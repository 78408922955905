import en from './en';
import zn from './zn';
import ko from './ko';

const indexLngObj = { 
  en,
  zn,
  ko,
};

export default indexLngObj;
