// Корейский
const ko = {
    'mainBlock#1': '암호자산 관리를 위한 원스탑 솔루션',
    'mainBlock#2': '암호자산 투자를 쉽고, 안전하게 그리고 빠르게 하십시오',
    'mainBlock#3': 'CINDX 실시간 이용 가능!',

//Текст в плашке
    'headertext#0': 'CINDX Pre-IEO start in',      //сейчас ( есть таймер)
    'headertext#1': 'The CINDX Pre-IEO is Live!', //25.06 14-59 мск:
    'headertext#2': '2nd round of CINDX Pre-IEO starts in', // 25.06 16-00 мск: ( есть таймер)
    'headertext#3': 'The CINDX Pre-IEO ends in',  // 26.06 15-00 мск ( есть таймер)
    'headertext#4': 'The CINDX Pre-IEO has ended.', // 27.06 15-00 мск(или раньше) первая строка  
    'headertext#4_1': '$400,000 worth of tokens sold.', // 27.06 15-00 мск(или раньше) вторая строка ( не показывать в моб)
    'headertext#5': 'Need Help?',
// Кнопка в плашке
    'buttonheader#1': 'Buy CINXO on ', 
    'buttonheader#2': 'BitForex',   // Название биржи с эконкой на кнопке

//    'icoInfo#1': '동의합니다:',
//    'icoInfo#2': '하드캡:',
//    'icoInfo#3': '소프트캡 중요 지표:',
//    'icoInfo#4': '상장 계약서에 서명됨:',

    'menu#1': '백서',
    'menu#2': '프레젠테이션',
    'menu#3': '요약 자료 ',
    'menu#4': '블로그',
    'menu#5': '법률 문서',
    'menu#6': '영어',
    'menu#7': '제품',
//    'menu#8': '세일 세부 정보',
    'menu#9': '팀',
    'menu#10': '공개 문서',
    'menu#11': 'CINDX LIVE',
    'menu#12': 'SUBSCRIBE',

    'mailForm#1': ' 틀린 이메일입니다',
    'mailForm#2': '보내는 중.. ',
    'mailForm#3': '유효한 것이 아닙니다, 다시 시도해보세요',
    'mailForm#4': ' 이메일이 제대로 전달되었습니다',
    'mailForm#5': '오류가 발생했습니다, 다시 시도해보세요 ',
    'mailForm#6': 'GDRP 준수',
    'mailForm#7': '구독양식과 KYC 단계에 의해 제공된 개인정보는 GDRP를 온전히 준수한 상태에서 수집됩니다',
    'mailForm#8': '프로젝트 정보 관리 담당은 Sofja Pevzner-Belositski입니다. 연락처: dpo@cindx.io. 정보 제공자는 정보 보호 관련해서 언제든 정보 관리 담당에게 연락주시길 바랍니다.',
    'mailForm#9': '구독양식과 KYC 단계에 의해 제공된 당신의 개인정보는 파트너들에게 제공될 예정입니다: ',
    'mailForm#10': 'ICO promo',
    'mailForm#17': '개인정보 접근에',
    'mailForm#18': '동의합니다',
    'mailForm#19': '증권형 토큰(Security Token) 세일 시작',
    'mailForm#20': '20% 보너스',
    'mailForm#21': '다른 누구보다 먼저 가져가세요',
    'mailForm#22': '토큰 세일 시작 ',
    'mailForm#23': '현재 $1로는 ',
    'mailForm#24': '나중에는 $1로는',
    'mailForm#25': '이메일',
    'mailForm#26': '프리세일에 참여하세요!',
    'mailForm#27': '동의 & 제출',
    'mailForm#28': '기 전에 그것을 얻을 다른 사람들! 제공 내에 만료됩',
    'mailForm#29': '을 얻고 싶은 최신 뉴스를 제공합?',
    'mailForm#33': '구독하기',
    'mailForm#34': 'PROSPECTUS',
    'mailForm#35': '기술 백서',
    'mailForm#36': '',
    'mailForm#37': '',
    'mailForm#38': '',
    'mailForm#39': '',
    'mailForm#40': '',
    'mailForm#41': '',

//   'docPopup#1': 'Receive all documents in your email',
 //   'docPopup#2': 'Enter your email',
 //   'docPopup#3': 'SEND ME DOCS',

 //   'progressBarSale#1': 'Bonuses',
 //   'progressBarSale#2': 'in CINX',
 //  'progressBarSale#3': 'Pre-Sale',
 //   'progressBarSale#4': 'Sale',
 //   'progressBarSale#5': 'Aug 26',
 //   'progressBarSale#6': 'Sep 17',
 //   'progressBarSale#7': 'Oct 8',
 //   'progressBarSale#8': 'Oct 22',
 //   'progressBarSale#9': 'Nov 6',
 //   'progressBarSale#10': 'Nov 19',
 //   'progressBarSale#11': 'Dec 3',
 //   'progressBarSale#12': 'Dec 17',
 //   'progressBarSale#13': 'ONE PAGER',
 //   'progressBarSale#14': 'PRESENTATION',
 //   'progressBarSale#15': 'WHITEPAPER',
 //   'progressBarSale#16': 'FINANCIAL MODEL',
 //   'progressBarSale#17': 'LEGAL DOCS',
 //   'progressBarSale#18': 'Download Our Public Documents',
 //   'progressBarSale#19': '',
 //   'progressBarSale#20': '',
 //   'progressBarSale#21': '',
 //   'progressBarSale#22': '',

 //   'ourTopRatings#1': 'Our Top Ratings',

    'cryptoAssetsManagement#1.1': 'CINDX 는 암호자산 관리의 중심 입니다.',
    'cryptoAssetsManagement#1.2': '투자자, 트레이더, 개발자들의 문제를 해결해 드립니다.',
    'cryptoAssetsManagement#2': '투자자를 위한 CINDX.MARKET',
    'cryptoAssetsManagement#3': '여러분의 지식과 경험에 대해 너무 염려하지 마십시오 - 암호화폐거래 전문가들이 당신을 돕기위해 여기 있습니다.',
    'cryptoAssetsManagement#4': '여러분의 화폐를 관리하십시오 - 여러분의 코인은 언제나 지갑속에 보관되어 있습니다.',
    'cryptoAssetsManagement#5': '수익을 올린 경우에만 지불하십시오 - 여러분의 포트폴리오가 수익을 올린 경우에만 요금이 부과됩니다.', 
    'cryptoAssetsManagement#6': ' CINDX LIVE',
    'cryptoAssetsManagement#7': '트레이더를 위한 CINDX.TRADE',
    'cryptoAssetsManagement#8': '$0의 마케팅 비용으로 자본을 유치할 수 있습니다.',
    'cryptoAssetsManagement#9': 'Terminal 프로그램, 앱 스토어, 마켓플레이스 와 다른 고급 툴을 제공하여 거래를 성공시키는데 도움을 드립니다.',
    'cryptoAssetsManagement#10': 'CINDX는 여러분의 명성과 전문성으로 수익을 창출하도록 도울 것입니다.  당신의 거래 이력과 명성은 빛을 발합니다.',
    'cryptoAssetsManagement#11': 'CINDX.TALK, CINDX.HUB & CINDX.DAO',
    'cryptoAssetsManagement#12': 'CINDX.talk - 유용한 콘텐츠에 대한 보상을 제공하는 소셜 네트워크t',
    'cryptoAssetsManagement#13': 'CINDX.hub - 구성 요소에는 공급 업체의 계정뿐만 아니라 타사 소프트웨어 테스트 및 통합을위한 허브가 포함됩니다',
    'cryptoAssetsManagement#14': 'CINDX.loan - 암호화 통화로 P2P 대출을 제공하고 투자 할 수있는 구성 요소',
    'cryptoAssetsManagement#15': 'CINDX.market 투자자를 위한 시장으로,  투자전략이나 투자 지수를 평가하고 선택할 수 있습니다.',
    'cryptoAssetsManagement#16': 'CINDX.trade는 암호자산거래에 있어서 다양한 환전을 한곳에서 관리하고, 시장을 분석하고, 거래를 자동화하고, 뛰어난 이익을 달성하고자 하는 트레이더들을 위한 모듈입니다.',
    'cryptoAssetsManagement#17': 'CINDX 는 데이터 수집 및 거래 실행을 위해 API를 사용합니다. API 및 비밀 키 사용은 사용자의 로그인 및 비밀번호보다 안전합니다. CINDX 사용자들을 보호하기 위해, 모든 비밀 API 키를 은행 수준의 암호화를 사용하여 별도의 데이터 베이스에 보관합니다.',
    'cryptoAssetsManagement#18': 'CINDX.HUB',
    'cryptoAssetsManagement#19': 'CINDX.hub는 바로적용이 가능한 솔루션을 가지고 알고리즘 전략, 이전 거래 내용 테스트 및 분석을 만들어 낼 수 있는 구성요소 입니다. ',
    'cryptoAssetsManagement#20': 'HUB.Pro 는 상시거래 및 수익창출을 위한 거래 전략을 자동화 할 수 있는 전문 트레이더 들을 위한 옵션 입니다.',
    'cryptoAssetsManagement#21': 'HUB.Lite 는 유연한 위험관리를 통해 검증 된 도구로 작업이 가능한 투자자들을 위한 옵션 입니다.',
    'cryptoAssetsManagement#22': '',
    'cryptoAssetsManagement#23': '',
    'cryptoAssetsManagement#24': '',

    'teamLegal#1': '우리는 최고의 실력과 팀워크를 가진 팀을 갖고 있습니다',
//   'teamLegal#2': '법률 및 규정의 체계적.',
    'teamLegal#3': '암호화폐 거래 라이센스',
    'teamLegal#4': '금융기관 라이센스',
    'teamLegal#5': '암호화폐 지갑 라이센스',
    'teamLegal#6': '백서 ',
    'teamLegal#7': '프레젠테이션',
    'teamLegal#8': '요약 자료 ',
//   'teamLegal#9': '금융 모델 ',
    'teamLegal#10':'법률 문서',

 /*   'roadmap#1': '로드맵 ',
    'roadmap#2': '시나리오 ',
    'roadmap#3': '먼저 CINDX 플랫폼의 기반을 조성합니다. 이 단계에서는 최소 기능 제품(MVP)이 개발되어 MVP가 교환 및 복제 프로토콜에 접근할 수 있습니다. 저희 리소스의 대부분이 플랫폼 및 보안의 확장성에 할당됩니다.',
    'roadmap#4': '플랫폼의 CINDX.alpha 버전이 출시됩니다 블록체인 구조 및 스마트 계약을 통한 결제의 기본 시스템을 포함합니다. 컴포넌트 CINDX.market 및 CINDX.trade는 추가 기능을 통해 수정될 것입니다.   ',
    'roadmap#5': 'CINDX.alpha 버전의 블록체인 구조에 기반하여 사용자가 소통하고 학습하며 유용한 컨텐츠를 획득할 수 있도록 CINDX.talk 컴포넌트를 생성합니다. 시장 정보를 분석하고 제 3자 서비스에 정보를 소개하기 위해 플랫폼의 CINDX.core 컴포넌트에 분석적 모듈이 추가됩니다.       ',
    'roadmap#6': '이 단계에서 컴포넌트 CINDX.market 및 CINDX.trade가 추가됩니다. 관리자들은 인덱스 기반의 제품을 생성할 수 있으며 터미널이 리스크 관리의 유연한 설정에 통합되어 있습니다. ',
    'roadmap#7': 'CINDX.talk이 공용 및 개인 채널을 갖습니다. 이 단계에서는 컨텐츠가 분리될 수 있습니다. ',
    'roadmap#8': '이 단계에서 플랫폼의 큰 업데이트가 있습니다. 이 단계에서 플랫폼의 큰 업데이트가 있습니다. CINDX.trade는 극도로 유연한 명령 실행 및 리스크 관리 시스템을 포함합니다. 판매자의 시장이 등장합니다. 그러나 이 시점에서는 CINDX가 개발한 어플리케이션과 파트너들만이 존재합니다.   ',
    'roadmap#9': 'CINDX.talk에서 토큰화된 평가 및 제품 "알고리즘 매매"의 새로운 유형 그리고 교환과 다중 교환 주문 사이 자동 재정거래의 기능은 최종 버전에 추가됩니다.    ',
    'roadmap#10': 'CINDX 플랫폼의 기반을 조성합니다. 이 단계에서는 최소 기능 제품(MVP)이 개발되어 MVP가 교환 및 복제 프로토콜에 접근할 수 있습니다. 저희 리소스의 대부분이 플랫폼 및 보안의 확장성에 할당됩니다. ',
    'roadmap#11': 'CINDX.alpha 버전이 블록체인 구조 및 스마트 계약을 통한 결제의 기본 시스템을 포함합니다. 컴포넌트 CINDX.market 및 CINDX.trade는 추가 기능을 통해 수정됩니다.  ',
    'roadmap#12': 'CINDX.alpha 버전의 블록체인 구조에 기반하여 사용자가 소통하고 학습하며 유용한 컨텐츠를 획득할 수 있도록 CINDX.talk 컴포넌트를 생성합니다. 시장 정보를 분석하고 제 3자 서비스에 정보를 소개하기 위해 플랫폼의 CINDX.core 컴포넌트에 분석적 모듈이 추가됩니다. 새 컴포넌트 CINDX.hub는 CINDX가 제공하는 시장 데이터를 분석하기 위한 어플리케이션 개발을 허용합니다. ',
    'roadmap#13': '이 단계에서 컴포넌트 CINDX.market 및 CINDX.trade가 추가됩니다. 관리자들은 인덱스 기반의 제품을 생성할 수 있으며 터미널이 리스크 관리의 유연한 설정에 통합되어 있습니다. CINDX 어플리케이션 개발 및 실행을 간편화 하는 개발자들을 위한 SDK가 출시됩니다.  ',
    'roadmap#14': 'CINDX.hub는 Github와 통합되어 어플리케이션 개발을 훨씬 더 간편하게 합니다.',
    'roadmap#15': '이 단계에서 플랫폼의 큰 업데이트가 있습니다. 사용자들은 암호 화폐와 법정 화폐를 저장하고 교환할 수 있습니다. CINDX.trade는 극도로 유연한 명령 실행 및 리스크 관리 시스템을 포함합니다. CINDX.hub에는 지금까지의 시장 데이터 기반의 시험 시스템과 함께 거래 전략을 위한 본격적인 에디터가 있습니다.',
    'roadmap#16': 'CINDX.talk에서 토큰화된 평가 및 제품 "알고리즘 매매"의 새로운 유형 그리고 교환과 다중 교환 주문 사이 자동 재정거래의 기능은 최종 버전에 추가됩니다. 코드 감사를 위한 우수한 플랫폼이 생성되며, CINDX.talk에서 토큰화 된 평가에 기반하여 커뮤니티 전문가 평가가 생성됩니다. ',
    'roadmap#17': 'CINDX 플랫폼의 기반을 조성합니다. 이 단계에서는 최소 기능 제품(MVP)이 개발되어 MVP가 교환 및 복제 프로토콜에 접근할 수 있습니다. 저희 리소스의 대부분이 플랫폼 및 보안의 확장성에 할당됩니다.',
    'roadmap#18': '플랫폼의 CINDX.alpha 버전이 출시됩니다 블록체인 구조 및 스마트 계약을 통한 결제의 기본 시스템을 포함합니다. 컴포넌트 CINDX.market 및 CINDX.trade는 추가 기능을 통해 수정될 것입니다.  ',
    'roadmap#19': 'CINDX.alpha 버전의 블록체인 구조에 기반하여 사용자가 소통하고 학습하며 유용한 컨텐츠를 획득할 수 있도록 CINDX.talk 컴포넌트를 생성합니다. 시장 정보를 분석하고 제 3자 서비스에 정보를 소개하기 위해 플랫폼의 CINDX.core 컴포넌트에 분석적 모듈이 추가됩니다. 새 컴포넌트 CINDX.hub는 CINDX가 제공하는 시장 데이터를 분석하기 위한 어플리케이션 개발을 허용합니다. 팀이 플랫폼의 소지인 수익 분배를 위한 시스템인 컴포넌트 CINDX.dao를 개발합니다.       ',
    'roadmap#20': '이 단계에서 컴포넌트 CINDX.market 및 CINDX.trade가 추가됩니다. 관리자들은 인덱스 기반의 제품을 생성할 수 있으며 터미널이 리스크 관리의 유연한 설정에 통합되어 있습니다. 개발자들을 위해 CINDX 어플리케이션 개발 및 실행을 간편화 하는 SDK가 출시됩니다.  ',
    'roadmap#21': 'CINDX.talk이 공용 및 개인 채널을 갖습니다. 이 단계에서는 컨텐츠가 분리될 수 있습니다. CINDX.hub는 Github와 통합되어 어플리케이션 개발을 훨씬 더 간편하게 합니다.',
    'roadmap#22': '이 단계에서 플랫폼의 큰 업데이트가 있습니다. 사용자들은 암호 화폐와 법정 화폐를 저장하고 교환할 수 있습니다. CINDX.trade는 극도로 유연한 명령 실행 및 리스크 관리 시스템을 포함합니다. CINDX.hub에는 지금까지의 시장 데이터 기반의 시험 시스템과 함께 거래 전략을 위한 본격적인 에디터가 있습니다. CINDX.dao가 토큰 소지인을 위한 투표 시스템을 받습니다 ',
    'roadmap#23': 'CINDX.talk에서 토큰화된 평가 및 제품 "알고리즘 매매"의 새로운 유형 그리고 교환과 다중 교환 주문 사이 자동 재정거래의 기능은 최종 버전에 추가됩니다. 코드 감사를 위한 우수한 플랫폼이 생성되며, CINDX.talk에서 토큰화 된 평가에 기반하여 커뮤니티 전문가 평가가 생성됩니다. ',
*/
 'roadmap#24': 'Roadmap',
    //'roadmap#25': '',
    //'roadmap#26': '',

    'problemSolving#1': '시간 ',
    'problemSolving#2': '신뢰 ',
    'problemSolving#3': '불확실성',
    'problemSolving#4': '저는 암호화폐 시장에 투자할 돈은 있지만, 내 포트폴리오를 관리할 수 있는 전문 트레이더를 검색하고 연락할 시간이 없습니다.',
    'problemSolving#5': '암호화폐 거래에 관한 기술은 사용자에게 친숙하게 느껴지지 않습니다. 저는 어떻게 코인을 거래하는지 잘 모릅니다.',
    'problemSolving#6': '암호화 거래를 둘러싸고 있는 기술은 사용자 친화적이지 않습니다. 어떻게 코인을 거래하는지 모릅니다.',
    'problemSolving#7': 'CINDX는 신뢰할 수 있으며 평판이 좋은 트레이더와 연결해드립니다.',
    'problemSolving#8': 'CINDX 플랫폼에서 당신의 자금은 절대 당신의 지갑을 떠나지 않습니다.',
    'problemSolving#9': '시장에서 가장 뛰어난 트레이더와 함께 바로 수익을 냅니다.',
    'problemSolving#10': 'CINDX의 트레이더는 거래 성공 이력에 따라 등급을 평가 받습니다. 여러분에게 맞는 트레이더를 선택하십시오.',
    'problemSolving#11': '분산된 시스템 및 똑똑한 계약을 통해 자금을 트레이더에게 직접 보내지 않고 관리할 수 있습니다.',
    'problemSolving#12': 'CINDX의 모든 투자자는 시장에서 가장 성공적인 트레이더에 의해 자신의 자금을 관리할 수 있는 기회를 갖습니다.',
    'problemSolving#13': 'CINDX가 투자자들을 위해 해결하는 문제들은 어떤 게 있을까요?',
    'problemSolving#14': 'CINDX LIVE',

    'howCindxWorks#1': '투자자 ',
    'howCindxWorks#2': '트레이더 ',
    'howCindxWorks#3': '판매자 ',
    'howCindxWorks#4': '뛰어난 트레이더의 포트폴리오 관리 기술을 통해 암호화폐의 수입을 증가시킵니다.',
    'howCindxWorks#5': '실적 및 거래 전략에 기반해 투자자로부터 수수료를 받습니다.',
    'howCindxWorks#6': '트레이드 봇, 뉴스 위젯과 같은 고급 거래 툴을 판매합니다.',
    'howCindxWorks#7': '커미션과 구독 수수료를 벌어보세요.',
    'howCindxWorks#8': '거래하고자 하는 암호화폐 자산을 가지고 CINDX에 참여합니다.',
    'howCindxWorks#9': '투자자에게 이익이 되는 트레이딩 전문 지식을 가지고 CINDX에 참여합니다.',
    'howCindxWorks#10': '투자자를 도울 수 있는 트레이딩 앱을 가지고 CINDX에 참여합니다.',
    'howCindxWorks#11': '쉽고, 안전하며, 수익성 있는 거래를 위해 노력해왔습니다.',
    'howCindxWorks#12': 'CINDX의 원리',

    'marketAnalytics#1': '시장 분석',
    'marketAnalytics#2': '관리중인 암호화폐 자산의 증가 (백만, 미화)',
    'marketAnalytics#3': '지역별 온라인 거래자 수 (천, 명)',
    'marketAnalytics#4': '암호화폐시장의 월간 거래량 (십억, 미화)',
    'marketAnalytics#5': '2018 년에는 암호화 통화 가격이 전반적으로 하락하는 추세를 보였으 나 펀드 관리를 통해 새로운 유동성이 자산으로 유입되면서 업계는 계속 발전했습니다.',
    'marketAnalytics#6': '',
    'marketAnalytics#7': '2017 년과 2018 년의 지역별 인종 차별에 의한 온라인 상인 수의 증가. 2018 년에는 새로운 거래 참가자를 위한 진입 문턱이 낮은 암호화폐 거래의 출현으로 인해, 아시아와 유럽에서 성장이 주를 이루었습니다.',
    'marketAnalytics#8': '',
    "marketAnalytics#_9": '암호화 시장에서의 거래량은 이미 암호 통화가 과열된 2017 년 말 이미 초과했습니다. 거의 매년 주가의 하락에도 불구하고, 연구 데이터로 표시된 바와 같이 거래는 매우 활발하게 발전하고 있습니다',
    "marketAnalytics#_10": '',
    //'marketAnalytics#9': 'WHITEPAPER',
    //'marketAnalytics#10': 'PRESENTATION',
    //'marketAnalytics#11': 'ONE PAGER',
    //'marketAnalytics#12': 'FINANCIAL MODEL',
    //'marketAnalytics#13': 'LEGAL DOCS',
    'marketAnalytics#14': '지리적 범위 ',
    'marketAnalytics#15': '2,500 만 명 이상의 잠재적 관리자 및 트레이더',
    'marketAnalytics#16': '',
    'marketAnalytics#17': '1 억 명 이상의 잠재 투자자들의 매일 거래',
    'marketAnalytics#18': '매일 거래',
    'marketAnalytics#19': '1000 개 이상의 기관 파트너,',
    'marketAnalytics#20': '1000 개 이상의 기관 파트너, 각각 3-5 억 달러 거래',
    'marketAnalytics#21': 'Cryptofundresearch.com으로', //(1 слайд вместо Reference)
    'marketAnalytics#22': 'Brokernotes.co으로',         //(2 слайд вместо Reference)
    'marketAnalytics#23': 'Coinmarketcap.com으로',      //(3 слайд вместо Reference)

 /*   'tokenSaleDetails#1': '토큰 배당 ',
    'tokenSaleDetails#2': '팀',
    'tokenSaleDetails#3': '바운티 ',
    'tokenSaleDetails#4': '자문단',
    'tokenSaleDetails#5': '준비금',
    'tokenSaleDetails#6': '트레이더 모집 프로그램',
    'tokenSaleDetails#7': '토큰 유동성 지원',
    'tokenSaleDetails#8': '새일 가능 ',
    'tokenSaleDetails#9': '토큰 이름: :',
    'tokenSaleDetails#10': '토큰 프리새일 시작일:',
    'tokenSaleDetails#11': '총 배출량: :',
    'tokenSaleDetails#12': '토큰 당 가격:',
    'tokenSaleDetails#13': '최소 투자: :',
    'tokenSaleDetails#14': '50 달러 상당 ',
    'tokenSaleDetails#15': '백서',
    'tokenSaleDetails#16': '프레젠테이션',
    'tokenSaleDetails#17': '요약 자료 ',
    'tokenSaleDetails#18': '금융 모델',
    'tokenSaleDetails#19': '법률 문서 ',
    'tokenSaleDetails#20': '수익의 사용 ',
    'tokenSaleDetails#21': '마케팅 및 홍보  21%',
    'tokenSaleDetails#22': '연구 및 개발 41%',
    'tokenSaleDetails#23': '운영 28%',
    'tokenSaleDetails#24': '법률 비용 7%',
    'tokenSaleDetails#25': '커뮤니티 보조금 3%',
    'tokenSaleDetails#26': '토큰 거래',
    'tokenSaleDetails#27': '토큰 새일:',
    'tokenSaleDetails#28': '토큰 프리세일 보너스:',
    'tokenSaleDetails#29': '8월 26일~9월 16일- 25% 보너스',
    'tokenSaleDetails#30': '9월 17일~10월 7일- 20% 보너스',
    'tokenSaleDetails#31': '토큰 메인 세일 보너스:',
    'tokenSaleDetails#32': '10월 8일~21일 - 17% 보너스',
    'tokenSaleDetails#33': '10월 22일~11월 4일 - 15% 보너스',
    'tokenSaleDetails#34': '11월 5일~18일 - 13% 보너스',
    'tokenSaleDetails#35': '11월 19일~12월 2일 - 10% 보너스',
    'tokenSaleDetails#36': '12월 3일~16일 - 5% 보너스',
    'tokenSaleDetails#37': '17일~26일 - 0% 보너스 ',
    'tokenSaleDetails#38': '토큰 구매 ',
    'tokenSaleDetails#39': '토큰 판매 세부 사항 ',
    'tokenSaleDetails#40': '토큰 세일 중 투자자는 이후 보안 토큰 \[CINX]를 받을 권리를 부여하는 유틸리티 토큰 - 옵션 \[CINXO]를 구매합니다.',
    'tokenSaleDetails#41': '보안 토큰 \[CINX]는 방출 예상에 따라 발행되고 배포됩니다(토큰 판매 종료 후, 늦어도 2019년 1분기 전에 에스토니아 FSA 웹사이트 https://www.fi.ee/ and cindx.io에 개재될 예정).',
    'tokenSaleDetails#42': '[CINXO] 토큰의 보유자는 신원 서명, 주소 확인 및 기타 문서 제공을 포함하는 KYC 절차의 완료 후 보안 토큰의 옵션을 1대1 비율로 교환할 권리가 있지만 의무는 없습니다 (CINDX KYC 계획 참고).',
    'tokenSaleDetails#43': '토큰 \[CINXO]는 보너스 프로그램 잠금 및 플랫폼 투표상의 수익 분배 참여 권리 외에는 보안 토큰과 동일한 권리를 부여합니다.',

    'legalSchedule#1': '법적 조항 ',
    'legalSchedule#2': '가상 화폐와 법정 통화 간의 교환을 제공하기 위해 FVR000110 라이선스를 취득',
    'legalSchedule#3': '에스토니아와 유럽에서 상표 등록',
    'legalSchedule#4': '금융 기관으로 운용하기 위해 FFA000254 라이선스',
    'legalSchedule#5': '금융 기관으로 운용하기 위해 FFA000254 라이선스 취득',
    'legalSchedule#6': '에스토니아 법에 따라 투자 설명서 완성',
    'legalSchedule#7': 'FSA 라이센스 (에스토니아 금융 감독 당국) 클레임 ',
    'legalSchedule#8': '가상 화폐 지갑 서비스를 제공할 수 있는 라이센스 취득',
    'legalSchedule#9': '면책 D (Regulation D)하에 SEC에 등록됨',
    'legalSchedule#10': 'GDPR을 위해 개인 정보 관리자를 임명됨',
    'legalSchedule#11': 'FSA 라이선스 취득',
    'legalSchedule#12': '투자 설명서 EU 809-2004 등록',
    'legalSchedule#13': 'CINX 보안 토큰 발행',
*/
     'feedbackProject#_1': '비디오',
    'feedbackProject#1': '프로젝트에 대한 피드백',
    'feedbackProject#2': 'Val Jerdes',
    'feedbackProject#3': '제품 이사',
    'feedbackProject#4': 'Innov8 Global Ventures의 파트너 | Advisory | Labs',
    'feedbackProject#5': '비디오',
    'feedbackProject#6': '“그것은 세계 경제의 급속한 발전이었습니다. 동시에, 그들은 지원됩니다. 매우 흥미 진진한! ".',
    //'feedbackProject#7': 'Denis Eskenazi',
    //'feedbackProject#8': 'Product Marketing Director',
    //'feedbackProject#9': 'Denis Eskenazi, CINDX Product Marketing Director explains why he has joined CINDX',
    'feedbackProject#10': 'Philip Staehelin',
    'feedbackProject#11': 'CINDX 고문',
    'feedbackProject#12': 'Roland Berger 파트너 관리',
    'feedbackProject#13': '비디오',
    'feedbackProject#14': '"CINDX는 통화 및 통화 투자입니다."',
    //'feedbackProject#15': 'Andrzej Cichowlaz',
    //'feedbackProject#16': 'Marketing Specialist',
    //'feedbackProject#17': 'Andrzej Cichowlaz, CINDX Marketing Manager tells why he believes in the project`s success',
    'feedbackProject#18': 'Austin Kimm',
    'feedbackProject#19': 'CINDX 고문',
    'feedbackProject#20': '국제 금융 서비스 CEO',
    'feedbackProject#21': '비디오',
    'feedbackProject#22': '"CINDX는"상인 - 투자자 "관계를 다시 개인화합니다."',
    'feedbackProject#23': 'Amarpreet Singh',
    'feedbackProject#24': 'CINDX 고문',
    'feedbackProject#25': '글로벌 블록 체인 재단 고문',
    'feedbackProject#26': '비디오',
    'feedbackProject#27': '“CINDX는 모든 투자자와 거래자를위한 정교한 도구를 제공 할뿐만 아니라 플랫폼도 제공합니다. 우리는 상인들의 경험을 복사 할 수 있습니다. "',
    'feedbackProject#28': 'Keith Teare',
    'feedbackProject#29': 'CINDX 고문',
    'feedbackProject#30': 'Accelerated Digital Ventures 설립자 겸 집행 위원장',
    'feedbackProject#31': '비디오',
    'feedbackProject#32':'"새로운 거래자들이 경험이 풍부한 트레이더들의 추적을 가능하게 함으로서, CINDX는 무경험이 실패를 피할 수 없을 것이라는 두려움에서 벗어나게 해 주었습니다." ',
    //'feedbackProject#33': 'Mansur Rasulov',
    //'feedbackProject#34': 'Community Lead',
    //'feedbackProject#35': 'Mansur Rasulov, CINDX Community Lead explains why he decided to become a part of CINDX team',
    'feedbackProject#36': 'Sadie Hutton',
    'feedbackProject#37': 'CINDX 고문',
    'feedbackProject#38': 'AuBit 창립자이자 CSO',
    'feedbackProject#39': '비디오',
    'feedbackProject#40': '"전체 프로토콜은 참여하는 모든 사람들을위한 진정한 프로토콜입니다."',
    'feedbackProject#41': 'Graham Doggart',
    'feedbackProject#42': 'CINDX 고문',
    'feedbackProject#43': 'AuBit 창립자이자 CSO',
    'feedbackProject#44': '비디오',
    'feedbackProject#45': '"투자자가 자신의 펀드를이 시장의 전문가 인 누군가에게 맡기고 자금을 통제하지 않고 거래 할 수 있다고 상상해보십시오."',
    //'feedbackProject#46': 'Alina Vardanian',
    //'feedbackProject#47': 'Content Manager',
    //'feedbackProject#48': 'Alina Vardanian, CINDX Content Manager talks about the team spirit and what she loves about her job',
    'feedbackProject#49': 'Bogdan Fiedur',
    'feedbackProject#50': 'CINDX 고문',
    'feedbackProject#51': 'bitJob의 공동 설립자이자 Blockchainexperts.io의 사장',
    'feedbackProject#52': '비디오',
    'feedbackProject#53': '"파괴적인 암호화 통화 투자 시장은 투자자들에게 혁신적이고 혁신적인 혁신적인 솔루션이었습니다."',
    'feedbackProject#54': 'Jason King',
    'feedbackProject#55': 'CINDX 고문',
    'feedbackProject#56': 'Telenor의 최고 경영자',
    'feedbackProject#57': '비디오',
    'feedbackProject#58': '"CINDX 진정으로 이해하기 쉽게 알 수 있습을 암호화 통화 거래를 위한 세계 대중 시장의 투자자입니다. 이 제공하는 기회에 대한 일반 작은 투자자의 이동에 참여 거래에 일치하는 자신의 프로필을 공정하고 투명한 방법입니다."',

    'mediaNews#1': '미디어',
    'mediaNews#2': '뉴스',
    // Media (Не переводим)
    'mediaNews#1_1': 'CINDX aims to Democratize the Modern Financial System',
    'mediaNews#1_2': 'Even as investors in the cryptocurrency space recognize that they are at the forefront of a new financial revolution, adoption by the general populace is yet to occur. While 3rd parties are slowly entering the space in the form of hedge funds and other investors, very few individuals seem to have taken the step to invest in cryptocurrencies on their own.',
    'mediaNews#1_3': 'CINDX Announces Plans For ICO Pre-Sale Amidst Release Of Revolutionary Crypto Trading Hub That Allows Users To Follow Expert Traders',
    'mediaNews#1_4': 'CINDX takes its business seriously with particular attention and cares to legal matters and has taken great pains to ensure that CINDX is as compliant as possible with global regulations.',
    'mediaNews#1_5': 'How CINDX is Democratizing the Modern Financial System',
    'mediaNews#1_6': 'CINDX is a new platform that is making crypto trading easier, more secure, and more accessible. With this platform, it’s possible to have your funds traded without high fees or minimum investments, with the added security and transparency of blockchain technology.',
    'mediaNews#1_7': 'CINDX is making crypto trading easier, more secure, and more accessible.',
    'mediaNews#1_8': 'Cryptocurrency and blockchain technology have been slated as powerful tools capable of fueling a global financial revolution. There are myriad opportunities to invest in the crypto space, and yet, very few individuals have undertaken the steps necessary to invest.',
    'mediaNews#1_9': 'CINDX Announces Plans Release Of Revolutionary Crypto Trading Hub',
    'mediaNews#1_10': 'The CINDX platform creates a complete ecosystem for cryptocurrency trading that is favorable to all the participants of the market and offers solutions to existing pain points.',
    'mediaNews#1_11': 'CINDX Announces Plans For ICO Pre-Sale',
    'mediaNews#1_12': 'CINDX made headlines this week with the announcement that they will be opening their ICO Pre-Sale in late August after investors interest in the ICO peaks to new levels.',
    'mediaNews#1_13': 'CINDX Will Be Opening ICO Pre-Sale in late August',
    'mediaNews#1_14': 'The CINDX Crypto trading platform has a myriad of features and benefits that haven’t been seen in other platforms to date.',
    'mediaNews#1_15': '5 Tips from CINDX Crypto Assets Management Hub: How to Avoid Being Cheated',
    'mediaNews#1_16': 'The team behind the CINDX crypto assets management hub has created a helpful set of guidelines on how not to become victims of unfair play, so you can learn what should be primarily taken into account when evaluating ICOs before investing in them.',
    'mediaNews#1_17': 'CINDX Crypto Asset Management Hub Adopts Bancor Protocol',
    'mediaNews#1_18': 'CINDX is delighted to announce that a number of new integrations with top industry players are underway, each of which will make the life of the users of the platform easier and richer. These new integrations include the decision to adopt the Bancor protocol to provide continuous liquidity for the CINDX platform.',
    'mediaNews#1_19': 'CINDX Crypto Asset Management Hub Adopts Bancor Protocol',
    'mediaNews#1_21': 'CINDX is delighted to announce that a number of new integrations with top industry players are underway, each of which will make the life of the users of the platform easier and richer. These new integrations include the decision to adopt the Bancor protocol to provide continuous liquidity for the CINDX platform.',
    'mediaNews#1_22': 'CINDX Beginning ICO for Crypto Asset Management and Social Network',
    'mediaNews#1_23': 'CINDX, a cryptocurrency project has now begun its security token ICO pre-sale to introduce a multifunctional platform that allows blockchain investors to choose an expert trader to follow based on trading stats recorded on the blockchain.',
    'mediaNews#1_24': 'Blockchain Investors Can Now Make It Big with the Help of CINDX',
    'mediaNews#1_25': 'Cryptocurrencies have certainly made their mark. The digital tokens have consistently shown that they have the potential to rise very high in value. The market is huge and investors are insatiable.',
    'mediaNews#1_26': 'Even Amateur Investors Can Make It Big with the Help of CINDX',
    'mediaNews#1_27': 'CINDX, the crypto asset management company, recently opened its ICO pre-sale and is introducing a multifunctional platform that allows anybody to invest in the crypto market while consistently earning returns. ',
    'mediaNews#1_28': 'CINDX Crypto Asset Manager For Traders Launches Security Token ICO',
    'mediaNews#1_29': 'CINDX, a digital currency startup, recently initiated the presale phase of its ICO token sale. Then proceeds from the ICO will be used to establish a multifunctional platform that enables crypto traders identify and follow their preferred expert investor based on trading statistics recorded on the blockchain.',
    'mediaNews#1_30': 'CINDX CEO Shares His Point of View on why Investors Should Treat Cryptocurrency and Blockchain Predictions with "Healthy Skepticism".',
    'mediaNews#1_31': 'Crypto isn\'t all it\'s cracked up to be. Citi analyst Josh Levin says to treat the many cryptocurrency and blockchain predictions with "healthy skepticism" and to remain "open minded".',
    'mediaNews#1_32': 'CINDX CEO Shares His Point of View on Rising Bitcoin Mining Hashrates',
    'mediaNews#1_33': 'Even though cryptocurrency prices have been significantly down throughout 2018 thus far, oddly, hashrates for the Bitcoin network—signifying mining activity—have risen meaningfully. Could this disparity provide some insight into the future of alt-currencies?',
    // News (Не переводим)
     'mediaNews#2_1': 'CINDX meets Ian Balina',
    'mediaNews#2_2': 'CINDX pitch to the one of the TOP10 crypto gurus in the world in the Cryptobazar event.',
    'mediaNews#2_3': 'CINDX took part in Moscow ICO Summit 2018',
    'mediaNews#2_4': 'CINDX team introduced the project to the Russian crypto community during ICO Summit event.',
    'mediaNews#2_5': 'How CINDX is ensuring legal compliance',
    'mediaNews#2_6': 'Our company is registered in Estonia. Why? The answer is simple — the authorities of Estonia have created a strong and innovative legal infrastructure...',
    'mediaNews#2_7': 'Keith Teare Co-founder of Accelerated Digital Ventures and Founding Shareholder in TechCrunch',
    'mediaNews#2_8': 'We continue to introduce CINDX advisers, find out what they...',
    'mediaNews#2_9': 'Meet world\'s first financial crypto social network',
    'mediaNews#2_10': 'Every day our team takes one more step on the way to creation and start...',
    'mediaNews#2_11': 'Denis Eskenazi CINDX Product Marketing Director',
    'mediaNews#2_12': 'We continue to tell about the CINDX team and each team member’s professional experience. Please, read about Denis Eskenazi...',
    'mediaNews#2_13': 'CINDX crypto currency exchange license',
    'mediaNews#2_14': 'Our company in Estonia has received Crypto Currency Exchange License. This allows CINDX to provide fiat-to-crypto exchange services. This licence is issued...',
    'mediaNews#2_15': 'Austin Kimm Co-founder of Crypterium & CINDS Adviser',
    'mediaNews#2_16': 'We continue to introduce CINDX advisers, find out what they are proud...',
    'mediaNews#2_17': 'The strategic meeting of the international CINDX team',
    'mediaNews#2_18': 'Every day our team takes one more step on the way to creation and start of the CINDX platform. Our work...',
    'mediaNews#2_19': 'Artur Shamalov CINDX Investment Director',
    'mediaNews#2_20': 'We continue to introduce the CINDX team. Our next article is about Artur Shamalov, a skilled...',
    'mediaNews#2_21': 'The issuance of CINX tokens',
    'mediaNews#2_22': 'The issuance of CINX tokens will be carried out according to the license of The Estonian Financial Supervisory Authority (EFSA). What is a token...',
    'mediaNews#2_23': 'Amarpreet Singh Co-Founding Member of Malta Blockchain Association & CINDX adviser',
    'mediaNews#2_24': 'We at CINDX are incredibly proud to present our advisers, tell the world about their achievements and why they have decided to become a part of the CINDX...',
    'mediaNews#2_25': 'CINDX meets Vangoo Capital Partners Fund',
    'mediaNews#2_26': 'Today we have welcomed Kevin Shang from Vangoo Capital Partners and his colleagues in our office. We have discussed business partnership opportunities in order to attract investors from the Asian region.',
    'mediaNews#2_27': 'Mofassair Hossain CINDX Chief Marketing Officer.',
    'mediaNews#2_28': 'We continue to introduce the CINDX team, explore each team member’s point of view, find out what our team members are particularly excited about...',
    'mediaNews#2_29': 'How CINDX ensures safety for its users - KYC',
    'mediaNews#2_30': 'Every crypto enthusiast knows that a KYC procedure is necessary in order to protect investors against fraudulent actions of the third...',
    'mediaNews#2_31': 'Philip Staehelin 25 Years of Consulting and Entrepreneurial Experience',
    'mediaNews#2_32': 'We continue our regular series of articles dedicated to introducing CINDX advisers. The next item is about Philip Staehelin.',
    'mediaNews#2_33': 'CINDX meets 9coin Digital Asses Exchange',
    'mediaNews#2_34': 'The CINDX team continues active work on building relationships with various international companies. One of the most important directions of business...',
    'mediaNews#2_35': 'Jason King',
    'mediaNews#2_36': 'The core of any great project is always people who make the unique idea come true. We would like to tell about...',
    'mediaNews#2_37': 'CINDX Presents the project during RICLUB private investors meeting',
    'mediaNews#2_38': 'CINDX has successfully passed the due diligence procedure requested by RICLub and was one of the few projects...',
    'mediaNews#2_39': 'Sofja Pevzner CINDX Personal Data Officer',
    'mediaNews#2_40': 'We continue to introduce our team and tell about what inspires our colleagues to work with CINDX. Please read about...',
    'mediaNews#2_41': 'Tyler Sanford 8+ years digital marketing in Yelp, Zenefits',
    'mediaNews#2_42': 'We continue to introduce CINDX advisers, find out what they are proud of and why they have decided to become a part of the CINDX team. In this...',
    'mediaNews#2_43': 'CINDX - Key points to observe',
    'mediaNews#2_44': 'The CINDX project is a valuable business venture with the features that are critically relevant for its participants. In this article we talk about Strengths...',
    'mediaNews#2_45': 'Airat Shayhulov CINDX Head of Quantitative Research.',
    'mediaNews#2_46': 'In “TEAM” series of the articles, we tell about people who every day participates in the creation...',
    'mediaNews#2_47': 'CINDX license of the financial consultant',
    'mediaNews#2_48': 'CINDX has obtained a termless operating licence (the FFA 000254 license) that gives the right to operate as a financial institution...',
    'mediaNews#2_49': 'Graham Doggart 20 Years of Enterprise Development and Fintech Marketing Compliance',
    'mediaNews#2_50': 'We continue our regular series of articles dedicated to introducing CINDX advisers. The next item...',
    'mediaNews#2_51': 'CINDX continues the roadshow in China!',
    'mediaNews#2_52': 'The CINDX team is glad to announce participation as a Golden Sponsor of Borderless Blockchain Technology Summit 2018, which...',
    'mediaNews#2_53': 'Ibraghim Haniev CINDX Tech Lead',
    'mediaNews#2_54': 'Get to know more about the CINDX team! The core of any great project is always people who make the unique ideas come true...',
    'mediaNews#2_55': 'Sadie Hutton 25 Years in Senior Management and Management Consulting',
    'mediaNews#2_56': 'Like any global project, we understand the importance of professional help and advice. We continue to tell the stories of...',
    'mediaNews#2_57': 'CINDX.talk - a service that will help people become wiser in taking financial decisions',
    'mediaNews#2_58': 'The current digital-world related to the crypto industry is getting crowded with mind-charming digital solutions...',
    'mediaNews#2_59': 'Val Jerdes CINDX Product Director',
    'mediaNews#2_60': 'We continue to introduce the CINDX team, explore each team member’s point of view, find out what our team members are particularly...',
    'mediaNews#2_61': 'Bogdan Fiedur Blockchain and cryptocurrency expert, investor, entrepreneur',
    'mediaNews#2_62': 'We continue to introduce CINDX advisers, find out what they are proud of and why they have decided to become...',
    'mediaNews#2_63': 'Welcome to CINX Token Distribution – it’s not an ICO; it’s an STO',
    'mediaNews#2_64': 'In this article, we want to make it clear why CINDX chose to hold a Security Token Offering (STO) instead of an Initial...',
    'mediaNews#2_65': 'CINDX celebrates the “AA” Rating from Top ICO List Audit!',
    'mediaNews#2_66': 'The results of CINDX’s sincerity to make meaningful changes in people’s ambitions via a unique business model just got appreciated...',
    'mediaNews#2_67': 'CINDX Weekly digest (30.07.18 - 05.08.18)',
    'mediaNews#2_68': 'The CINDX team is very excited to share the results of our work for the last week 30.07.18 - 05.08.18!',
    'mediaNews#2_69': 'Virtual Currency Wallet Services',
    'mediaNews#2_70': 'CINDX believes that being fluent in legal issues and having a clean, confident and clear legal status is of utmost importance. At the moment, CINDX is going...',
    'mediaNews#2_71': 'How CINDX Influences Crypto Trading',
    'mediaNews#2_72': 'How to master the crypto market movements and turn them in one’s favor? This is where CINDX’s effective business model comes into play. Learn more about CINDX on our Medium!',
    'mediaNews#2_73': 'CINDX Weekly digest (06.08.18 - 12.08.18)',
    'mediaNews#2_74': 'CINDX continues its regular column - Weekly Digest. We are excited to share the recent achievements of our project! Enjoy your time reading!',
    'mediaNews#2_75': 'CINDX gets 5.0 rating on TrackICO!',
    'mediaNews#2_76': 'CINDX continues getting approval from the largest and the most credible rating services. The week has hardly passed after...',
    'mediaNews#2_77': 'David Gutierrez Investment Relations Officer',
    'mediaNews#2_78': 'CINDX is very serious about taking people on crew and proud to introduce them. This article is dedicated to David Gutierrez, CINDX Investment Relations Officer',
    'mediaNews#2_79': 'ICObench gives 4.8 to CINDX',
    'mediaNews#2_80': 'The CINDX project has already gained the highest mark of  ‘AA’ from Top ICO List and got a 5.0 rating from TrackICO. Now, we are proud to share...',
    'mediaNews#2_81': 'Dip your Toe in Crypto',
    'mediaNews#2_82': 'It is never late to change your mind. It’s no surprise that Wall Street bankers change their minds in favour of crypto. Enjoy reading CINDX review on the current market situation!',
    'mediaNews#2_83': 'Yuriy Avdeev, CEO of the CINDX project',
    'mediaNews#2_84': 'Enjoy reading what inspired Yuriy Avdeev to create a project that will help all participants of the crypto market to be profitably engaged - CINDX.',
    'mediaNews#2_85': 'Weekly digest (13.08.18 - 19.08.18)',
    'mediaNews#2_86': 'CINDX continues its regular Weekly Digest column. We are excited to share our project’s progress and the most recent achievements! We are glad to remind you that CINDX security token Pre-sale starts this Sunday. Don\'t miss!',
    'mediaNews#2_87': 'Dip your Toe in Crypto',
    'mediaNews#2_88': "It is never late to change your mind. It’s no surprise that Wall Street bankers change their minds in favour of crypto. Enjoy reading CINDX review on the current market situation!",
    'mediaNews#2_89': 'Yuriy Avdeev, CEO of the CINDX project',
    'mediaNews#2_90': 'Enjoy reading what inspired Yuriy Avdeev to create a project that will help all participants of the crypto market to be profitably engaged - CINDX.',
    'mediaNews#2_91': 'Weekly digest (13.08.18 - 19.08.18)',
    'mediaNews#2_92': 'CINDX continues its regular Weekly Digest column. We are excited to share our project’s progress and the most recent achievements! We are glad to remind you that CINDX security token Pre-sale starts this Sunday. Don"t miss',
    'mediaNews#2_93': 'Upcoming',
    'mediaNews#2_94': 'Ended',
    'mediaNews#2_95': '로드쇼',
 
    'socialMedia#1': '더 많은 정보를 위해 다음의 소셜 미디어에 팔로우 하세요:',

    'teamAdvisersPartners#1': '핵심 팀 구성원',
    'teamAdvisersPartners#2': 'Cindx 고문',
    'teamAdvisersPartners#3': '우리의 파트너 ',
    // teamInfo
    'teamAdvisersPartners#4': 'Yuri Avdeev',
    'teamAdvisersPartners#5': '최고경영자(CEO)',
    'teamAdvisersPartners#6': '13년간의 기업가 활동',
    'teamAdvisersPartners#7': '9개의 벤처 기업 운영',
    'teamAdvisersPartners#8': '2백만 달러 규모 암호화폐 관리 ',
    'teamAdvisersPartners#9': 'Val Jerdes',
    'teamAdvisersPartners#10': '제품 이사',
    'teamAdvisersPartners#11': '20년간의 기업가 활동',
    'teamAdvisersPartners#12': '3개의 성공적 투자회수(Exit)',
    'teamAdvisersPartners#13': '포트폴리오 운영 4배 수익',
    'teamAdvisersPartners#14': 'Mofassair Hossain',
    'teamAdvisersPartners#15': '최고 마케팅 경영자',
    'teamAdvisersPartners#16': '15개 이상 ICO의 투자자 및 고문',
    'teamAdvisersPartners#17': '',
    'teamAdvisersPartners#18': 'GBAP 멤버',
    'teamAdvisersPartners#19': 'Artur Shamalov',
    'teamAdvisersPartners#20': '투자 이사',
    'teamAdvisersPartners#21': '15년간의 기업가 활동',
    'teamAdvisersPartners#22': 'mixcart.ru에 5000여 단체의 고객보유',
    'teamAdvisersPartners#23': 'dc-daily.ru에 mail.ru로부터 투자유치',
    'teamAdvisersPartners#24': 'Denis Eskenazi',
    'teamAdvisersPartners#25': '상품 마케팅 이사',
    'teamAdvisersPartners#26': '7년간의 기업가 활동',
    'teamAdvisersPartners#27': '고객으로부터 2백만 달러 유치',
    'teamAdvisersPartners#28': '10년간의 브랜딩 경험',
    'teamAdvisersPartners#29': 'Ibraghim Haniev',
    'teamAdvisersPartners#30': 'CTO',
    'teamAdvisersPartners#31': '6년 간의 기업경영',
    'teamAdvisersPartners#32': '풀스택 개발자',
    'teamAdvisersPartners#33': '정보 보안 및 블록체인 중점',
    'teamAdvisersPartners#34': 'Airat Shaikhulov',
    'teamAdvisersPartners#35': '양적 연구 이사',
    'teamAdvisersPartners#36': 'MSU 이학박사',
    'teamAdvisersPartners#37': 'Aton-line 내비게이터 개발자',
    'teamAdvisersPartners#38': '알고리즘 트레이딩 이사',
    'teamAdvisersPartners#39': 'Sofja Pevzner',
    'teamAdvisersPartners#40': '개인 정보 담당',
    'teamAdvisersPartners#41': '5년간의 기업가 활동',
    'teamAdvisersPartners#42': '에스토니아 AML 사무소에서 근무 허가 소유',
    'teamAdvisersPartners#43': ' 8년간의 법률 사무직 근무',
    'teamAdvisersPartners#44': 'Dave Gutierrez',
    'teamAdvisersPartners#45': '투자 관계 담당',
    'teamAdvisersPartners#46': '5년간 항공기 임대',
    'teamAdvisersPartners#47': '3년간의 투자 유치 경험',
    'teamAdvisersPartners#48': '2년간 러시아 해외 투자담당',
    'teamAdvisersPartners#49': 'Jelena Karzetskaja',
    'teamAdvisersPartners#50': '특별 감사 책임자',
    'teamAdvisersPartners#51': '다수의 법학 학위 소유',
    'teamAdvisersPartners#52': '1997년부터 사법 처리 담당',
    'teamAdvisersPartners#53': '법률 범위: 국제법',
    'teamAdvisersPartners#54': 'Wang Dong',
    'teamAdvisersPartners#55': '중국 블록체인 시장 전문가 ',
    'teamAdvisersPartners#56': '3년간 PHP/JavaScript 경험',
    'teamAdvisersPartners#57': '5년간의 SEO/SMM/미디어 바이어',
    'teamAdvisersPartners#58': '중국에서 2년간 블록체인 개발 및 토큰 세일',
    'teamAdvisersPartners#59_1': 'Sergey Potekhin',
    'teamAdvisersPartners#60_1': '개발자',
    'teamAdvisersPartners#61_1': '개발 6 년',
    'teamAdvisersPartners#62_1': '3 년 금융 기술 및 블록 체인 개발',
    'teamAdvisersPartners#63_1': '데이터 과학 경험',
    'teamAdvisersPartners#64_1': 'Georg Brich',
    'teamAdvisersPartners#65_1': '카오',
    'teamAdvisersPartners#66_1': '거래 7 년',
    'teamAdvisersPartners#67_1': '에서 경험하는 금융 기술 건축물 프로젝트 개발',
    'teamAdvisersPartners#68_1': '5 년 자산 운용',
    'teamAdvisersPartners#69_1': 'Yuri Proshin',
    'teamAdvisersPartners#70_1': '지역 사회 책임자',
    'teamAdvisersPartners#71_1': '국제 비즈니스에서 석사 학위',
    'teamAdvisersPartners#72_1': 'FINTECH 및 blockchain 신생 기업에서 사년',
    'teamAdvisersPartners#73_1': '지역 사회 관리, 홍보 및 커뮤니케이션에 중점을 둡니다.',
    'teamAdvisersPartners#74_1': 'Nick Begi',
    'teamAdvisersPartners#75_1': '개발자',
    'teamAdvisersPartners#76_1': '숙련 된 풀 스택 개발자',
    'teamAdvisersPartners#77_1': '빅 데이터 처리',
    'teamAdvisersPartners#78_1': '분석 및 시각화',
    'teamAdvisersPartners#79_1': 'Alexander Bogdanov',
    'teamAdvisersPartners#80_1': '',
    'teamAdvisersPartners#81_1': '12 년 이상의 경험을 가진 재무 및 투자 컨설팅 전문가',
    'teamAdvisersPartners#82_1': '마케팅 전략 개발',
    'teamAdvisersPartners#83_1': '',
 
    // advisers
    'teamAdvisersPartners#59': 'Keith Teare',
    'teamAdvisersPartners#60': 'Accelerated Digital Ventures의 창립자 및 집행위원장',
    'teamAdvisersPartners#61': 'Adviser to ContextGrid, INFARM, etc.',
    'teamAdvisersPartners#62': 'TechCrunch의 창립 주주',
    'teamAdvisersPartners#63': '저는 영국 벤처 회사인 Accelerated Digital Ventures의 투자위원회 위원장 겸 회장입니다, 영국에서 태어나고 자랐고요.',
    'teamAdvisersPartners#64': '캘리포니아에 있는 Archimedes Labs의 창립자이자 파트너였습니다. 제품을 만들고, 이야기를 구성하여 그것을 실제로 구현하기 위해 창립자, 팀, 디자이너, 엔지니어들과 일하는 것을 좋아합니다. 1980년대 초부터 많은 회사들을 창립, 혹은 공동 창립해왔습니다. 대부분 성공적으로 판매되었으며, 나머지는 상장하였습니다.',
    'teamAdvisersPartners#65': '그중 두 회사는 10억 달러 이상의 가치 평가를 받아 유니콘이라고 불립니다. 항상 변화가 일어나고 있는 시점에 초점을 맞추어 왔습니다. 1980년대에는 네트워킹과 데이터베이스. 1994년에서 1998년까지는 인터넷 액세스. 1998년에서 2010년까지는 웹서비스 및 콘텐츠, 모바일 소비자 애플리케이션. 2017년부터는 블록체인 이죠입니다.',
    'teamAdvisersPartners#66': '경력 및 프로젝트: Tech Crunch, Accelerated Digital Ventures, cScape, EasyNet, Cyberia, RealNames, Venture Network, Archimedes Labs 및 CINDX ',
    'teamAdvisersPartners#67': 'Austin Kimm',
    'teamAdvisersPartners#68': '국제 금융 서비스 CEO',
    'teamAdvisersPartners#69': '30년 이상의 금융 서비스 경험',
    'teamAdvisersPartners#70': '5억 달러 가치의 기업들 창업 경험',
    'teamAdvisersPartners#71': '경험이 풍부하고 입증된 금융 서비스 CEO입니다. 특히 개발도상국에서 보험 사험을 구축하는 데에 초점을 두고 있습니다. 이곳에서 5억 달러 이상의 현재 가치를 지닌 회사를 성공적으로 설립하였습니다.',
    'teamAdvisersPartners#72': '가장 최근 러시아에서 (지난 10년간) Workle이라는 러시아의 가장 큰 인터넷 판매 고용 플랫폼에서 CEO로 일했을 뿐만 아니라, 세계 최초의 디지털 암호 은행인 Crypterium을 포함하여 다양한 핀테크 스타트업 C 레벨 직책을 맡았습니다.',
    'teamAdvisersPartners#73': '경력 및 프로젝트: Aviva, Commercial Union UK, Reinaissance Insurance, Kimm Health & Leisure Ltd, Workle, CryptoIndex, CINDX',
    'teamAdvisersPartners#74': 'Philip Staehelin',
    'teamAdvisersPartners#75': '25년에 걸친 컨설팅 및 기업가 활동 경험 보유',
    'teamAdvisersPartners#76': '리더십을 가지고 5억 달러의 지불 사업 운영',
    'teamAdvisersPartners#77': '20개 국가에서 200개 이상의 스타트업 멘토',
    'teamAdvisersPartners#78': '혁신적인 리더이며, 한없는 낙천주의와 실용적인 사실주의를 조화시킬 줄 압니다. 다른 사람들이 보지 못하는 기회를 찾아냅니다.',
    'teamAdvisersPartners#79': '기업, 컨설팅 및 스타트업 환경에서 "사물을 다르게 보는" 능력 및 "일을 해내는" 능력을 발휘하였습니다.이는 변혁의 아이디어를 위한 기반이 되는 새로운 관점을 제공하였습니다.',
    'teamAdvisersPartners#80': '',
    'teamAdvisersPartners#81': '',
    'teamAdvisersPartners#82': 'Amarpreet Singh',
    'teamAdvisersPartners#83': 'Global Blockchain Foundation 선임 고문',
    'teamAdvisersPartners#84': 'Microsoft, The World Bank and Airbus에서의 경험 보유',
    'teamAdvisersPartners#85': '블록체인 프로젝트 자문 위원',
    'teamAdvisersPartners#86': '기술 업계에서 수년간의 경험을 쌓은 기술/디지털 애호가이자 노련한 전문가 – 운영, 컨설팅 및 혁신 인증된 클라우드 (Microsoft 및 AWS) 전문가입니다.',
    'teamAdvisersPartners#87': '다재다능함과 유연성으로 유명합니다. 뛰어난 분석, 전략, 리더십 스킬을 가졌으며 최고의 팀원입니다.',
    'teamAdvisersPartners#88': '특기: 기술 컨설팅, 운영, 클라우드 컴퓨팅, 핀테크, 혁신, ERP, 머신 러닝, 계정 관리, 파트너 개발, 비즈니스 개발 및 전략.',
    'teamAdvisersPartners#89': 'Amarpreet Singh은 매우 다양한 업무 경험과 교육 배경을 가지고 있습니다. 3개의 석사 학위를 가지고 있으며 인도, 싱가포르, 프랑스, 중국, 남아프리카, 한국, 캐나다에서 거주, 근무 공부하였습니다.',
    'teamAdvisersPartners#90': '경력 및 프로젝트: Microsoft, The World Bank, Airbus, TokenAsia Platform, CINDX ',
    'teamAdvisersPartners#91': 'Tyler Sanford',
    'teamAdvisersPartners#92': 'Yelp와 Zenefits에서 8년간 디지털 마케팅',
    'teamAdvisersPartners#93': '',
    'teamAdvisersPartners#94': '블록체인 얼리 어답터',
    'teamAdvisersPartners#95': '운동 선수로 자라면서 경력과 개인 생활에 적용할 수 있는 스킬을 얻었습니다.',
    'teamAdvisersPartners#96': '경력을 추구하면서 경험한 최고점과 최저점을 다루는 법을 배우는 동안 항상 스스로 동기 부여하며, 비즈니스의 모든 측면에서 지식과 재능이 성장하는 것을 기대하고 있습니다',
    'teamAdvisersPartners#97': '. ',
    'teamAdvisersPartners#98': '항상 도전하며, 동기는 목표를 달성하기 위해 장애물을 극복하는 것에서 비롯됩니다.',
    'teamAdvisersPartners#99': '경력 및 프로젝트: IPG Holdings, DataBlockChain, TraXion by Puma Technologies, ELYSIAN, Etherinc, FTEC Foundation, CINDX ',
    'teamAdvisersPartners#100': 'William Zhu',
    'teamAdvisersPartners#101': 'Blockchainer & J & C Capital 설립자',
    'teamAdvisersPartners#102': '절강 대학교 객원 교수',
    'teamAdvisersPartners#103': '전 OTC 데이터베이스 (상하이) 회장',
    'teamAdvisersPartners#104': 'Mr.Zhu 은 금융 박사 과정 학생,보유하고 학사학위의 법률 및 마케팅. 이제 그는 행정 사무총장과 신흥 산업 협회(상하이),객 절강 대학의 교수,전문 강사의 상하이시 관광을 관리합니다.',
    'teamAdvisersPartners#105': '이전에는 의장이 의약품의 데이터베이스(상하이)정보 기술 Co., Ltd.), 일반 관리자의 은련카드 스마트는 빅 데이터와 인터넷 금융 연구소,그리고 CEO 로의 CDMC.',
    'teamAdvisersPartners#106': 'Blockchain 은 고객에게 제공하는 포괄적인 다음과 같은 서비스를 제공합니다:정상 조직이 글로벌 비즈니스 여행 안내소,만남 호스트 및 사용자 정의 미디어 및 소셜 미디어 캐스팅,VIP 리소스를 연결,현지화 서비스입니다.',
    'teamAdvisersPartners#107': '이 여섯 비즈니스 세그먼트를 제공하는 핵심 체인 서비스의 주위에 머리에 기록 기업,빌딩전을 확장하는 기업을 서비스합니다',
    'teamAdvisersPartners#108': '',
    'teamAdvisersPartners#110': 'The Blockchainer10 년 이상의 경험에서 과학기술융 팀은 최초의 기록 지지하고 실천 중국에서 최초 blockchain 생태계 핵심 플레이어 중국에 초점을 맞추고 blockchain 분야 제공하고,심층적이고 수직 글로벌 전문 서비스,하나의 산업에서 가장 포괄적인 네트워크에서 블록체인 업계의 업스트림 및 다운스트림.',
    //'teamAdvisersPartners#111': 'Technology/Digital enthusiast and a seasoned professional with years of experience in Tech industry – operations, consulting and innovation.',
    // 'teamAdvisersPartners#112': 'He is a certified Cloud (Microsoft and AWS) specialist. Known for versatility and flexibility. Excellent analytic, strategic, leadership skills and a team player at its best.',
    // 'teamAdvisersPartners#113': 'Specialties: Technology Consulting, Operations, Cloud Computing, Fintech, Innovation, ERP, Machine Learning, Account Management, Partner Development, Business Development and Strategy',
    // 'teamAdvisersPartners#114': 'Amarpreet Singh has a very diversified work and educational background. He holds three Masters degrees and has lived/worked/studied in India, Singapore, France, China, South Africa, Korea, Canada.',
    // 'teamAdvisersPartners#115': 'Career and projects: Microsoft, The World Bank, Airbus, TokenAsia Platform, GOeurika, CINDX',
    // 'teamAdvisersPartners#116': 'Tyler Sanford',
    // 'teamAdvisersPartners#117': '8 Years Digital Marketing in Yelp and Zenefits',
    // 'teamAdvisersPartners#118': '12 ICO’s over $225M raised',
    // 'teamAdvisersPartners#119': 'Early adopter of blockchain',
    // 'teamAdvisersPartners#120': 'Growing up as an Athlete has given me a skill set that I\'ve been able to apply to my careers and personal life.',
    // 'teamAdvisersPartners#121': 'I have been very fortunate to see the results of going that extra mile, while learning to deal with the highs and lows that go along with all professional careers.',
    // 'teamAdvisersPartners#122': 'I\'m an extremely self-motivated individual, always looking to continue my growth of knowledge and talents in all aspects of business.',
    // 'teamAdvisersPartners#123': 'Always up for a challenge, my motivation comes from overcoming obstacles to reach my goals',
    // 'teamAdvisersPartners#124': 'Career and projects: IPG Holdings, DataBlockChain, TraXion by Puma Technologies, ELYSIAN, Etherinc, FTEC Foundation,  CINDX',
    'teamAdvisersPartners#125': 'Bogdan Fiedur',
    'teamAdvisersPartners#126': '블록체인 및 암호화폐 전문가, 투자자, 기업가',
    'teamAdvisersPartners#127': 'bitJob 공동 창립자 및 Blockchainexperts.io 대표',
    'teamAdvisersPartners#128': 'Blockchain Expert',
    'teamAdvisersPartners#129': 'Bogdan Fiedur는 스마트 계약 개발자, 암호화폐 투자자, 기업가이자 http://blockchainexperts.io의 대표입니다. e-커머스 웹사이트 구축에 20년이 넘는 IT업계 경험을 가지고 있으며, 지난 3년간 블록체인 개발에 참여해왔습니다.',
    'teamAdvisersPartners#130': '또한 성공적인 20개의 ICO와 함께 일했던 경험이 있는 ICO 고문이기도 합니다. 위니펙의 암호화폐 커뮤니티에서 블록체인 및 스마트 계약 프로그래밍에 대한 프레젠테이션 및 워크숍을 제공하기도 했습니다.',
    'teamAdvisersPartners#131': 'Bogdan은 이제 블록체인 업계에 중점을 두고 조언, 컨설팅 서비스, 블록체인 전문가와의 연결을 제공합니다. Bogdan은 최근 학생들을 위한 프리랜서 블록체인 플랫폼인 Bitjob을 공동 창립하였으며, 여러 ICO 프로젝트에 팀원으로 참여했습니다. (예: PayPie.com).  ',
    'teamAdvisersPartners#132': '경력 및 프로젝트: US Blockchain Association, Tuurnt, Trends Project, BiNeuro, Zichain, Graphen Tech, CyClean, Swachhcoin, CINDX ',
    'teamAdvisersPartners#133': 'Graham Doggart',
    'teamAdvisersPartners#134': '20년간 기업 개발, 핀테크 마케팅 감사',
    'teamAdvisersPartners#135': '1억 달러 가치의 캠페인 실행',
    'teamAdvisersPartners#136': '올해 10개 이상의 블록체인 프로젝트 고문',
    'teamAdvisersPartners#137': 'Stealth Cryptocurrency Project의 회장이며, 2012년부터 블록체인 및 핀테크 자문 회사인 Dynamic Abundance의 공동 창립자이자 수석 전략가였습니다.',
    'teamAdvisersPartners#138': '토큰 제공의 구조화와 패키징을 전문으로 하며 국제적으로 수많은 블록체인, 암호화폐, ICO 프로젝트를 수행했습니다.',
    'teamAdvisersPartners#139': '20년 이상 기술 경영 컨설팅, 법률 및 금융 준수 자문, 성장 전략, 투자자 관계 및 디자인 등을 제공해왔습니다. ',
    'teamAdvisersPartners#140': '경력 및 프로젝트: Stealth Cryptocurrency Project, Dynamic Abundance, ELYSIAN, Fox Trading FX, Profede, Worltdopoly, CINDX ',
    'teamAdvisersPartners#141': 'Sadie Hutton',
    'teamAdvisersPartners#142': '25년간 고위 경영 및 경영 컨설팅',
    'teamAdvisersPartners#143': ' 7,500만 파운드(£) 가치의 캠페인 집행 경력을 보유한 수백만 달러 펀드레이저',
    'teamAdvisersPartners#144': '8개 프로젝트의 ICO 고문',
    'teamAdvisersPartners#145': '블록체인 자문 회사인 Dynamic Abundance의 창립자입니다.',
    'teamAdvisersPartners#146': '직접 참여"하는 방식을 선호하며, ICO 분석, 프로젝트 관리, ICO 프로세스의 모든 주요 측면을 전문으로 합니다. 사업 관리 및 성장에 특화되어 있어서 프로젝트를 시작부터 끝까지 볼 수 있습니다. 선천적으로 문제 해결에 능하며 맡은 프로젝트는 개인적으로 받아 들입니다. 이기는 것을 좋아합니다!',
    'teamAdvisersPartners#147': '20년 이상의 경영 경험을 통해 강력한 네트워크를 구축하였습니다. 고급 블록체인 프로젝트에 대해 금융, 기술, 영업, 마케팅 및 품질 보증에 적극적으로 조언합니다. ',
    'teamAdvisersPartners#148': '경력 및 프로젝트: Dynamic Abundance, ELYSIAN, Worldopoly, Fox Trading FX, Profede, Market Digital, CINDX',
    'teamAdvisersPartners#149': 'Jason King',
    'teamAdvisersPartners#150': '20년간 경영진 경력',
    'teamAdvisersPartners#151': '30개 국가에서 비즈니스 운영',
    'teamAdvisersPartners#152': '5억 달러의 손익에 대해 책임',
    'teamAdvisersPartners#153': '거의 20년간 Jason King은 상업 거래의 구조 및 중개, 협상 승리, 시장 구축 및 성공을 위한 조직 포지셔닝과 관련하여 기술, 미디어, 통신 전문가로 일해왔습니다. ',
    'teamAdvisersPartners#154': '이러한 적절한 도전들이 성공적인 구조 조정 및 전환 역할로 저를 이끌었습니다.',
    'teamAdvisersPartners#155': ' 가장 수익성이 높은 목표를 달성할 수 있도록 적절한 인력과 팀을 구성하였습니다.',
    'teamAdvisersPartners#156': 'Jason은 비즈니스 및 운영 관리, 전략 기획 및 재무, 암호화폐 및 블록체인 업계를 비롯한 다양한 분야에서 방대한 경험을 가지고 있습니다.',
    'teamAdvisersPartners#157': 'Motti Peer',
    'teamAdvisersPartners#158': '수상 경력을 가진 글로벌 PR 에이전시 Blonde 2.0의 공동 창립자',
    'teamAdvisersPartners#159': '보안 및 금융 분야에서 다수의 회사 설립',
    'teamAdvisersPartners#160': 'Motti는 블록체인과 암호화폐, 스타트업, 벤처 캐피털을 위한 PR 분야의 선두 주자 중 하나입니다.',
    'teamAdvisersPartners#161': 'Motti는 보안 및 금융 분야에서 다수의 회사를 설립하였습니다. 자금과 헤지 펀드 매니저로서 수년간의 경험을 가지고 있습니다. 맨체스터 대학교에서 금융 및 마케팅 MBA를, Fairleigh Dickinson 대학교에서 경영학 학사를 취득하였습니다.',
    'teamAdvisersPartners#162': '수상 경력이 있는 글로벌 PR 에이전시인 Blonde 2.0의 공동 CEO이며, 이 회사는 포브스에서 "Movers and Shakers"로 선정되기도 했습니다. Blonde 2.0의 공동 CEO로서 전략 및 위기 관리와 함께 홍보 활동을 담당합니다.',
    'teamAdvisersPartners#163': '35명의 전문가로 이루어진 팀을 가진 Blonde 2.0은 블록체인과 암호화폐, 스타트업, 벤처 캐피털을 위한 PR 분야의 선두 주자 중 하나입니다.',
    'teamAdvisersPartners#164': 'Leonard Grayver',
    'teamAdvisersPartners#165': 'Greenberg, Whitcombe & Takeuchi의 파트너',
    'teamAdvisersPartners#166': 'Leonard는 다양한 국제 벤처 자금과의 강력한 전문적인 관계를 구축했습니다.',
    'teamAdvisersPartners#167': '로스쿨 재학 중에 자신의 스타트업을 위해 1천 8백만 달러 이상을 모금했습니다.',
    'teamAdvisersPartners#168': 'Leonard Grayver은 구소련에서 태어났으며, 어릴 적 부모님과 함께 미국으로 이주하였습니다. 로스쿨에 재학 중일 때, 스타트업을 만들었으며 1천 8백만 달러 이상을 성공적으로 모금하였습니다.',
    'teamAdvisersPartners#169': '그 기업은 살아남지 못했지만, 그 이후로 Leonard는 400개 이상의 스타트업을 도왔습니다.',
    'teamAdvisersPartners#170': 'Leonard는 벤처 캐피털 자금 조달, 자금 구조화, 기업 실사(펀드와 LP 모두를 대표)에 10년 이상의 경험을 보유하고 있습니다. Leonard 는 모든 기업가들에게 조언합니다 - 트렌드를 따르지 말고, 본인의 관심사를 따르세요.',
    'teamAdvisersPartners#171': 'Kosol Saisanit',
    'teamAdvisersPartners#172': 'Power C Technology Co., Ltd, C Power Solutions Co., Ltd 및 CoachHub 설립자',
    'teamAdvisersPartners#173': '경영 전문가 및 마케팅 전문가',
    'teamAdvisersPartners#174': '동남아시아 최대의 FMCG 브랜드 매니저',
    'teamAdvisersPartners#175': '씨 Saisanit 대학교 태국 가정대학에서 MBA 를 Executive management 및 BBA 주요 마케팅이다.',
    'teamAdvisersPartners#176': '그 이전에 개최되는 위치의 브랜드 관리자의 몽드 Nissin,가장 큰 중 하나의 선수 FMCG 시장에 남쪽으로 아시아 지역입니다. 또한,그가 광범위한 경력에서 부동산 서비스 회사,CB Richard Ellis.',
    'teamAdvisersPartners#177': '그의 회사,C 전원 기술 Co., 주식 회사,에너지 서비스 회사(ESCO)전문으로 줄여 에너지 손실과 전력품질 향상을 통해 혁신적인 기술입니다. C 전원 솔루션 Co., 주식 회사는 온라인 마케팅 지점을 지원,클라이언트에 온라인 브랜딩 전략적 계획,마케팅 커뮤니케이션을 계획하고 관리자에서 마케팅 및 판매 계획입니다.',
    'teamAdvisersPartners#178': 'Teo Lincoln',
    'teamAdvisersPartners#179': 'Intel Wise Group의 창립자 겸 CEO',
    'teamAdvisersPartners#180': 'DP Information Group 전 전무 이사',
    'teamAdvisersPartners#181': '브루나이 중앙 은행 고문 브루나이 다루 살람 (AMBD)',
    'teamAdvisersPartners#182': '링컨은 Intel Wise Group의 창립자이자 CEO입니다.',
    'teamAdvisersPartners#183': '이에 앞서,그는 최고 운영 책임자 및 관리 감독의 DP 정보를 그룹의 자회사–의 plc 그리고 관리되는 여러 데이터 저장소는 동남 아시아에서(포함하여 싱가포르,말레이시아,인도네시아,베트남,브루나이).',
    'teamAdvisersPartners#184': '링컨의 경험을 포함한 위험 관리 및 IT 인프라와 미국의 해외 은행의 그룹 스탠다드차타드은행. 통계로,그가 도입 신용 득점을 여러 개의 은행에서 아시아-태평양하고 또한 찾을 봉사하는 시간에 프로젝트 팀은 세계 은행의 그룹과 아시아 개발 은행합니다. 인텔',
    'teamAdvisersPartners#185': '우산 아래의 충칭의 연결 이니셔티브,싱가포르 중국 정부는 양자 프로젝트에 인텔명을 기획하고있는 데이터에서 100 개 이상의 백만 회사는 중국에서 도와 전 세계의 유명한 기업의 규정 준수함으로 필요합니다. 더 최근에,회사에 착수했 blockchain 기술 개발 플랫폼에 대한 금융서비스 거래의 디지털 자산입니다. 또한,링컨은 또한 고문 브루나이의 중앙 은행 Autoriti Monetari 브루나이 다루살람(AMBD).',
    'teamAdvisersPartners#186': '또한,링컨은 또한 고문 브루나이의 중앙 은행 Autoriti Monetari 브루나이 다루살람(AMBD).',
    'teamAdvisersPartners#187': 'Reinhard Berger',
    'teamAdvisersPartners#188': 'Reinhard Berger는 혁신적인 DLT 기술에 중점을 둔 VC 회사 인 Pecunio Blockchain Technologies의 CEO입니다.',
    'teamAdvisersPartners#189': 'Accenture의 Business Architect로 Credit Suisse, UBS 및 Invesco를 컨설팅했습니다. capgemini의 교장이었습니다.',
    'teamAdvisersPartners#190': '관$250M 에서 대체 자산을 통해 투자 금융 AG2003 년 이후',
    'teamAdvisersPartners#191': '그분을 보유하고 MSc 에서 컴퓨터 과학,M. 는 법률에,뿐만 아니라에서 MBA 를 다뉴브 대학교도 있습니다.',
    'teamAdvisersPartners#192': '하기 전에는 금전,라인하르트의 경력으로 시작업 건축가를 위한 글로벌 컨설팅 회사인 액센츄어,클라이언트와 같은 크레딧 스위스 UBS 및 Invesco.',
    'teamAdvisersPartners#193': '그 다음으로 봉사자 프랑스의 기술 거 캡 제미니. 2003 년에,Reinhard 공동 설립 대체 투자 금융 AG,독립 헤지 펀드,연산자는 그는 대안을 초과하는 자산$250M.',
    'teamAdvisersPartners#194': '직관적인 지도자,자연적인 멘토이자 진정한 전략가 Pecuino 의 투구.',
    'teamAdvisersPartners#195': '',
    'teamAdvisersPartners#196': '',
    'teamAdvisersPartners#197': '',

    //urgentnews
    'urgentnews#g1': '모두 보기 게시물',
    'urgentnews#g2': '자세히보기',
    'urgentnews#1': 'Initial Circulation Supply of CINXO',            //title
    'urgentnews#2': '4 MIN READ',                                      // readtime
    'urgentnews#3': 'Important information about CINDX tokens!',      // mainThought
    'urgentnews#4': 'https://medium.com/cindx/the-initial-circulating-supply-of-cinxo-tokens-386ae92b57cd', // link
    'urgentnews#5': 'CINDX Token Benefits For Token Holders',
    'urgentnews#6': '4 MIN READ',
    'urgentnews#7': 'Get to know more about the CINDX tokens!',
    'urgentnews#8': 'https://medium.com/cindx/cindx-token-benefits-for-token-holders-d3228205117c',
    'urgentnews#9': 'CINDX FAQ',
    'urgentnews#10': '6 MIN READ',
    'urgentnews#11': 'Frequently Asked Questions About CINDX',
    'urgentnews#12': 'https://medium.com/cindx/frequently-asked-questions-about-cindx-692cae405d6',
    'urgentnews#13': 'CINDX launches on Bitforex',
    'urgentnews#14': '3 MIN READ',
    'urgentnews#15': 'CINDX Premium Token Offering on Bitforex',
    'urgentnews#16': 'https://event.bitforex.com/kr/CINXO.html',
    'urgentnews#17': 'Official Social Media Channels for CINDX',
    'urgentnews#18': '2 MIN READ',
    'urgentnews#19': 'The given list contains all of our official channels and online media',
    'urgentnews#20': 'https://medium.com/cindx/official-social-media-channels-for-cindx-4c44759dffe8',

//    'joinTokenPreSale#1': '토큰 프리세일 참여하기!',
//    'joinTokenPreSale#2': '프리세일 참여하기 ',
//    'joinTokenPreSale#3': 'MVP 테스트 ',
    'joinTokenPreSale#4': '본사 ',
    'joinTokenPreSale#5': 'Harjumaa, Erika 14,',
    'joinTokenPreSale#6': 'Tallinn, 10416, Estonia',
    'joinTokenPreSale#7': '비즈니스 문의 | hello@cindx.io',
//    'joinTokenPreSale#8': 'Legal Disclaimer',
//    'joinTokenPreSale#9': '개발 및 마케팅',
//    'joinTokenPreSale#10': 'Presnenskaya naberezhnaya, 8 building 1,',
//    'joinTokenPreSale#11': 'Moscow, 123112, Russia',
//    'joinTokenPreSale#12': '커리어 | 팀에 참여하세요 |',
    'joinTokenPreSale#13': '파트너:',
    'joinTokenPreSale#14': '사용자 동의',
    'joinTokenPreSale#15': '개인 정보 보호 정책',
    'joinTokenPreSale#16': '구독하에서 최신 업데이트 CINDX',
    'joinTokenPreSale#17': '구독',
    'joinTokenPreSale#18': '',
    'joinTokenPreSale#19': '',
    'joinTokenPreSale#20': '',

    'mvpBlock#1': 'CINDX Live',
    'mvpBlock#2': 'CINDX 플랫폼을 출시! 가입하세요!',
    'mvpBlock#3': '가입',
    'mvpBlock#4': '',
    'mvpBlock#5': '',
    'mvpBlock#6': '',
    'mvpBlock#7': '',
    'mvpBlock#8': '',
    'mvpBlock#9': '',
    'mvpBlock#10': '',
    'mvpBlock#11': '',
    'mvpBlock#12': '',
    'mvpBlock#13': '',
    'mvpBlock#14': '',

    'common#g1': '이메일',

    'subscribeBlock#1': 'CINDX에 대해 먼저 알아야합니다.',

    'link#1_bitforex': 'https://event.bitforex.com/kr/CINXO.html',
    'link#2_help': 'https://medium.com/cindx/how-to-buy-cinxo-tokens-7fc82a27ba47?postPublishedType=repub',
}

export default ko