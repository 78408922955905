// Английский
const en = {
    'mainBlock#1': 'The one-stop solution for crypto asset management',
    'mainBlock#2': 'Earn along with the best traders',
    'mainBlock#3': 'CINDX Platform is Live Now!',

//Текст в плашке
    'headertext#0': 'CINDX Pre-IEO start in',      //сейчас ( есть таймер)
    'headertext#1': 'The CINDX Pre-IEO is Live!', //25.06 14-59 мск:
    'headertext#2': '2 round Pre-IEO start in', // 25.06 16-00 мск: ( есть таймер)
    'headertext#3': 'The CINDX Pre-IEO ends in',  // 26.06 15-00 мск ( есть таймер)
    'headertext#4': 'The CINDX Pre-IEO has ended.', // 27.06 15-00 мск(или раньше) первая строка  
    'headertext#4_1': '$400,000 worth of tokens sold.', // 27.06 15-00 мск(или раньше) вторая строка ( не показывать в моб)
    'headertext#5': 'Need Help?', 
// Кнопка в плашке
    'buttonheader#1': 'Buy CINXO on ', 
    'buttonheader#2': 'BitForex',   // Название биржи с эконкой на кнопке

//    'icoInfo#1': 'We accept:',
//    'icoInfo#2': 'Soft Cap Closed',
//    'icoInfo#3': 'Hard Cap Milestone: ',
//    'icoInfo#4': 'Listing Agreements are Signed With: ',

    'menu#1': 'WHITE PAPER', // распространяется также на кнопку в хэдере 
    'menu#2': 'PRESENTATION',
    'menu#3': 'ONE PAGER',  //распространяется также на кнопку в хэдере 
    'menu#4': 'Blog',     //распространяется также на кнопку в хэдере 
    'menu#5': 'LEGAL DOCS',
    'menu#6': 'EN',
    'menu#7': 'Product',
//    'menu#8': 'Sale Details',
    'menu#9': 'Team',
    'menu#10': 'Public Docs',
    'menu#11': 'CINDX LIVE',  //распространяется также на кнопку в хэдере 
    'menu#12': 'SUBSCRIBE',

    'mailForm#1': 'Wrong email',
    'mailForm#2': 'sending ..',
    'mailForm#3': 'Already was or is not valid, try again',
    'mailForm#4': 'Your email has been sent successfully',
    'mailForm#5': 'an error occurred, try again',
    'mailForm#6': 'GDRP Сompliance',
    'mailForm#7': 'Personal data provided via Subscription form and KYC procedure will be collected in the full compliance with GDRP The Data Protection Officer of the project is Mrs. Sofja Pevzner-Belositski. Contact details: dpo@cindx.io. Any data subject may, at any time, contact Data Protection Officer of directly with any request related to data protection. Your personal data provided via Subscription form and KYC procedure will be shared to our partners:',
    'mailForm#8': 'ICO Promo Privacy Policy document is available upon request via',
    'mailForm#9': 'Sum & Substance',
    'mailForm#10': 'By default, the personal data provided by subscriber are kept by CINDX, ICO Promo and Sum & Substance for 5 years after registration of subscriber in the system If you do not agree to this privacy policy, please do not use our site. By using our site, you consent to the collection and use of information by us. Owing to the global nature of the internet infrastructure, the information you provide may be transferred in transit to countries outside the European Economic Area that do not have similar protections in place regarding your data and its use as set out in this policy. However, we have taken the steps outlined above to try to improve the security of your information. By submitting your information you consent to these transfers',
    'mailForm#17': 'I agree to process my',
    'mailForm#18': 'personal data',
    'mailForm#19': 'Security Token Sale Is Live',
    'mailForm#20': '20% BONUS',
    'mailForm#21': 'Get it before everyone else does',
    'mailForm#22': 'Token sale starts in',
    'mailForm#23': '$1 now',
    'mailForm#24': '$1 later',
    'mailForm#25': 'Your Email',
    'mailForm#26': 'BUY CINX TOKENS',
    'mailForm#27': 'AGREE & SUBMIT',
    'mailForm#28': 'Get it before everyone else does Offer expires within',
    'mailForm#29': 'Want to get the latest news and upcoming offers?',
    'mailForm#33': 'GET STARTED',
    'mailForm#34': 'PROSPECTUS',
    'mailForm#35': 'TECH WHITEPAPER',
    'mailForm#36': '',
    'mailForm#37': '',
    'mailForm#38': '',
    'mailForm#39': '',
    'mailForm#40': '',
    'mailForm#41': '',

 //   'docPopup#1': 'Receive all documents in your email',
 //   'docPopup#2': 'Enter your email',
 //   'docPopup#3': 'SEND ME DOCS',

 //   'progressBarSale#1': 'Bonuses',
 //   'progressBarSale#2': 'in CINX',
 //  'progressBarSale#3': 'Pre-Sale',
 //   'progressBarSale#4': 'Sale',
 //   'progressBarSale#5': 'Aug 26',
 //   'progressBarSale#6': 'Sep 17',
 //   'progressBarSale#7': 'Oct 8',
 //   'progressBarSale#8': 'Oct 22',
 //   'progressBarSale#9': 'Nov 6',
 //   'progressBarSale#10': 'Nov 19',
 //   'progressBarSale#11': 'Dec 3',CINDX IS LIVE NOW! 
 //   'progressBarSale#12': 'Dec 17',
 //   'progressBarSale#13': 'ONE PAGER',
 //   'progressBarSale#14': 'PRESENTATION',
 //   'progressBarSale#15': 'WHITEPAPER',
 //   'progressBarSale#16': 'FINANCIAL MODEL',
 //   'progressBarSale#17': 'LEGAL DOCS',
 //   'progressBarSale#18': 'Download Our Public Documents',
 //   'progressBarSale#19': '',
 //   'progressBarSale#20': '',
 //   'progressBarSale#21': '',
 //   'progressBarSale#22': '',

 //   'ourTopRatings#1': 'Our Top Ratings',

    'cryptoAssetsManagement#1.1': 'CINDX is a Сrypto Asset Management Hub.',
    'cryptoAssetsManagement#1.2': 'We solve the problems of investors, traders and developers',
    'cryptoAssetsManagement#2': 'СINDX.MARKET FOR INVESTORS',
    'cryptoAssetsManagement#3': 'Don’t worry about your knowledge and experience – the experts of crypto trading are here to help you',
    'cryptoAssetsManagement#4': 'Control your currency: your coins are kept in your wallet at all times',
    'cryptoAssetsManagement#5': 'Only pay if you earn: you will only be charged if your portfolio makes money',
    'cryptoAssetsManagement#6': 'CINDX LIVE',
    'cryptoAssetsManagement#7': 'CINDX.TRADE FOR TRADERS',
    'cryptoAssetsManagement#8': 'You can attract capital at $0 marketing costs',
    'cryptoAssetsManagement#9': 'We provide you with a Terminal, an App Store, a marketplace and other advanced tools to succeed in trading',
    'cryptoAssetsManagement#10': 'CINDX helps monetize your reputation and expertise. Your trade history and reputation are transparent',
    'cryptoAssetsManagement#11': 'CINDX.TALK, CINDX.HUB & CINDX.DAO',
    'cryptoAssetsManagement#12': 'CINDX.talk - a social network with a reward for useful content',
    'cryptoAssetsManagement#13': 'CINDX.hub - the component includes a vendor’s account, as well as a hub for testing and integrating third-party software',
    'cryptoAssetsManagement#14': 'CINDX.loan - a component that allows to provide and invest in crypto backed P2P loan',
    'cryptoAssetsManagement#15': 'CINDX.market is a marketplace for investors, where they can evaluate and select strategies to follow or indices to invest in.',
    'cryptoAssetsManagement#16': 'CINDX.trade is a module for traders for trading crypto assets on different exchanges in one place, analyzing the market, automating trading, and achieving outstanding gains.',
    'cryptoAssetsManagement#17': 'CINDX uses an API for collecting data and executing trades. The API and secret keys are no less secure than the users’ login and password details. To protect CINDX users, we keep all the secret API keys in a separate database using bank-level encryption.',
    'cryptoAssetsManagement#18': 'CINDX.HUB',
    'cryptoAssetsManagement#19': 'CINDX.hub is a component for creating algorithmic strategies, historical testing and analysis with a number of ready-to-use solutions.',
    'cryptoAssetsManagement#20': 'HUB.Pro is an option for professional traders, which allows automating trading strategies for 24/7 trading and earning.',
    'cryptoAssetsManagement#21': 'HUB.Lite is an option for investors, who can work with proven instruments backed by flexible risk settings.',
    'cryptoAssetsManagement#22': '',
    'cryptoAssetsManagement#23': '',
    'cryptoAssetsManagement#24': '',

   'teamLegal#1': 'We Have a Great Team and Legal Department',
    // 'teamLegal#2': 'Legal and Regulatory Organizational Framework',
    'teamLegal#3': 'CRYPTOCURRENCY EXCHANGE LICENSE',
    'teamLegal#4': 'FINANCIAL INSTITUTION LICENSE',
    'teamLegal#5': 'CRYPTO WALLET LICENSE',
    'teamLegal#6': 'WHITEPAPER',
    'teamLegal#7': 'PRESENTATION',
    'teamLegal#8': 'ONE PAGER',
    //'teamLegal#9': 'FINANCIAL MODEL',
    'teamLegal#10': 'LEGAL DOCS',

    //'roadmap#1': 'Roadmap for $21m Scenario',
    //'roadmap#2': 'SCENARIO',
    //'roadmap#3': 'We are building the base for the CINDX platform. At this step, the Minimum Viable Product (MVP) is developed. The MVP allows an access to exchanges and replication protocol. A large portion of our resources is allocated to the scalability and security of the platform.',
    //'roadmap#4': 'The CINDX.alpha version of the platform will be released. This includes a blockchain architecture and a basic system of settlement via smart contracts. The CINDX.market and CINDX.trade components will be  going through some alterations to add functionalities.  ',
    //'roadmap#5': 'Based on the blockchain architecture of the CINDX.alpha version, we will produce the CINDX.talk component. This will allow users to communicate, learn and earn with useful content. Additionally, an analytical module will be added to the CINDX.core component of the platform to analyze the market information and present it to the third-party services.      ',
    //'roadmap#6': 'At this stage, the CINDX.market and CINDX.trade components will be added. Managers can create index-based products, and the trading terminal is integrated with a flexible setting of risk management. ',
    //'roadmap#7': 'CINDX.talk will have public and private channels. At this stage, the content will  be able to be  separated. ',
    //'roadmap#8': 'There will be a big update for the platform at this stage: users will be able to store and exchange cryptocurrencies and fiat. CINDX.trade is an extremely flexible system of order execution and risk management. The Vendor’s market will appear; however, at this point, the Vendor’s market will only contain applications developed by CINDX and its partners.  ',
    //'roadmap#9': 'A tokenized rating system will be added in the final version of CINDX.talk, as well as a new type of product: “algorithmic trading”. We will also add an automatic arbitrage function that will operate between exchanges and multi-exchange orders.   ',
    //'roadmap#10': 'We are building the base for the CINDX platform. At this step, the MVP is developed. The MVP allows an access to exchanges and replication protocol. A large portion of our resources is allocated to the scalability and security of the platform. ',
    //'roadmap#11': 'The CINDX.alpha version of the platform will be released. This includes a blockchain architecture and a basic system of settlement via smart contracts. The CINDX.market and CINDX.trade components will be going through some alterations to add functionalities.  ',
    //'roadmap#12': 'Based on the blockchain architecture of the CINDX.alpha version, we will produce the CINDX.talk component. This will allow users to communicate, learn and earn with useful content. Additionally, an analytical module will be added to the CINDX.core component of the platform to analyze the market information and present it to the third-party services. Another new component, CINDX.hub, will enable Vendors to develop applications that will analyze the market data that CINDX provides. ',
    //'roadmap#13': 'At this stage, the CINDX.market and CINDX.trade components will be added. Managers can create index-based products, and the trading terminal is integrated with a flexible setting of risk management. An SDK for developers will be released. This should simplify application development and implementation for CINDX.  ',
    //'roadmap#14': 'CINDX.talk will have public and private channels. At this stage, the content will be able to be separated. CINDX.hub will integrate Github, which will simplify application development even more.',
    //'roadmap#15': 'There will be a big update for the platform at this stage: users will be able to store and exchange cryptocurrencies and fiat. CINDX.trade will be an extremely flexible system of order execution and risk management. CINDX.hub will have a full-scale editor for trading strategies with a testing system based on historical market data.',
    //'roadmap#16': 'A tokenized rating system  CINDX.talk will be added in the final version of CINDX.talk. This will provide a system of assessment for CINDX community trading experts. “Algorithmic trading” will also be added. We will also add an automatic arbitrage function that will operate between exchanges and multi-exchange orders. A high-grade platform for a code audit will be created. ',
    //'roadmap#17': 'We are building the base for the CINDX platform. At this step, the MVP is developed. The MVP allows an access to exchanges and replication protocol. A large portion of our resources is allocated to the scalability and security of the platform.',
    //'roadmap#18': 'The CINDX.alpha version of the platform will be released. This includes a blockchain architecture and a basic system of settlement via smart contracts. The CINDX.market and CINDX.trade components will be  going through some alterations to add functionalities. ',
    //'roadmap#19': 'Based on the blockchain architecture of the CINDX.alpha version, we will produce the CINDX.talk component. This will allow users to communicate, learn and earn with useful content. Additionally, an analytical module will be added to the CINDX.core component of the platform to analyze the market information and present it to the third-party services. Another new component,  CINDX.hub, will enable Vendors to develop applications that will analyze the market data that CINDX provides. The team will develop CINDX.dao, a component that acts as a system for the platform’s revenue distribution to its token holders.       ',
    //'roadmap#20': 'At this stage, the CINDX.market and CINDX.trade  components will be added. Managers can create index-based products, and the trading terminal is integrated with a flexible setting of risk management. An SDK for developers will be released. This should simplify application and implementation development for CINDX.  ',
    //'roadmap#21': 'CINDX.talk will have public and private channels. At this stage the content can be separated. CINDX.hub will integrate Github which will simplify application development even more.',
    //'roadmap#22': 'There will be a big update for the platform at this stage: users will be able to store and exchange cryptocurrencies and fiat. CINDX.trade will be an extremely flexible system of order execution and risk management. CINDX.hub has a full-scale editor for trading strategies with a testing system based on historical market data. CINDX.dao will receive a voting system for token holders. ',
    //'roadmap#23': 'A tokenized rating system CINDX.talk will be added in the final version of CINDX.talk. This will provide a system of assessment for CINDX community trading experts. “Algorithmic trading” will also be added. We will also add an automatic arbitrage function that will operate between exchanges and multi-exchange orders. A high-grade platform for a code audit will be created. ',
    'roadmap#24': 'Roadmap',
    //'roadmap#25': '',
    //'roadmap#26': '',

    'problemSolving#1': 'Time',
    'problemSolving#2': 'Trust',
    'problemSolving#3': 'Uncertainty',
    'problemSolving#4': 'I have some money to invest in the crypto market, but I don’t have the time to research and contact professional traders to manage my portfolio',
    'problemSolving#5': 'I have always felt uncomfortable with the idea of handing my money over to someone else to make a profit',
    'problemSolving#6': 'The technology surrounding crypto trading is not user-friendly. I can’t figure out how to trade my coins',
    'problemSolving#7': 'CINDX provides access to rated and trustworthy traders',
    'problemSolving#8': 'On the CINDX platform, your funds will never leave your wallet',
    'problemSolving#9': 'You earn right along with the best traders on the market',
    'problemSolving#10': 'Traders on CINDX are rated according to their history of trading success. Choose the trader that’s right for you',
    'problemSolving#11': 'Our decentralized system and smart contracts allow your funds to be managed without being transferred to another trader',
    'problemSolving#12': 'Every investor on CINDX has the opportunity to have their funds managed by the most successful traders on the market',
    'problemSolving#13': 'What Kinds of Problems Does CINDX Solve for Investors?',
    'problemSolving#14': 'CINDX LIVE',

    'howCindxWorks#1': 'Investors',
    'howCindxWorks#2': 'Traders',
    'howCindxWorks#3': 'Vendors',
    'howCindxWorks#4': 'Increase their crypto earnings with the portfolio management skills of top traders',
    'howCindxWorks#5': 'Earn commissions from the investors that follow them based on their performance and trading strategy',
    'howCindxWorks#6': 'Sell advanced trading tools, like trade robots and news widgets',
    'howCindxWorks#7': 'Collects commissions and subscription fees',
    'howCindxWorks#8': 'I’m joining CINDX with crypto assets that I want to be traded',
    'howCindxWorks#9': 'I’m joining CINDX with trading expertise that will benefit investors',
    'howCindxWorks#10': 'I’m joining CINDX with trading apps that will help investors',
    'howCindxWorks#11': 'We’re here to make trading easy, secure and profitable',
    'howCindxWorks#12': 'How CINDX Works',

    'marketAnalytics#1': 'Market Analytics',
    'marketAnalytics#2': 'Growth of Crypto Assets Under Management (millions, USD)',
    'marketAnalytics#3': 'The Number of Online Traders by Region (thousands, people)',
    'marketAnalytics#4': 'Monthly Trading Volume on Cryptomarkets (billions, USD)',
    'marketAnalytics#5': 'The year 2018 saw an overall declining trend in the prices of cryptocurrencies, but the industry has continued to develop as new liquidity was directed into assets under fund management.',
    'marketAnalytics#6': '',
    'marketAnalytics#7': 'The growth in the number of online traders by regional segregation in 2017 and 2018. The leaders of 2018 were Asia and Europe mostly due to the advent of crypto trading, which has a low entry threshold for new participants.',
    'marketAnalytics#8': '',
    "marketAnalytics#_9": 'The volume of trading on the crypto market has already exceeded the volumes of the end of 2017, when cryptocurrencies were on hype. Despite the almost annual bear market, trading is developing very actively, as indicated by research data.',
    "marketAnalytics#_10": '',
    //'marketAnalytics#9': 'WHITEPAPER',
    //'marketAnalytics#10': 'PRESENTATION',
    //'marketAnalytics#11': 'ONE PAGER',
    //'marketAnalytics#12': 'FINANCIAL MODEL',
    //'marketAnalytics#13': 'LEGAL DOCS',
    'marketAnalytics#14': 'Geographic Scope',
    'marketAnalytics#15': 'More than 25 million potential Managers and Traders',
    'marketAnalytics#16': '',
    'marketAnalytics#17': 'More than 100 million potential investors and',
    'marketAnalytics#18': 'transactions every day',
    'marketAnalytics#19': 'More than 1000 institutional partners,',
    'marketAnalytics#20': 'each with $3-5 billion',
    'marketAnalytics#21': 'By Cryptofundresearch.com', //(1 слайд вместо Reference)
    'marketAnalytics#22': 'By Brokernotes.co',         //(2 слайд вместо Reference)
    'marketAnalytics#23': 'By Coinmarketcap.com',      //(3 слайд вместо Reference)

    //'tokenSaleDetails#1': 'Token Allocation',
    //'tokenSaleDetails#2': 'Team',
    //'tokenSaleDetails#3': 'Bounty',
    //'tokenSaleDetails#4': 'Advisers',
    //'tokenSaleDetails#5': 'Reserve',
    //'tokenSaleDetails#6': 'Community Grants',
    //'tokenSaleDetails#7': 'Token Liquidity Support',
    //'tokenSaleDetails#8': 'Available for Sale',
    //'tokenSaleDetails#9': 'Token Name:',
    //'tokenSaleDetails#10': 'Token Pre-Sale start:',
    //'tokenSaleDetails#11': 'Total Emission:',
    //'tokenSaleDetails#12': 'Price Per Token:',
    //'tokenSaleDetails#13': 'Minimal Investment:',
    //'tokenSaleDetails#14': '$ 50 equivalent',
    //'tokenSaleDetails#15': 'WHITEPAPER',
    //'tokenSaleDetails#16': 'PRESENTATION',
    //'tokenSaleDetails#17': 'ONE PAGER',
    //'tokenSaleDetails#18': 'FINANCIAL MODEL',
    //'tokenSaleDetails#19': 'LEGAL DOCS',
    //'tokenSaleDetails#20': 'Use of Proceeds',
    //'tokenSaleDetails#21': 'Marketing and PR 21%',
    //'tokenSaleDetails#22': 'Research and Development 41%',
    //'tokenSaleDetails#23': 'Operations 28%',
    //'tokenSaleDetails#24': 'Legal expenses 7%',
    //'tokenSaleDetails#25': 'Community Grants 3%',
    //'tokenSaleDetails#26': 'Token Deals',
    //'tokenSaleDetails#27': 'Token Sale:',
    //'tokenSaleDetails#28': 'Token Pre-Sale Bonuses:',
    //'tokenSaleDetails#29': 'Aug 26 - Sep 16 - 25% Bonus',
    //'tokenSaleDetails#30': 'Sep 17 - Oct 7 - 20% Bonus',
    //'tokenSaleDetails#31': 'Token Main Sale Bonuses:',
    //'tokenSaleDetails#32': 'Oct 8 - Oct 21 - 17% Bonus',
    //'tokenSaleDetails#33': 'Oct 22 - Nov 4 - 15% Bonus',
    //'tokenSaleDetails#34': 'Nov 6 - Nov 18 - 13% Bonus',
    //'tokenSaleDetails#35': 'Nov 19 - Dec 2 - 10% Bonus',
    //'tokenSaleDetails#36': 'Dec 3 - Dec 16 - 5% Bonus',
    //'tokenSaleDetails#37': 'Dec 17 - Dec 26 - 0% Bonus',
    //'tokenSaleDetails#38': 'buy tokens',
    //'tokenSaleDetails#39': 'Token Sale Details',
    //'tokenSaleDetails#40': 'During the token sale, investors purchase utility tokens – options [CINXO], which give them the right to receive security tokens [CINX] in the future.',
    //'tokenSaleDetails#41': 'Security tokens [CINX] will be issued and distributed following the prospect of emission published on the website of FSA of Estonia https://www.fi.ee/ and cindx.io after the end of the token sale, but no later than Q1 2019.',
    //'tokenSaleDetails#42': 'The holder of [CINXO] tokens has the right but no obligation to exchange options for security tokens with a ratio of 1:1 after completing the KYC procedure with identification signature, address confirmation and supply of other documents (see the KYC diagram).',
    //'tokenSaleDetails#43': 'Tokens [CINXO] grant the same rights as security tokens apart from the right to participate in revenue distribution with a lock-up and voting on the platform.',

    //'legalSchedule#1': 'Legal Schedule',
    //'legalSchedule#2': 'RECEIVED LICENSE (FVR000110) TO EXCHANGE VIRTUAL CURRENCY TO FIAT SERVICES',
    //'legalSchedule#3': 'APPLIED FOR TRADEMARK REGISTRATION IN ESTONIA AND EU',
    //'legalSchedule#4': 'RECEIVED LICENSE (FFA000254) FOR OPERATING AS A FINANCIAL INSTITUTION',
    //'legalSchedule#5': 'RECEIVED LICENSE FFA000254 FOR OPERATING AS A FINANCIAL INSTITUTION',
    //'legalSchedule#6': 'WORKED OUT PROSPECTUS IN ACCORDANCE WITH ESTONIAN LAW',
    //'legalSchedule#7': 'CLAIMED FSA LICENSE (ESTONIAN FINANCIAL SUPERVISION AUTHORITY)',
    //'legalSchedule#8': 'RECEIVED LICENSE FOR PROVIDING A VIRTUAL CURRENCY WALLET SERVICE',
    //'legalSchedule#9': 'REGISTERED UNDER EXEMPTION D IN SEC',
    //'legalSchedule#10': 'APPOINTED A PERSONAL DATA MANAGER TO MEET GDPR REQUIREMENTS',
    //'legalSchedule#11': 'RECEIVED THE FSA LICENSE',
    //'legalSchedule#12': 'REGISTERED THE PROSPECTUS EU 809-2004',
    //'legalSchedule#13': 'ISSUED CINX SECURITY TOKENS',

    'feedbackProject#_1': 'video',
    'feedbackProject#1': 'Feedback On Our Project',
    'feedbackProject#2': 'Val Jerdes',
    'feedbackProject#3': 'Product Director',
    'feedbackProject#4': 'Partner at Innov8 Global Ventures | Advisory | Labs',
    'feedbackProject#5': 'video',
    'feedbackProject#6': '“CINDX has a platform that plays in a new, rapidly-emerging layer of financial products in the crypto-world that the traditional ‘fiat’ financial world has grown accustomed to. At the same time, they are supporting the development of a new class of professional crypto managers. Very exciting!”.',
    //'feedbackProject#7': 'Denis Eskenazi',
    //'feedbackProject#8': 'Product Marketing Director',
    //'feedbackProject#9': 'Denis Eskenazi, CINDX Product Marketing Director explains why he has joined CINDX',
    'feedbackProject#10': 'Philip Staehelin',
    'feedbackProject#11': 'CINDX Adviser',
    'feedbackProject#12': 'Managing Partner of Roland Berger',
    'feedbackProject#13': 'video',
    'feedbackProject#14': '“CINDX is a brilliant idea that addresses all of the pain points of cryptocurrency and token investing.”',
    //'feedbackProject#15': 'Andrzej Cichowlaz',
    //'feedbackProject#16': 'Marketing Specialist',
    //'feedbackProject#17': 'Andrzej Cichowlaz, CINDX Marketing Manager tells why he believes in the project`s success',
    'feedbackProject#18': 'Austin Kimm',
    'feedbackProject#19': 'CINDX Adviser',
    'feedbackProject#20': 'International Financial Services CEO',
    'feedbackProject#21': 'video',
    'feedbackProject#22': '“CINDX makes “trader-investor” relationships personal again.”',
    'feedbackProject#23': 'Amarpreet Singh',
    'feedbackProject#24': 'CINDX Adviser',
    'feedbackProject#25': 'Senior Adviser of Global Blockchain Foundation',
    'feedbackProject#26': 'video',
    'feedbackProject#27': '“CINDX not only offers sophisticated tools for all the investors, traders, but also offers a platform, where we can just copy trade and we can piggyback on the experience of very experienced traders.”',
    'feedbackProject#28': 'Keith Teare',
    'feedbackProject#29': 'CINDX Adviser',
    'feedbackProject#30': 'Founder and Executive Chairman at Accelerated Digital Ventures',
    'feedbackProject#31': 'video',
    'feedbackProject#32':'“By enabling a new trader to track the trades of experienced traders, CINDX removes the fear that inexperience will make failure inevitable.”',
    //'feedbackProject#33': 'Mansur Rasulov',
    //'feedbackProject#34': 'Community Lead',
    //'feedbackProject#35': 'Mansur Rasulov, CINDX Community Lead explains why he decided to become a part of CINDX team',
    'feedbackProject#36': 'Sadie Hutton',
    'feedbackProject#37': 'CINDX Adviser',
    'feedbackProject#38': 'Founder and CSO at AuBit',
    'feedbackProject#39': 'video',
    'feedbackProject#40': '“The whole protocol is creating a community with real support and intelligence for anyone participating.”',
    'feedbackProject#41': 'Graham Doggart',
    'feedbackProject#42': 'CINDX Adviser',
    'feedbackProject#43': 'Founder and CPO at AuBit',
    'feedbackProject#44': 'video',
    'feedbackProject#45': '“Imagine that the investor could entrust his funds with somebody who is an expert in this market and allow them to trade, without surrendering control of their funds.”',
    //'feedbackProject#46': 'Alina Vardanian',
    //'feedbackProject#47': 'Content Manager',
    //'feedbackProject#48': 'Alina Vardanian, CINDX Content Manager talks about the team spirit and what she loves about her job',
    'feedbackProject#49': 'Bogdan Fiedur',
    'feedbackProject#50': 'CINDX Adviser',
    'feedbackProject#51': 'Co-Founder of bitJob and President of Blockchainexperts.io',
    'feedbackProject#52': 'video',
    'feedbackProject#53': '“Any innovative solutions directed at simplifying the work of investors, managers and vendors represent a potentially disruptive model for the cryptocurrency investments market.”',
    'feedbackProject#54': 'Jason King',
    'feedbackProject#55': 'CINDX Adviser',
    'feedbackProject#56': 'Chief Executive Officer at Telenor',
    'feedbackProject#57': 'video',
    'feedbackProject#58': '“CINDX truly can demystify and uncover the cryptocurrency trading world for the mass market investor. This offers a fighting chance for the ordinary small investor to go in and take part in trades that match their profile in a fairer and more transparent way.”',

    'mediaNews#1': 'Press',
    'mediaNews#2': 'News',
    // Media
    'mediaNews#1_1': 'CINDX Aims to Democratize the Modern Financial System',
    'mediaNews#1_2': 'Even as investors in the cryptocurrency space recognize that they are at the forefront of a new financial revolution, adoption by the general populace is yet to occur. While 3rd parties are slowly entering the space in the form of hedge funds and other investors, very few individuals seem to have taken the step to invest in cryptocurrencies on their own.',
    'mediaNews#1_3': 'CINDX Announces Plans For ICO Pre-Sale Amidst Release Of Revolutionary Crypto Trading Hub That Allows Users To Follow Expert Traders',
    'mediaNews#1_4': 'CINDX takes its business seriously with particular attention and cares to legal matters and has taken great pains to ensure that CINDX is as compliant as possible with global regulations.',
    'mediaNews#1_5': 'How CINDX is Democratizing the Modern Financial System',
    'mediaNews#1_6': 'CINDX is a new platform that is making crypto trading easier, more secure, and more accessible. With this platform, it’s possible to have your funds traded without high fees or minimum investments, with the added security and transparency of blockchain technology.',
    'mediaNews#1_7': 'CINDX is making crypto trading easier, more secure, and more accessible.',
    'mediaNews#1_8': 'Cryptocurrency and blockchain technology have been slated as powerful tools capable of fueling a global financial revolution. There are myriad opportunities to invest in the crypto space, and yet, very few individuals have undertaken the steps necessary to invest.',
    'mediaNews#1_9': 'CINDX Announces Plans Release Of Revolutionary Crypto Trading Hub',
    'mediaNews#1_10': 'The CINDX platform creates a complete ecosystem for cryptocurrency trading that is favorable to all the participants of the market and offers solutions to existing pain points.',
    'mediaNews#1_11': 'CINDX Announces Plans For ICO Pre-Sale',
    'mediaNews#1_12': 'CINDX made headlines this week with the announcement that they will be opening their ICO Pre-Sale in late August after investors interest in the ICO peaks to new levels.',
    'mediaNews#1_13': 'CINDX Will Be Opening ICO Pre-Sale in late August',
    'mediaNews#1_14': 'The CINDX Crypto trading platform has a myriad of features and benefits that haven’t been seen in other platforms to date.',
    'mediaNews#1_15': '5 Tips from CINDX Crypto Assets Management Hub: How to Avoid Being Cheated',
    'mediaNews#1_16': 'The team behind the CINDX crypto assets management hub has created a helpful set of guidelines on how not to become victims of unfair play, so you can learn what should be primarily taken into account when evaluating ICOs before investing in them.',
    'mediaNews#1_17': 'CINDX Crypto Asset Management Hub Adopts Bancor Protocol',
    'mediaNews#1_18': 'CINDX is delighted to announce that a number of new integrations with top industry players are underway, each of which will make the life of the users of the platform easier and richer. These new integrations include the decision to adopt the Bancor protocol to provide continuous liquidity for the CINDX platform.',
    'mediaNews#1_19': 'CINDX Crypto Asset Management Hub Adopts Bancor Protocol',
    'mediaNews#1_21': 'CINDX is delighted to announce that a number of new integrations with top industry players are underway, each of which will make the life of the users of the platform easier and richer. These new integrations include the decision to adopt the Bancor protocol to provide continuous liquidity for the CINDX platform.',
    'mediaNews#1_22': 'CINDX Beginning ICO for Crypto Asset Management and Social Network',
    'mediaNews#1_23': 'CINDX, a cryptocurrency project has now begun its security token ICO pre-sale to introduce a multifunctional platform that allows blockchain investors to choose an expert trader to follow based on trading stats recorded on the blockchain.',
    'mediaNews#1_24': 'Blockchain Investors Can Now Make It Big with the Help of CINDX',
    'mediaNews#1_25': 'Cryptocurrencies have certainly made their mark. The digital tokens have consistently shown that they have the potential to rise very high in value. The market is huge and investors are insatiable.',
    'mediaNews#1_26': 'Even Amateur Investors Can Make It Big with the Help of CINDX',
    'mediaNews#1_27': 'CINDX, the crypto asset management company, recently opened its ICO pre-sale and is introducing a multifunctional platform that allows anybody to invest in the crypto market while consistently earning returns. ',
    'mediaNews#1_28': 'CINDX Crypto Asset Manager For Traders Launches Security Token ICO',
    'mediaNews#1_29': 'CINDX, a digital currency startup, recently initiated the presale phase of its ICO token sale. Then proceeds from the ICO will be used to establish a multifunctional platform that enables crypto traders identify and follow their preferred expert investor based on trading statistics recorded on the blockchain.',
    'mediaNews#1_30': 'CINDX CEO Shares His Point of View on why Investors Should Treat Cryptocurrency and Blockchain Predictions with "Healthy Skepticism".',
    'mediaNews#1_31': 'Crypto isn\'t all it\'s cracked up to be. Citi analyst Josh Levin says to treat the many cryptocurrency and blockchain predictions with "healthy skepticism" and to remain "open minded".',
    'mediaNews#1_32': 'CINDX CEO Shares His Point of View on Rising Bitcoin Mining Hashrates',
    'mediaNews#1_33': 'Even though cryptocurrency prices have been significantly down throughout 2018 thus far, oddly, hashrates for the Bitcoin network—signifying mining activity—have risen meaningfully. Could this disparity provide some insight into the future of alt-currencies?',
    // News
    'mediaNews#2_1': 'CINDX meets Ian Balina',
    'mediaNews#2_2': 'CINDX pitch to the one of the TOP10 crypto gurus in the world in the Cryptobazar event.',
    'mediaNews#2_3': 'CINDX took part in Moscow ICO Summit 2018',
    'mediaNews#2_4': 'CINDX team introduced the project to the Russian crypto community during ICO Summit event.',
    'mediaNews#2_5': 'How CINDX is ensuring legal compliance',
    'mediaNews#2_6': 'Our company is registered in Estonia. Why? The answer is simple — the authorities of Estonia have created a strong and innovative legal infrastructure...',
    'mediaNews#2_7': 'Keith Teare Co-founder of Accelerated Digital Ventures and Founding Shareholder in TechCrunch',
    'mediaNews#2_8': 'We continue to introduce CINDX advisers, find out what they...',
    'mediaNews#2_9': 'Meet world\'s first financial crypto social network',
    'mediaNews#2_10': 'Every day our team takes one more step on the way to creation and start...',
    'mediaNews#2_11': 'Denis Eskenazi CINDX Product Marketing Director',
    'mediaNews#2_12': 'We continue to tell about the CINDX team and each team member’s professional experience. Please, read about Denis Eskenazi...',
    'mediaNews#2_13': 'CINDX crypto currency exchange license',
    'mediaNews#2_14': 'Our company in Estonia has received Crypto Currency Exchange License. This allows CINDX to provide fiat-to-crypto exchange services. This licence is issued...',
    'mediaNews#2_15': 'Austin Kimm Co-founder of Crypterium & CINDS Adviser',
    'mediaNews#2_16': 'We continue to introduce CINDX advisers, find out what they are proud...',
    'mediaNews#2_17': 'The strategic meeting of the international CINDX team',
    'mediaNews#2_18': 'Every day our team takes one more step on the way to creation and start of the CINDX platform. Our work...',
    'mediaNews#2_19': 'Artur Shamalov CINDX Investment Director',
    'mediaNews#2_20': 'We continue to introduce the CINDX team. Our next article is about Artur Shamalov, a skilled...',
    'mediaNews#2_21': 'The issuance of CINX tokens',
    'mediaNews#2_22': 'The issuance of CINX tokens will be carried out according to the license of The Estonian Financial Supervisory Authority (EFSA). What is a token...',
    'mediaNews#2_23': 'Amarpreet Singh Co-Founding Member of Malta Blockchain Association & CINDX adviser',
    'mediaNews#2_24': 'We at CINDX are incredibly proud to present our advisers, tell the world about their achievements and why they have decided to become a part of the CINDX...',
    'mediaNews#2_25': 'CINDX meets Vangoo Capital Partners Fund',
    'mediaNews#2_26': 'Today we have welcomed Kevin Shang from Vangoo Capital Partners and his colleagues in our office. We have discussed business partnership opportunities in order to attract investors from the Asian region.',
    'mediaNews#2_27': 'Mofassair Hossain CINDX Chief Marketing Officer.',
    'mediaNews#2_28': 'We continue to introduce the CINDX team, explore each team member’s point of view, find out what our team members are particularly excited about...',
    'mediaNews#2_29': 'How CINDX ensures safety for its users - KYC',
    'mediaNews#2_30': 'Every crypto enthusiast knows that a KYC procedure is necessary in order to protect investors against fraudulent actions of the third...',
    'mediaNews#2_31': 'Philip Staehelin 25 Years of Consulting and Entrepreneurial Experience',
    'mediaNews#2_32': 'We continue our regular series of articles dedicated to introducing CINDX advisers. The next item is about Philip Staehelin.',
    'mediaNews#2_33': 'CINDX meets 9coin Digital Asses Exchange',
    'mediaNews#2_34': 'The CINDX team continues active work on building relationships with various international companies. One of the most important directions of business...',
    'mediaNews#2_35': 'Jason King',
    'mediaNews#2_36': 'The core of any great project is always people who make the unique idea come true. We would like to tell about...',
    'mediaNews#2_37': 'CINDX Presents the project during RICLUB private investors meeting',
    'mediaNews#2_38': 'CINDX has successfully passed the due diligence procedure requested by RICLub and was one of the few projects...',
    'mediaNews#2_39': 'Sofja Pevzner CINDX Personal Data Officer',
    'mediaNews#2_40': 'We continue to introduce our team and tell about what inspires our colleagues to work with CINDX. Please read about...',
    'mediaNews#2_41': 'Tyler Sanford 8+ years digital marketing in Yelp, Zenefits',
    'mediaNews#2_42': 'We continue to introduce CINDX advisers, find out what they are proud of and why they have decided to become a part of the CINDX team. In this...',
    'mediaNews#2_43': 'CINDX - Key points to observe',
    'mediaNews#2_44': 'The CINDX project is a valuable business venture with the features that are critically relevant for its participants. In this article we talk about Strengths...',
    'mediaNews#2_45': 'Airat Shayhulov CINDX Head of Quantitative Research.',
    'mediaNews#2_46': 'In “TEAM” series of the articles, we tell about people who every day participates in the creation...',
    'mediaNews#2_47': 'CINDX license of the financial consultant',
    'mediaNews#2_48': 'CINDX has obtained a termless operating licence (the FFA 000254 license) that gives the right to operate as a financial institution...',
    'mediaNews#2_49': 'Graham Doggart 20 Years of Enterprise Development and Fintech Marketing Compliance',
    'mediaNews#2_50': 'We continue our regular series of articles dedicated to introducing CINDX advisers. The next item...',
    'mediaNews#2_51': 'CINDX continues the roadshow in China!',
    'mediaNews#2_52': 'The CINDX team is glad to announce participation as a Golden Sponsor of Borderless Blockchain Technology Summit 2018, which...',
    'mediaNews#2_53': 'Ibraghim Haniev CINDX Tech Lead',
    'mediaNews#2_54': 'Get to know more about the CINDX team! The core of any great project is always people who make the unique ideas come true...',
    'mediaNews#2_55': 'Sadie Hutton 25 Years in Senior Management and Management Consulting',
    'mediaNews#2_56': 'Like any global project, we understand the importance of professional help and advice. We continue to tell the stories of...',
    'mediaNews#2_57': 'CINDX.talk - a service that will help people become wiser in taking financial decisions',
    'mediaNews#2_58': 'The current digital-world related to the crypto industry is getting crowded with mind-charming digital solutions...',
    'mediaNews#2_59': 'Val Jerdes CINDX Product Director',
    'mediaNews#2_60': 'We continue to introduce the CINDX team, explore each team member’s point of view, find out what our team members are particularly...',
    'mediaNews#2_61': 'Bogdan Fiedur Blockchain and cryptocurrency expert, investor, entrepreneur',
    'mediaNews#2_62': 'We continue to introduce CINDX advisers, find out what they are proud of and why they have decided to become...',
    'mediaNews#2_63': 'Welcome to CINX Token Distribution – it’s not an ICO; it’s an STO',
    'mediaNews#2_64': 'In this article, we want to make it clear why CINDX chose to hold a Security Token Offering (STO) instead of an Initial...',
    'mediaNews#2_65': 'CINDX celebrates the “AA” Rating from Top ICO List Audit!',
    'mediaNews#2_66': 'The results of CINDX’s sincerity to make meaningful changes in people’s ambitions via a unique business model just got appreciated...',
    'mediaNews#2_67': 'CINDX Weekly digest (30.07.18 - 05.08.18)',
    'mediaNews#2_68': 'The CINDX team is very excited to share the results of our work for the last week 30.07.18 - 05.08.18!',
    'mediaNews#2_69': 'Virtual Currency Wallet Services',
    'mediaNews#2_70': 'CINDX believes that being fluent in legal issues and having a clean, confident and clear legal status is of utmost importance. At the moment, CINDX is going...',
    'mediaNews#2_71': 'How CINDX Influences Crypto Trading',
    'mediaNews#2_72': 'How to master the crypto market movements and turn them in one’s favor? This is where CINDX’s effective business model comes into play. Learn more about CINDX on our Medium!',
    'mediaNews#2_73': 'CINDX Weekly digest (06.08.18 - 12.08.18)',
    'mediaNews#2_74': 'CINDX continues its regular column - Weekly Digest. We are excited to share the recent achievements of our project! Enjoy your time reading!',
    'mediaNews#2_75': 'CINDX gets 5.0 rating on TrackICO!',
    'mediaNews#2_76': 'CINDX continues getting approval from the largest and the most credible rating services. The week has hardly passed after...',
    'mediaNews#2_77': 'David Gutierrez Investment Relations Officer',
    'mediaNews#2_78': 'CINDX is very serious about taking people on crew and proud to introduce them. This article is dedicated to David Gutierrez, CINDX Investment Relations Officer',
    'mediaNews#2_79': 'ICObench gives 4.8 to CINDX',
    'mediaNews#2_80': 'The CINDX project has already gained the highest mark of  ‘AA’ from Top ICO List and got a 5.0 rating from TrackICO. Now, we are proud to share...',
    'mediaNews#2_81': 'Dip your Toe in Crypto',
    'mediaNews#2_82': 'It is never late to change your mind. It’s no surprise that Wall Street bankers change their minds in favour of crypto. Enjoy reading CINDX review on the current market situation!',
    'mediaNews#2_83': 'Yuriy Avdeev, CEO of the CINDX project',
    'mediaNews#2_84': 'Enjoy reading what inspired Yuriy Avdeev to create a project that will help all participants of the crypto market to be profitably engaged - CINDX.',
    'mediaNews#2_85': 'Weekly digest (13.08.18 - 19.08.18)',
    'mediaNews#2_86': 'CINDX continues its regular Weekly Digest column. We are excited to share our project’s progress and the most recent achievements! We are glad to remind you that CINDX security token Pre-sale starts this Sunday. Don\'t miss!',
    'mediaNews#2_87': 'Dip your Toe in Crypto',
    'mediaNews#2_88': "It is never late to change your mind. It’s no surprise that Wall Street bankers change their minds in favour of crypto. Enjoy reading CINDX review on the current market situation!",
    'mediaNews#2_89': 'Yuriy Avdeev, CEO of the CINDX project',
    'mediaNews#2_90': 'Enjoy reading what inspired Yuriy Avdeev to create a project that will help all participants of the crypto market to be profitably engaged - CINDX.',
    'mediaNews#2_91': 'Weekly digest (13.08.18 - 19.08.18)',
    'mediaNews#2_92': 'CINDX continues its regular Weekly Digest column. We are excited to share our project’s progress and the most recent achievements! We are glad to remind you that CINDX security token Pre-sale starts this Sunday. Don"t miss',
    'mediaNews#2_93': 'Upcoming',
    'mediaNews#2_94': 'Ended',
    'mediaNews#2_95': 'Roadshow',
 
    'socialMedia#1': 'For more information, follow us on social media:',

    'teamAdvisersPartners#1': 'Core Team Members',
    'teamAdvisersPartners#2': 'CINDX Advisers',
    'teamAdvisersPartners#3': 'Our Partners',
    // teamInfo
    'teamAdvisersPartners#4': 'Yuriy Avdeev',
    'teamAdvisersPartners#5': 'Chief Executive Officer',
    'teamAdvisersPartners#6': '13 years in entrepreneurship',
    'teamAdvisersPartners#7': '9 ventures',
    'teamAdvisersPartners#8': '$2m for crypto management',
    'teamAdvisersPartners#9': 'Val Jerdes',
    'teamAdvisersPartners#10': 'Product Director',
    'teamAdvisersPartners#11': '20 years in entrepreneurship',
    'teamAdvisersPartners#12': '3 successful exits',
    'teamAdvisersPartners#13': '4x returns for portfolios',
    'teamAdvisersPartners#14': 'Mofassair Hossain',
    'teamAdvisersPartners#15': 'Chief Marketing Officer',
    'teamAdvisersPartners#16': 'Investor and Adviser',
    'teamAdvisersPartners#17': '',
    'teamAdvisersPartners#18': 'GBAP Member',
    'teamAdvisersPartners#19': 'Artur Shamalov',
    'teamAdvisersPartners#20': 'Investment Director',
    'teamAdvisersPartners#21': '15 years in entrepreneurship',
    'teamAdvisersPartners#22': '5000 clients of mixcart.ru',
    'teamAdvisersPartners#23': 'mail.ru invested in dc-daily.ru',
    'teamAdvisersPartners#24': 'Denis Eskenazi',
    'teamAdvisersPartners#25': 'Product Marketing Director',
    'teamAdvisersPartners#26': '7 years in entrepreneurship',
    'teamAdvisersPartners#27': '$2m attracted for clients',
    'teamAdvisersPartners#28': '10 years in branding',
    'teamAdvisersPartners#29': 'Ibraghim Haniev',
    'teamAdvisersPartners#30': 'CTO',
    'teamAdvisersPartners#31': '6 years in entrepreneurship',
    'teamAdvisersPartners#32': 'Full Stack developer',
    'teamAdvisersPartners#33': 'Information Security and Blockchain focus',
    'teamAdvisersPartners#34': 'Airat Shaikhulov',
    'teamAdvisersPartners#35': 'Head of Quantitative Research',
    'teamAdvisersPartners#36': 'Dr of science at MSU',
    'teamAdvisersPartners#37': 'Aton-line navigator developer',
    'teamAdvisersPartners#38': 'Head of algorithmic trading',
    'teamAdvisersPartners#39': 'Sofja Pevzner',
    'teamAdvisersPartners#40': 'Personal Data Officer',
    'teamAdvisersPartners#41': '5 years in entrepreneurship',
    'teamAdvisersPartners#42': 'Permisson of estonian AML office',
    'teamAdvisersPartners#43': '8 years of legal work expirience',
    'teamAdvisersPartners#44': 'Dave Gutierrez',
    'teamAdvisersPartners#45': 'Investment Relations Officer',
    'teamAdvisersPartners#46': '5 years in aircraft leasing',
    'teamAdvisersPartners#47': '3 years in investment promotion',
    'teamAdvisersPartners#48': '2 years in russian deals abroad',
    'teamAdvisersPartners#49': 'Jelena Karzetskaja',
    'teamAdvisersPartners#50': 'Compliance Adviser',
    'teamAdvisersPartners#51': 'Holds several degrees in law',
    'teamAdvisersPartners#52': 'Judicial practice since 1997',
    'teamAdvisersPartners#53': 'Legal spheres: international law',
    'teamAdvisersPartners#54': 'Wang Dong',
    'teamAdvisersPartners#55': 'Specialist in Chinese market',
    'teamAdvisersPartners#56': '3 years in PHP/JavaScript',
    'teamAdvisersPartners#57': '5 years in SEO/SMM/media buyer',
    'teamAdvisersPartners#58': '2 years in token sale in China',
    'teamAdvisersPartners#59_1': 'Sergey Potekhin',
    'teamAdvisersPartners#60_1': 'Developer',
    'teamAdvisersPartners#61_1': '6 years in development',
    'teamAdvisersPartners#62_1': '3 years in fintech & blockchain development',
    'teamAdvisersPartners#63_1': 'Data science experience',
    'teamAdvisersPartners#64_1': 'Georg Brich',
    'teamAdvisersPartners#65_1': 'CAO',
    'teamAdvisersPartners#66_1': '7 years in trading',
    'teamAdvisersPartners#67_1': 'Experience in FINTECH project architecture development',
    'teamAdvisersPartners#68_1': '5 years is Asset Management',
    'teamAdvisersPartners#69_1': 'Yuri Proshin',
    'teamAdvisersPartners#70_1': 'Head of Community',
    'teamAdvisersPartners#71_1': 'Master \'s Degree in International Business',
    'teamAdvisersPartners#72_1': '4 years in FinTech and blockchain startups',
    'teamAdvisersPartners#73_1': 'Focus on community management, PR and communications',
    'teamAdvisersPartners#74_1': 'Nick Begi',
    'teamAdvisersPartners#75_1': 'Developer',
    'teamAdvisersPartners#76_1': 'Experienced Full-Stack Developer',
    'teamAdvisersPartners#77_1': 'Big data processing',
    'teamAdvisersPartners#78_1': 'Аnalysis and visualization',
    'teamAdvisersPartners#79_1': 'Alexander Bogdanov',
    'teamAdvisersPartners#80_1': 'Member of the board CINDX Investkapital AS',
    'teamAdvisersPartners#81_1': 'Finance and investment consulting specialist with over 12 years of experience',
    'teamAdvisersPartners#82_1': 'Marketing strategy development',
    'teamAdvisersPartners#83_1': '',
  
    // advisers
    'teamAdvisersPartners#59': 'Keith Teare',
    'teamAdvisersPartners#60': 'Founder and Executive Chairman at Accelerated Digital Ventures',
    'teamAdvisersPartners#61': 'Adviser to ContextGrid, INFARM, etc.',
    'teamAdvisersPartners#62': 'Founding shareholder of TechCrunch',
    'teamAdvisersPartners#63': 'I am Executive Chairman and Chair of the Investment Committee at Accelerated Digital Ventures - a UK Venture Company. I was born and raised in the UK.',
    'teamAdvisersPartners#64': 'Previously, I was founder and a partner at Archimedes Labs in Palo Alto, California. I love working with founders, teams, designers and engineers to create products, tell stories, and turn them into reality. I have founded or co-founded many companies myself since the early 1980s. Some were successfully sold, others IPO\'d.',
    'teamAdvisersPartners#65': 'Two of my companies were Unicorns - valued by others at more than $1 billion. I have always focused on the point at which change is happening. In the 1980\'s networking and databases. In the 1994-1998 period, Internet Access. From 1998-2010 - Web Services and Content, Mobile Consumer Applications and since 2017 Blockchain.',
    'teamAdvisersPartners#66': 'Career and projects: Tech Crunch, Accelerated Digital Ventures, cScape, EasyNet, Cyberia, RealNames, Venture Network, Archimedes Labs and CINDX.',
    'teamAdvisersPartners#67': 'Austin Kimm',
    'teamAdvisersPartners#68': 'International Financial Services CEO',
    'teamAdvisersPartners#69': '30+ years of financial service experience',
    'teamAdvisersPartners#70': 'Created companies with a worth of $500m',
    'teamAdvisersPartners#71': 'Experienced results proven financial services CEO with a particular focus on building insurance businesses in developing markets where I have successfully built companies with a current valuation in excess $500m, most recently in Russia (10 years), as well as other non insurance senior management roles, including CEO of Russia\'s largest internet sales employment platform, Workle, and a wide range of fintech start up C level positions including Crypterium, the world\'s first digital cryptobank.',
    'teamAdvisersPartners#72': 'Most recently in Russia (10 years), as well as other non insurance senior management roles, including CEO of Russia\'s largest internet sales employment platform, Workle, and a wide range of fintech start up C level positions including Crypterium, the world\'s first digital cryptobank.',
    'teamAdvisersPartners#73': 'Career and projects: Aviva, Commercial Union UK, Reinaissance Insurance, Kimm Health & Leisure Ltd, Workle, CryptoIndex, CINDX',
    'teamAdvisersPartners#74': 'Philip Staehelin',
    'teamAdvisersPartners#75': '25 years of consulting and entrepreneurial experience',
    'teamAdvisersPartners#76': '$500m alternative payments business under leadership',
    'teamAdvisersPartners#77': '200+ startups from 20 countries mentored',
    'teamAdvisersPartners#78': 'Philip has more than 25 years of experience in the corporate and consulting worlds. He served as the CEO of a $500m payments company, he was a Managing Partner in Roland Berger (a global, top-tier, management consulting firm), and he’s also held executive roles in T-Mobile, Accenture, A.T. Kearney and CA-IB Investment Bank (now UniCredit). He’s also a co-owner of StartupYard (CEE\'s oldest tech startup accelerator), he’s an active angel investor, and he\'s launched a number of startups, including his latest venture - DOT Glasses – that aims to provide eyeglasses to the >1 billion people in the world that need glasses but can’t afford them (or have no access to them). Philip is also a Board member of Transparency International CZ, as well as a Board member of Gjirafa – the fastest growing company in the Balkans, and he mentors startups throughout CEE, the Baltics and the Balkans, and is an Advisor to numerous startups as well as ICOs.',
    'teamAdvisersPartners#79': 'Philip earned his MBA from INSEAD (Fontainebleau, France) and Bachelors degrees in Economics and International Affairs from the University of Colorado (Boulder, Colorado, USA).',
    'teamAdvisersPartners#80': '',
    'teamAdvisersPartners#81': '',
    'teamAdvisersPartners#82': 'Amarpreet Singh',
    'teamAdvisersPartners#83': 'Senior Adviser of Global Blockchain Foundation',
    'teamAdvisersPartners#84': 'Experience in Microsoft, The World Bank and Airbus',
    'teamAdvisersPartners#85': 'Advisery Board Member of Blockchain projects',
    'teamAdvisersPartners#86': 'Technology/Digital enthusiast and a seasoned professional with years of experience in Tech industry – operations, consulting and innovation.',
    'teamAdvisersPartners#87': 'He is a certified Cloud (Microsoft and AWS) specialist. Known for versatility and flexibility. Excellent analytic, strategic, leadership skills and a team player at its best.',
    'teamAdvisersPartners#88': 'Specialties: Technology Consulting, Operations, Cloud Computing, Fintech, Innovation, ERP, Machine Learning, Account Management, Partner Development, Business Development and Strategy',
    'teamAdvisersPartners#89': 'Amarpreet Singh has a very diversified work and educational background. He holds three Masters degrees and has lived/worked/studied in India, Singapore, France, China, South Africa, Korea, Canada.',
    'teamAdvisersPartners#90': 'Career and projects: Microsoft, The World Bank, Airbus, TokenAsia Platform, CINDX',
    'teamAdvisersPartners#91': 'Tyler Sanford',
    'teamAdvisersPartners#92': '8 Years Digital Marketing in Yelp and Zenefits',
    'teamAdvisersPartners#93': '12 projects over $225M raised',
    'teamAdvisersPartners#94': 'Early adopter of blockchain',
    'teamAdvisersPartners#95': 'Growing up as an Athlete has given me a skill set that I\'ve been able to apply to my careers and personal life.',
    'teamAdvisersPartners#96': 'I have been very fortunate to see the results of going that extra mile, while learning to deal with the highs and lows that go along with all professional careers.',
    'teamAdvisersPartners#97': 'I`m an extremely self-motivated individual, always looking to continue my growth of knowledge and talents in all aspects of business.', 
    'teamAdvisersPartners#98': 'Always up for a challenge, my motivation comes from overcoming obstacles to reach my goals',
    'teamAdvisersPartners#99': 'Career and projects: IPG Holdings, DataBlockChain, TraXion by Puma Technologies, ELYSIAN, Etherinc, FTEC Foundation,  CINDX',
    'teamAdvisersPartners#100': 'William Zh',
    'teamAdvisersPartners#101': 'Founder of The Blockchainer & J&C Capital',
    'teamAdvisersPartners#102': 'Guest Professor of Zhejiang University',
    'teamAdvisersPartners#103': 'Former Chairman of OTC Database (Shanghai)',
    'teamAdvisersPartners#104': 'Mr. Zhu is finance doctoral student, holds Bachelor of law & Master of Marketing. Now he is Executive Secretary General of Emerging Industries Institute (Shanghai), Guest Professor of Zhejiang University, and Expert Lecturer of Shanghai Municipal Tourism Administration.',
    'teamAdvisersPartners#105': 'Previously, he was Chairman of OTC Database (Shanghai) Information Technology Co., Ltd.), General Manager of China UnionPay Smart Big Data and Internet Finance Research Institute, and CEO of CDMC.',
    'teamAdvisersPartners#106': 'The Blockchainer provides to its customers comprehensive services such as: summit organization, global business tours, meetup host and customization, media & social media casting, VIP resource connection, localisation service.',
    'teamAdvisersPartners#107': 'These six business segments provide core chain services around the head blockchain enterprise, building blockchain enterprises to expand the service ecosystem.',
    'teamAdvisersPartners#108': 'These six business segments provide core chain services around the head blockchain enterprise, building blockchain enterprises to expand the service ecosystem.',
    'teamAdvisersPartners#109': 'The Blockchainer accelerates the network development of the business sector in the three dimensions of talent, organization and information.',
    'teamAdvisersPartners#110': 'The Blockchainer has more than 10 years of experience in the science and technology finance team, is the earliest blockchain proponent and practitioner in China; is the earliest blockchain ecosystem core player in China; focusing on the blockchain field, providing in-depth and vertical global professional services; one of the industry’s most comprehensive network in blockchain industry upstream and downstream.',
    //'teamAdvisersPartners#111': 'Technology/Digital enthusiast and a seasoned professional with years of experience in Tech industry – operations, consulting and innovation.',
    // 'teamAdvisersPartners#112': 'He is a certified Cloud (Microsoft and AWS) specialist. Known for versatility and flexibility. Excellent analytic, strategic, leadership skills and a team player at its best.',
    // 'teamAdvisersPartners#113': 'Specialties: Technology Consulting, Operations, Cloud Computing, Fintech, Innovation, ERP, Machine Learning, Account Management, Partner Development, Business Development and Strategy',
    // 'teamAdvisersPartners#114': 'Amarpreet Singh has a very diversified work and educational background. He holds three Masters degrees and has lived/worked/studied in India, Singapore, France, China, South Africa, Korea, Canada.',
    // 'teamAdvisersPartners#115': 'Career and projects: Microsoft, The World Bank, Airbus, TokenAsia Platform, GOeurika, CINDX',
    // 'teamAdvisersPartners#116': 'Tyler Sanford',
    // 'teamAdvisersPartners#117': '8 Years Digital Marketing in Yelp and Zenefits',
    // 'teamAdvisersPartners#118': '12 ICO’s over $225M raised',
    // 'teamAdvisersPartners#119': 'Early adopter of blockchain',
    // 'teamAdvisersPartners#120': 'Growing up as an Athlete has given me a skill set that I\'ve been able to apply to my careers and personal life.',
    // 'teamAdvisersPartners#121': 'I have been very fortunate to see the results of going that extra mile, while learning to deal with the highs and lows that go along with all professional careers.',
    // 'teamAdvisersPartners#122': 'I\'m an extremely self-motivated individual, always looking to continue my growth of knowledge and talents in all aspects of business.',
    // 'teamAdvisersPartners#123': 'Always up for a challenge, my motivation comes from overcoming obstacles to reach my goals',
    // 'teamAdvisersPartners#124': 'Career and projects: IPG Holdings, DataBlockChain, TraXion by Puma Technologies, ELYSIAN, Etherinc, FTEC Foundation,  CINDX',
    'teamAdvisersPartners#125': 'Bogdan Fiedur',
    'teamAdvisersPartners#126': 'Blockchain and cryptocurrency expert, investor, entrepreneur',
    'teamAdvisersPartners#127': 'Co-Founder of bitJob and President of Blockchainexperts.io',
    'teamAdvisersPartners#128': 'Blockchain Expert',
    'teamAdvisersPartners#129': 'Bogdan Fiedur is a smart contract developer, crypto-investor, entrepreneur and President of http://blockchainexperts.io . He has over 20 years of IT experience in building e-commerce websites and has been involved in blockchain development during last 3 years.',
    'teamAdvisersPartners#130': 'He is also an adviser working with 20 successful projects to date and deeply involved in offering presentation and workshops on blockchain and smart contract programming in his local crypto community of Winnipeg.',
    'teamAdvisersPartners#131': 'Bogdan now focuses on the Blockchain industry and offers advice, consulting services and connections to Blockchain experts. Most recently Bogdan has co-founded ‘Bitjob’, a freelancer blockchain platform for students and participated in several ICO projects as a team member. e.g. PayPie.com.',
    'teamAdvisersPartners#132': 'Career and projects: US Blockchain Association, Tuurnt, Trends Project, BiNeuro, Zichain, Graphen Tech, CyClean, CINDX',
    'teamAdvisersPartners#133': 'Graham Doggart',
    'teamAdvisersPartners#134': '20 years of enterprise development, fintech marketing compliance',
    'teamAdvisersPartners#135': 'Executed campaigns with a worth of $100m',
    'teamAdvisersPartners#136': 'Adviser for 10+ blockchain projects this year',
    'teamAdvisersPartners#137': 'I am chairman of a Stealth Cryptocurrency Project and co-founder and lead strategist at Dynamic Abundance, a Blockchain and Fintech Advisery since 2012.',
    'teamAdvisersPartners#138': 'I specialize in the structuring and packaging of token offerings and have worked with numerous blockchain, cryptocurrency.',
    'teamAdvisersPartners#139': 'For more than 20 years I have provided tech management consultation, legal and financial compliance advice, growth strategies, investor relations, as well as design and authorship of materials.',
    'teamAdvisersPartners#140': 'Career and projects: Stealth Cryptocurrency Project, Dynamic Abundance, ELYSIAN, Fox Trading FX, Profede, Worltdopoly, CINDX',
    'teamAdvisersPartners#141': 'Sadie Hutton',
    'teamAdvisersPartners#142': '25 years in senior management and management consulting',
    'teamAdvisersPartners#143': 'Multimillion-dollar fundraiser with campaigns with a worth of £75m',
    'teamAdvisersPartners#144': 'Adviser of 8 projects',
    'teamAdvisersPartners#145': 'I am the founder of Dynamic Abundance, a blockchain advisery.',
    'teamAdvisersPartners#146': 'Preferring a “hands-on” approach, I am a specialist in project management, all key aspects of the business process. I am skilled in business management and growth, which enable me to see a project from inception through to delivery. I am a problem solver by nature, and I take any project I am involved with personally. I like to win!',
    'teamAdvisersPartners#147': 'With over 20 years of management experience I built up a powerful network, I actively advise on finance, tech, sales, marketing and quality assurance for high-level blockchain projects.',
    'teamAdvisersPartners#148': 'Career and projects: Dynamic Abundance, ELYSIAN, Worldopoly, Fox Trading FX, Profede, Market Digital, CINDX',
    'teamAdvisersPartners#149': 'Jason King',
    'teamAdvisersPartners#150': '20 years of excecutive experience',
    'teamAdvisersPartners#151': 'Led businesses operations in 30 countries',
    'teamAdvisersPartners#152': 'Responsible over $500m P&L',
    'teamAdvisersPartners#153': 'For almost 20 years, Jason has been a senior technology, media and telecoms executive involved in structuring and brokering commercial deals, negotiating wins, building markets and positioning organizations for success.',
    'teamAdvisersPartners#154': 'The core theme in his career has been to successfully drive business restructuring, transformation and turnaround programs - by leading the right people and teams towards the most profitable goals.',
    'teamAdvisersPartners#155': 'In addition to his interests in private equity and startup companies, Jason has worked in various groups including Deutsche Telekom, Telenor and UPC Liberty Global and Veon.',
    'teamAdvisersPartners#156': 'Jason has either worked or has led business directly in over 30 countries and has previously held commercial P&L responsibilities in the $500M-$1B range.',
    'teamAdvisersPartners#157': 'Motti Peer',
    'teamAdvisersPartners#158': 'Co-founder of Blonde 2.0, award-winning global PR agency',
    'teamAdvisersPartners#159': 'Founder of various companies in the security and finance sectors',
    'teamAdvisersPartners#160': 'Motti is one of the leading forces in PR for Blockchain and Crypto, startups, VCs.',
    'teamAdvisersPartners#161': 'Motti founded various companies in the security and finance sectors. He has many years of experience as a money and hedge fund manager and holds an MBA in Finance and Marketing from Manchester University and a BA in Business Management from Fairleigh Dickinson University',
    'teamAdvisersPartners#162': 'He is a Co-CEO of Blonde 2.0, an award-winning global PR agency also named as one the startup nation’s “Movers and Shakers” on Forbes. As Co-CEO of Blonde 2.0, he is responsible for handling the Public Relations aspects, along with strategy and crisis management.',
    'teamAdvisersPartners#163': 'With a team of 35 professionals, Blonde 2.0 is one of the leading forces in PR for Blockchain and Crypto, startups, VCs.',
    'teamAdvisersPartners#164': 'Leonard Grayver',
    'teamAdvisersPartners#165': 'Сhair of Greenberg Whitcombe Takeuchi Gibson & Grayver LLP',
    'teamAdvisersPartners#166': 'Represented and advised a number of successful project’s',
    'teamAdvisersPartners#167': 'Has substantial experience in venture capital fund formation',
    'teamAdvisersPartners#168': 'Leonard Grayver is an experienced corporate attorney based in Silicon Valley, California. He is the chair of the corporate law group at Greenberg, LLP, a boutique law firm with offices in Southern and Northern California.',
    'teamAdvisersPartners#169': 'He has represented venture capital funds, entrepreneurs, and startup companies in diverse industries, including medical devices, software, healthcare, electronics, IT, and alternative energy, for almost twenty years. In the last several years, Leonard also developed a robust practice focusing on cryptocurrency law and has served as counsel in a number of token sales offerings.',
    'teamAdvisersPartners#170': 'Leonard earned a B.A. from University of California, Los Angeles, and a Juris Doctorate from UCLA School of Law.',
    'teamAdvisersPartners#171': 'Kosol Saisanit',
    'teamAdvisersPartners#172': 'Founder of Power C Technology Co., Ltd, C Power Solutions Co., Ltd and CoachHub.',
    'teamAdvisersPartners#173': 'Executive management expert and marketing specialist',
    'teamAdvisersPartners#174': 'Brand Manager of one of the biggest FMCG companies in the South-East Asia region',
    'teamAdvisersPartners#175': 'Mr. Saisanit is a graduate of the Thailand Assumption University and has an MBA in Executive management and BBA major in Marketing.',
    'teamAdvisersPartners#176': 'Previously, he held the position of Brand Manager of Monde Nissin, one of the biggest players of the FMCG market in the South-East Asia region. Also, he had an extensive career in a real estate service company, CB Richard Ellis.',
    'teamAdvisersPartners#177': 'His company, Power C Technology Co., Ltd, is an Energy Service Company (ESCO) with specialization in reducing Energy Losses and improving Power Quality through Innovative Technology. C Power Solutions Co., Ltd is an online-marketing branch, assisting a client in Online Branding, Strategic Plan, Marketing Communication Plan and Advisor in Marketing and Sales Plan.',
    'teamAdvisersPartners#178': 'Teo Lincoln',
    'teamAdvisersPartners#179': 'Founder and CEO of Intel Wise Group',
    'teamAdvisersPartners#180': 'Former Managing Director of DP Information Group',
    'teamAdvisersPartners#181': 'Advisor to Brunei’s central bank Authority Monetary Brunei Darussalam (AMBD)',
    'teamAdvisersPartners#182': 'Lincoln is Founder and CEO of Intel Wise Group.',
    'teamAdvisersPartners#183': 'Prior to this, he was Chief Operating Officer and Managing Director of DP Information Group – a subsidiary of Experian plc and managed multiple data repositories in South-East Asia (including Singapore, Malaysia, Indonesia, Vietnam and Brunei).',
    'teamAdvisersPartners#184': 'Lincoln’s experience includes risk management and IT infrastructures with United Overseas Bank Group and Standard Chartered Bank. As a statistician, he introduced credit scoring to multiple banks in Asia-Pacific and also found time to serve on project teams of the World Bank Group and the Asian Development Bank. Intel',
    'teamAdvisersPartners#185': 'Under the umbrella of the Chongqing Connectivity Initiative, a Singapore and China Governments’ bilateral project, Intel Wise has curated data on more than 100+ million companies in China and helped companies globally with their compliance and due diligence needs. More recently, the company has embarked on blockchain technology and developed platforms for alternative financial services and the trading of digital assets. In addition, Lincoln is also an Advisor to Brunei’s central bank Autoriti Monetary Brunei Darussalam (AMBD).',
    'teamAdvisersPartners#186': 'In addition, Lincoln is also an Advisor to Brunei’s central bank Autoriti Monetary Brunei Darussalam (AMBD).',
    'teamAdvisersPartners#187': 'Reinhard Berger',
    'teamAdvisersPartners#188': 'Reinhard Berger is the CEO of Pecunio Blockchain Technologies, a Dubai VC Firm focused on innovative DLT technologies.',
    'teamAdvisersPartners#189': 'Consulted Credit Suisse, UBS and Invesco as a business architect at Accenture. Was a principal for Capgemini',
    'teamAdvisersPartners#190': 'Managed $250M in alternative assets via Invest Finance AG since 2003',
    'teamAdvisersPartners#191': 'He holds an MSc in Computer Science, an M.A in law, as well as an MBA from Danube University. ',
    'teamAdvisersPartners#192': 'Prior to Pecunio, Reinhard’s career began as a business architect for global consulting firm Accenture, with clients such as Credit Suisse, UBS and Invesco.',
    'teamAdvisersPartners#193': 'He then served as a principal for French tech giant Capgemini. In 2003, Reinhard co-founded Alternative Invest Finance AG, an independent hedge fund operator, where he managed alternative assets in excess of $250M. ',
    'teamAdvisersPartners#194': 'An intuitive leader, natural mentor and true strategist at Pecuino’s helm.',
    'teamAdvisersPartners#195': '',
    'teamAdvisersPartners#196': '',
    'teamAdvisersPartners#197': '',
    
    //urgentnews
    'urgentnews#g1': 'View all posts',
    'urgentnews#g2': 'Read More',
    'urgentnews#1': 'Initial Circulation Supply of CINXO',            //title
    'urgentnews#2': '4 MIN READ',                                      // readtime
    'urgentnews#3': 'Important information about CINDX tokens!',      // mainThought
    'urgentnews#4': 'https://medium.com/cindx/the-initial-circulating-supply-of-cinxo-tokens-386ae92b57cd', // link
    'urgentnews#5': 'CINDX Token Benefits For Token Holders',
    'urgentnews#6': '4 MIN READ',
    'urgentnews#7': 'Get to know more about the CINDX tokens!',
    'urgentnews#8': 'https://medium.com/cindx/cindx-token-benefits-for-token-holders-d3228205117c',
    'urgentnews#9': 'CINDX FAQ',
    'urgentnews#10': '6 MIN READ',
    'urgentnews#11': 'Frequently Asked Questions About CINDX',
    'urgentnews#12': 'https://medium.com/cindx/frequently-asked-questions-about-cindx-692cae405d6',
    'urgentnews#13': 'CINDX launches on Bitforex',
    'urgentnews#14': '3 MIN READ',
    'urgentnews#15': 'CINDX Premium Token Offering on Bitforex',
    'urgentnews#16': 'https://event.bitforex.com/en/CINXO.html',
    'urgentnews#17': 'Official Social Media Channels for CINDX',
    'urgentnews#18': '2 MIN READ',
    'urgentnews#19': 'The given list contains all of our official channels and online media',
    'urgentnews#20': 'https://medium.com/cindx/official-social-media-channels-for-cindx-4c44759dffe8',

    //'joinTokenPreSale#1': 'Join Token Pre-Sale Now!',
    //'joinTokenPreSale#2': 'JOIN PRE-SALE',
    //'joinTokenPreSale#3': 'CINDX LIVE',
    'joinTokenPreSale#4': 'Head Office',
    'joinTokenPreSale#5': 'Estonia, Tallinn',
    'joinTokenPreSale#6': '10416, Harjumaa, Erika 14',
    'joinTokenPreSale#7': 'Business inquiries - hello@cindx.io',
    //'joinTokenPreSale#8': 'Legal Disclaimer',
    //'joinTokenPreSale#9': 'Development and Marketing',
    //'joinTokenPreSale#10': 'Russia, Moscow',
    //'joinTokenPreSale#11': '123112, Presnenskaya naberezhnaya, 8 / 1',
    //'joinTokenPreSale#12': 'Careers - ',
    'joinTokenPreSale#13': 'Follow us:',
    'joinTokenPreSale#14': 'User agreement',
    'joinTokenPreSale#15': 'Privacy policy',
    'joinTokenPreSale#16': 'Subscribe to the latest updates from Cindx',
    'joinTokenPreSale#17': 'Subscribe',
    'joinTokenPreSale#18': '',
    'joinTokenPreSale#19': '',
    'joinTokenPreSale#20': '',

    'mvpBlock#1': 'CINDX Live',
    'mvpBlock#2': 'We have launched our CINDX platform. Join us!',
    'mvpBlock#3': 'Sign Up',
    'mvpBlock#4': '',
    'mvpBlock#5': '',
    'mvpBlock#6': '',
    'mvpBlock#7': '',
    'mvpBlock#8': '',
    'mvpBlock#9': '',
    'mvpBlock#10': '',
    'mvpBlock#11': '',
    'mvpBlock#12': '',
    'mvpBlock#13': '',
    'mvpBlock#14': '',

    'common#g1': 'Email',

    'subscribeBlock#1': 'Be the first to get the latest news and updates from CINDX',

    'link#1_bitforex': 'https://event.bitforex.com/en/CINXO.html',
    'link#2_help': 'https://medium.com/cindx/how-to-buy-cinxo-tokens-7fc82a27ba47?postPublishedType=repub',
};

export default en;
