import en from '../../images/country-flag/en.jpg'
import zn from '../../images/country-flag/zn.jpg'
import ko from '../../images/country-flag/ko.jpg'
import tr from '../../images/country-flag/tr.jpg'
import fr from '../../images/country-flag/fr.jpg'
import ja from '../../images/country-flag/ja.jpg'
import pt from '../../images/country-flag/pt.jpg'
import vi from '../../images/country-flag/vi.jpg'
import ar from '../../images/country-flag/ar.jpg'
import de from '../../images/country-flag/de.jpg'
import es from '../../images/country-flag/es.jpg'

const flags = {
    'en': en,
    'zn': zn,
    'ko': ko,
    'tr': tr,
    'fr': fr,
    'ja': ja,
    'pt': pt,
    'vi': vi,
    'ar': ar,
    'de': de,
    'es': es
}

export default flags