// Китайский
const zn = {
    'mainBlock#1': '加密货币资产管理的一站式解决方案',
    'mainBlock#2': '让加密货币投资变得安全便捷',
    'mainBlock#3': '订阅 Cindx 的最新消息', // CINDX IS LIVE NOW!

//Текст в плашке
    'headertext#0': 'CINDX Pre-IEO 开始:',      //сейчас ( есть таймер)
    'headertext#1': 'CINDX Pre-IEO直播！', //25.06 14-59 мск:
    'headertext#2': '第二轮Pre-IEO开始::', // 25.06 16-00 мск: ( есть таймер)
    'headertext#3': 'CINDX Pre-IEO结束:',  // 26.06 15-00 мск ( есть таймер)
    'headertext#4': 'CINDX Pre-IEO 已经结束了.', // 27.06 15-00 мск(или раньше) первая строка  
    'headertext#4_1':'价值400 000美元的代币已经出售', // 27.06 15-00 мск(или раньше) вторая строка ( не показывать в моб)
    'headertext#5': 'Need Help?',
// Кнопка в плашке
    'buttonheader#1': '购买CINXO在', 
    'buttonheader#2': 'BitForex上',   // Название биржи с эконкой на кнопке

//    'icoInfo#1': '我们接受：',
//    'icoInfo#2': '硬上限：',
//    'icoInfo#3': '软上限里程碑：',
//    'icoInfo#4': '已经签署挂牌协议 ',

    'menu#1': '白皮书 ',
    'menu#2': '演示文稿',
    'menu#3': '单页报告',
    'menu#4': '博客 ',
    'menu#5': '法律文件',
    'menu#6': 'EN',
    'menu#7': '产品',
//    'menu#8': '销售详情',
    'menu#9': '团队',
    'menu#10': '公开文件',
    'menu#11': 'CINDX 平台',
    'menu#12': '订阅',

    'mailForm#1': '错误的电子邮件',
    'mailForm#2': '发送.',
    'mailForm#3': '已经使用过或无效，请再试一次',
    'mailForm#4': '您的电子邮件已成功发送',
    'mailForm#5': '放生了一个错误，请重新尝试一遍',
    'mailForm#6': 'GDRP法规',
    'mailForm#7': '通过订阅表格和KYC程序提供的个人数据将完全符合GDRP',
    'mailForm#8': '该计划的数据保护官员是Sofja Pevzner Belositski。联系方式：dpo@cindx.io。如有关于数据保护的任何问题可以随时直接联系数据保护官员',
    'mailForm#9': '您通过订阅表格和KYC程序提供的个人数据将分享给我们的合作伙伴：',
    'mailForm#10': 'ICO Promo',
    'mailForm#17': '我同意处理',
    'mailForm#18': '我的个人数据',
    'mailForm#19': '证券型代币销售启动',
    'mailForm#20': '20%奖金',
    'mailForm#21': '先到先得',
    'mailForm#22': '发售截至时间',
    'mailForm#23': '现在投入的1美元',
    'mailForm#24': '后来投入的1美元',
    'mailForm#25': '您的电子邮箱',
    'mailForm#26': '参与预售',
    'mailForm#27': '同意和提交',
    'mailForm#28': '比其他人更快获得。优惠期至',
    'mailForm#29': '想获得最新消息及优惠？',
    'mailForm#33': '预定',
    'mailForm#34': '招股书',
    'mailForm#35': '白皮书',
    'mailForm#36': '',
    'mailForm#37': '',
    'mailForm#38': '',
    'mailForm#39': '',
    'mailForm#40': '',
    'mailForm#41': '',

 //   'docPopup#1': 'Receive all documents in your email',
 //   'docPopup#2': 'Enter your email',
 //   'docPopup#3': 'SEND ME DOCS',

 //   'progressBarSale#1': 'Bonuses',
 //   'progressBarSale#2': 'in CINX',
 //  'progressBarSale#3': 'Pre-Sale',
 //   'progressBarSale#4': 'Sale',
 //   'progressBarSale#5': 'Aug 26',
 //   'progressBarSale#6': 'Sep 17',
 //   'progressBarSale#7': 'Oct 8',
 //   'progressBarSale#8': 'Oct 22',
 //   'progressBarSale#9': 'Nov 6',
 //   'progressBarSale#10': 'Nov 19',
 //   'progressBarSale#11': 'Dec 3',
 //   'progressBarSale#12': 'Dec 17',
  // 'progressBarSale#13': '单页报告',
 //   'progressBarSale#14': 'PRESENTATION',
  // 'progressBarSale#15': '白皮书',
 //   'progressBarSale#16': 'BLog',
 //   'progressBarSale#17': 'LEGAL DOCS',
 //   'progressBarSale#18': 'Download Our Public Documents',
 //   'progressBarSale#19': '',
 //   'progressBarSale#20': '',
 //   'progressBarSale#21': '',
 //   'progressBarSale#22': '',

 //   'ourTopRatings#1': 'Our Top Ratings',

    'cryptoAssetsManagement#1.1': 'CINDX是一个加密货币资产管理中心。',
    'cryptoAssetsManagement#1.2': '我们致力于为投资者、交易者和开发商解决切实问题。 ',
    'cryptoAssetsManagement#2': 'СINDX.MARKET是一个投资者市场',
    'cryptoAssetsManagement#3': '再也不要担心您的知识和经验了 - 加密资产交易的专家在这里随时为您提供再也不用担心您的知识和经验了.',
    'cryptoAssetsManagement#4': '掌控您的加密货币基金：您的代币时刻保存在您的钱包内',
    'cryptoAssetsManagement#5': '获利才需付费：只有您的投资组合盈利时才会向您收取费用',
    'cryptoAssetsManagement#6': '尝试这个平台',
    'cryptoAssetsManagement#7': 'CINDX.TRADE是一个交易者终端 ',
    'cryptoAssetsManagement#8': '如果您是一名成功的交易者，您便可在无需投入任何营销费用的情况下吸引资本。',
    'cryptoAssetsManagement#9': '我们为您提供了一个终端、应用商店、交易市场和其他先进工具，助您成功交易。',
    'cryptoAssetsManagement#10': 'CINDX助您将您的声誉和专业知识货币化。您的交易历史和声誉是透明的',
    'cryptoAssetsManagement#11': 'CINDX.TALK, CINDX.HUB & CINDX.DAO',
    'cryptoAssetsManagement#12': 'CINDX.talk  - 一个有用内容奖励的社交网络',
    'cryptoAssetsManagement#13': 'CINDX.hub - 包括供应商的帐户及用于测试和集成第三方软件的枢纽',
    'cryptoAssetsManagement#14': 'CINDX.loan - 一个允许提供和投资加密支持P2P贷款的组件',
    'cryptoAssetsManagement#15': 'CINDX.market 是一个为投资者而设的市场，投资者可以评估和选择要遵循的策略或投资的指数。',
    'cryptoAssetsManagement#16': 'CINDX.trade是为交易者交易加密资金而设的模块，交易者可以在一个地方进行不同的交易，分析市场，自动交易，和实现高额收益。',
    'cryptoAssetsManagement#17': 'CINDX使用API来收集数据和执行交易。API和密钥的安全性绝不低于用户的登录名和密码详细信息。为了保护CINDX用户，我们使用银行级加密将所有秘密API密钥保存在独立的数据库中。',
    'cryptoAssetsManagement#18': 'CINDX.HUB',
    'cryptoAssetsManagement#19': 'CINDX.hub是一个用于创建算法策略、历史测试和分析的组件，其中包含许多现成的解决方案。',
    'cryptoAssetsManagement#20': 'HUB.Pro是专业交易者的选择，它允许自动交易策略进行全天候交易和收益。',
    'cryptoAssetsManagement#21': 'HUB.Lite是投资者的选择，他们可以使用灵活风险设置支持的成熟工具。',
    'cryptoAssetsManagement#22': '',
    'cryptoAssetsManagement#23': '',
    'cryptoAssetsManagement#24': '',

    'teamLegal#1': '我们拥有优秀的团队和法律部门',
 //   'teamLegal#2': '法律和监管组织框架',
    'teamLegal#3': '加密货币交易许可证',
    'teamLegal#4': '金融机构许可证',
    'teamLegal#5': '加密账户许可证',
    'teamLegal#6': '白皮书',
    'teamLegal#7': '演示文稿',
    'teamLegal#8': '单页报告',
//    'teamLegal#9': '财务模型',
    'teamLegal#10': '法律文件',

/*  'roadmap#1': '路线图',
    'roadmap#2': '情景',
    'roadmap#3': '我们开始为 CINDX 平台打造基础。这一步骤开发了最简可行产品(MVP)，允许 访问交易和复制协议。我们大部分的资源配置给了平台的拓展性和安全性。',
    'roadmap#4': '平台的 CINDX.alpha 版本包括通过智能合约解决的区块链架构和基本系统。 CINDX.market 和 CINDX.trade 组件进行了改进，增加了新功能。',
    'roadmap#5': '基于 CINDX.alpha 版本的区块链架构, 我们创造了 CINDX.talk 组件，将允许用户交 流、学习，并通过有用的内容收益。一个分析性模型将会被添加到平台的 CINDX.core 组件中，用来 分析市场信息并呈现给第三方服务。',
    'roadmap#6': '这一阶段将会加入 CINDX.market 和 CINDX.trade 组件。经理人可以创建基于索引 的产品，并且终端会和灵活的风险管理设置集合在一起。',
    'roadmap#7': 'CINDX.talk 将会拥有公开和个人的频道。在这个阶段，内容可以被分开。',
    'roadmap#8': '此阶段平台会有一个重大更新。用户将能够储蓄和交换加密货币及法定货币。 CINDX.trade 包括一个极度灵活的订单执行和风险管理系统。供应商市场出现;但此时应用仅由 CINDX 及其合作伙伴开发。',
    'roadmap#9': '最终版本将会包括 CINDX.talk 的符号化评级、新型“算法交易“产品，以及交易和多次换汇订单中的自动套利功能。',
    'roadmap#10': '我们开始为 CINDX 平台打造基础。这一步骤开发了最简可行产品(MVP)，允许访问交易和复制协议。我们大部分的资源配置给了平台的拓展性和安全性。',
    'roadmap#11': '平台的 CINDX.alpha 版本包括通过智能合约解决的区块链架构和基本系统。 CINDX.market 和 CINDX.trade 组件进行了改进，增加了新功能。',
    'roadmap#12': '基于 CINDX.alpha 版本的区块链架构, 我们创造了 CINDX.talk 组件，将允许用户交 流、学习，并通过有用的内容收益。一个分析性模型将会被添加到平台的 CINDX.core 组件中，用来 分析市场信息并呈现给第三方服务。新组件 CINDX.hub 可以开发应用程序来分析 CINDX 提供的市场 数据。',
    'roadmap#13': '这一阶段将会加入 CINDX.market 和 CINDX.trade 组件。经理人可以创建基于索引 的产品，并且终端会和灵活的风险管理设置集合在一起。面向开发人员发布的软件开发工具包 (SDK)，将能够简化 CINDX 应用软件开发及实施。',
    'roadmap#14': 'CINDX.talk 将会拥有公开和个人的频道。在这个阶段，内容可以被分开。 CINDX.hub 将会集成 Github，使应用软件开发更加便利。',
    'roadmap#15': '此阶段平台会有一个重大更新。用户将能够储蓄和交换加密货币及法定货币。 CINDX.trade 包括一个极度灵活的订单执行和风险管理系统。CINDX.hub 拥有一个用于交易策略的全 面编辑器，其中包含基于历史市场数据的测试系统。',
    'roadmap#16': '最终版本将会包括 CINDX.talk 的符号化评级、新型“算法交易“产品，以及交易和 多次换汇订单中的自动套利功能。一个用于代码审计的高级平台被建立，产生基于 CINDX.talk 符号 化评级的社区专家评定。',
    'roadmap#17': '我们开始为 CINDX 平台打造基础。这一步骤开发了最简可行产品(MVP)，允许 访问交易和复制协议。我们大部分的资源配置给了平台的拓展性和安全性。',
    'roadmap#18': '平台的 CINDX.alpha 版本包括通过智能合约解决的区块链架构和基本系统。 CINDX.market 和 CINDX.trade 组件进行了改进，增加了新功能。',
    'roadmap#19': '基于 CINDX.alpha 版本的区块链架构, 我们创造了 CINDX.talk 组件，将允许用户交 流、学习，并通过有用的内容收益。一个分析性模型将会被添加到平台的 CINDX.core 组件中，用来 分析市场信息并呈现给第三方服务。新组件 CINDX.hub 可以开发应用程序来分析 CINDX 提供的市场 数据。由团队开发的 CINDX.dao 组件，用来向持有人发放平台收入。',
    'roadmap#20': '这一阶段将会加入 CINDX.market 和 CINDX.trade 组件。经理人可以创建基于索引 的产品，并且终端会和灵活的风险管理设置集合在一起。面向开发人员发布的软件开发工具包 (SDK)，将能够简化 CINDX 应用软件开发及实施。',
    'roadmap#21': 'CINDX.talk 将会拥有公开和个人的频道。在这个阶段，内容可以被分开。 CINDX.hub 将会集成 Github，使应用软件开发更加便利。',
    'roadmap#22': '此阶段平台会有一个重大更新。用户将能够储蓄和交换加密货币及法定货币。 CINDX.trade 包括一个极度灵活的订单执行和风险管理系统。CINDX.hub 拥有一个用于交易策略的全 面编辑器，其中包含基于历史市场数据的测试系统。CINDX.dao 增加了代币持有者的投票系统。',
    'roadmap#23': '最终版本将会包括 CINDX.talk 的符号化评级、新型“算法交易“产品，以及交易和 多次换汇订单中的自动套利功能。一个用于代码审计的高级平台被建立，产生基于 CINDX.talk 符号 化评级的社区专家评定。CINDX.dao 包括一个委托系统，使 CINDX 更加去中心化。',
*/  'roadmap#24': '发展蓝图',
    //'roadmap#25': '',
    //'roadmap#26': '',

    'problemSolving#1': '时间',
    'problemSolving#2': '信任',
    'problemSolving#3': '风险性',
    'problemSolving#4': '我想投资加密货币，但没有时间进行研究以及联系专业交易者来管理我的投资组合',
    'problemSolving#5': '我一直不放心把钱交给别人来替我盈利的模式.',
    'problemSolving#6': '加密货币交易的相关技术不够人性化。我不知道该怎么交易我的代币',
    'problemSolving#7': 'CINDX为您提供接触信誉良好、值得信赖的交易者的机会',
    'problemSolving#8': '在CINDX平台上，您的资金从不会离开您的钱包',
    'problemSolving#9': '您可以与市场内的最佳交易者一同盈利',
    'problemSolving#10': 'CINDX平台上的交易者根据其成功交易的历史获得评级。快速选择适合您的交易者',
    'problemSolving#11': '我们的分散式管理系统和智能合约能够让您在无需将资金转给交易者的情况下将其交给交易者托管',
    'problemSolving#12': 'CINDX的每一名投资者都有机会将资金交给市场内最成功的交易者管理',
    'problemSolving#13': 'CINDX能够解决哪些问题？',
    'problemSolving#14': 'CINDX LIVE ',

    'howCindxWorks#1': '投资者',
    'howCindxWorks#2': '交易者',
    'howCindxWorks#3': '商户',
    'howCindxWorks#4': '借助顶尖交易者的投资组合管理技能增加其加密货币投资盈利',
    'howCindxWorks#5': '根据其表现和交易策略从跟随其交易的投资者中赚取佣金',
    'howCindxWorks#6': '销售先进的交易工具，如交易机器人和新闻小工具。',
    'howCindxWorks#7': '赚取佣金和订阅费',
    'howCindxWorks#8': '我加入CINDX是因为我想交易加密货币来投资盈利。',
    'howCindxWorks#9': '我加入CINDX的原因是因为我拥有能够让投资者受益的交易专业知识',
    'howCindxWorks#10': '我加入CINDX的原因是因为我拥有能够帮助投资者的交易应用程序',
    'howCindxWorks#11': '我们致力于让交易变得便捷、安全并且利润丰厚',
    'howCindxWorks#12': 'CINDX如何运作',

    'marketAnalytics#1': '市场分析',
    'marketAnalytics#2': '管理下的加密资产增长（百万美元）',
    'marketAnalytics#3': '按地区划分的在线交易员数量（千人）',
    'marketAnalytics#4': '加密市场月交易量（亿美元）',
    'marketAnalytics#5': '虽然2018年加密货币的价格整体呈下降趋势，但是在资金管理下, 资产中引入新的流动性因素，行业继续发展。',
    'marketAnalytics#6': '',
    'marketAnalytics#7': '2017年和2018年不同地区的在线交易者的数量增长情况。2018年的领先者是亚洲和欧洲，其最主要的原因是因为加密资产管理中心对新用户的加入门槛低。',
    'marketAnalytics#8': '',
    "marketAnalytics#_9": '加密市场的交易量已经超过2017年底的加密货币大肆宣传的数量。尽管几乎每年都会有熊市，但是从调查的数据中可以看出，交易仍然非常积极地发展。',
    "marketAnalytics#_10": '',
    //'marketAnalytics#9': 'WHITEPAPER',
    //'marketAnalytics#10': 'PRESENTATION',
    //'marketAnalytics#11': 'ONE PAGER',
    //'marketAnalytics#12': 'FINANCIAL MODEL',
    //'marketAnalytics#13': 'LEGAL DOCS',
    'marketAnalytics#14': '地理范围',
    'marketAnalytics#15': '超过2500万潜在的管理人员和交易者',
    'marketAnalytics#16': '',
    'marketAnalytics#17': '每天超过1亿潜在投资者及交易',
    'marketAnalytics#18': '',
    'marketAnalytics#19': '超过1000个机构合作伙伴，',
    'marketAnalytics#20': '每个合作伙伴拥有3-5亿美元，',
    'marketAnalytics#21': '由Cryptofundresearch.com提供', //(1 слайд вместо Reference)
    'marketAnalytics#22': '由Brokernotes.co提供',         //(2 слайд вместо Reference)
    'marketAnalytics#23': '由Coinmarketcap.com提供',      //(3 слайд вместо Reference)

   /* 'tokenSaleDetails#1': '代币分配',
    'tokenSaleDetails#2': '团队',
    'tokenSaleDetails#3': '赏金',
    'tokenSaleDetails#4': '顾问',
    'tokenSaleDetails#5': '储备金',
    'tokenSaleDetails#6': '交易员招聘计划',
    'tokenSaleDetails#7': '代币流动性支持',
    'tokenSaleDetails#8': '供于销售',
    'tokenSaleDetails#9': '代币名称：',
    'tokenSaleDetails#10': '代币预售开始：',
    'tokenSaleDetails#11': '总发行量：',
    'tokenSaleDetails#12': '代币单价：',
    'tokenSaleDetails#13': '最低投资额：',
    'tokenSaleDetails#14': '50美元等值',
    'tokenSaleDetails#15': '白皮书',
    'tokenSaleDetails#16': '演示文稿',
    'tokenSaleDetails#17': '单页报告',
    'tokenSaleDetails#18': '财务模型',
    'tokenSaleDetails#19': '法律文件',
    'tokenSaleDetails#20': '收益用途',
    'tokenSaleDetails#21': '营销和公关21%',
    'tokenSaleDetails#22': '研发41%',
    'tokenSaleDetails#23': '运营28%',
    'tokenSaleDetails#24': '法务费用7%',
    'tokenSaleDetails#25': '社区补助金3%',
    'tokenSaleDetails#26': '代币交易',
    'tokenSaleDetails#27': '代币销售：',
    'tokenSaleDetails#28': '代币销售奖励金：',
    'tokenSaleDetails#29': '从8月26日至9月16日 - 25%奖励金',
    'tokenSaleDetails#30': '从9月17日至10月7日 - 20%奖励金',
    'tokenSaleDetails#31': '代币主要销售奖励金：',
    'tokenSaleDetails#32': '从10月8日至10月21日 - 17%奖励金',
    'tokenSaleDetails#33': '从10月22日至11月4日 - 15%奖励金',
    'tokenSaleDetails#34': '从11月6日至11月18日 - 13%奖励金',
    'tokenSaleDetails#35': '从11月2日至12月2日 - 10%奖励金',
    'tokenSaleDetails#36': '从12月3日至12月16日 - 5%奖励金',
    'tokenSaleDetails#37': '从12月17日至12月26日 - 0%奖励金',
    'tokenSaleDetails#38': '购买代币',
    'tokenSaleDetails#39': '代币销售详情',
    'tokenSaleDetails#40': '代币销售期间，投资者们购买实用型代币——期权[CINXO], 以获得未来收到证券代币[CINX] 的权利。',
    'tokenSaleDetails#41': '证券代币 [CINX] 的发布和发放将会基于《发行预期》(将在代币销售结束后在爱沙尼亚的金融管理局即 FSA 的网站:https://www.fi.ee/ and cindx.io 上发布，不晚于 2019 年第一季度)。',
    'tokenSaleDetails#42': '[CINXO]代币的持有者在完成 KYC 程序并具有身份识别签名，确认地址和提供相关文件后，有权利但没有义务以 1:1 的比例兑换证券代币 (详见 CINDX KYC 计划).',
    'tokenSaleDetails#43': '[CINXO]代币除了参与奖励计划的收入分配和在平台上的投票外，享有和证券代币同样的权利。',

    'legalSchedule#1': '法务工作时间表',
    'legalSchedule#2': '已获牌照（FVR000110）以提供虚拟货币和法币交易服务',
    'legalSchedule#3': '在爱沙尼亚和欧盟申请商标注册',
    'legalSchedule#4': '牌照FFA000254（金融机构牌照）',
    'legalSchedule#5': '已获牌照FFA000254（金融机构牌照）',
    'legalSchedule#6': '根据爱沙尼亚法律编制了发行说明书',
    'legalSchedule#7': '获得了FSA牌照（爱沙尼亚金融监管局） ',
    'legalSchedule#8': '获得了提供虚拟货币钱包服务的牌照',
    'legalSchedule#9': '根据证券交易委员会豁免规则D进行注册',
    'legalSchedule#10': '委任了一名个人数据经理以满足GDPR要求',
    'legalSchedule#11': '已获FSA牌照',
    'legalSchedule#12': '根据欧盟说明书规例（809/2004）注册',
    'legalSchedule#13': '发行了CINX证券型代币',
*/
    'feedbackProject#_1': '视频',
    'feedbackProject#1': '我们的项目的回馈',
    'feedbackProject#2': 'Val Jerdes',
    'feedbackProject#3': '产品总监',
    'feedbackProject#4': 'Innov8 Global Ventures的合伙人|顾问验室',
    'feedbackProject#5': '视频',
    'feedbackProject#6': '“CINDX拥有在传统的「法定」金融世界已经习以为常的加密世界中，在一个快速兴起的新金融产品层中发挥作用的平台。同时，他们正在支持开发一类新的专业加密管理器。非常令人兴奋！”.',
    //'feedbackProject#7': 'Denis Eskenazi',
    //'feedbackProject#8': 'Product Marketing Director',
    //'feedbackProject#9': 'Denis Eskenazi, CINDX Product Marketing Director explains why he has joined CINDX',
    'feedbackProject#10': 'Philip Staehelin',
    'feedbackProject#11': 'CINDX 顾问',
    'feedbackProject#12': 'Roland Berger的管理合伙人',
    'feedbackProject#13': '视频',
    'feedbackProject#14': '“CINDX是一个绝妙的想法，解决了加密货币和代币投资的所有痛点。”',
    //'feedbackProject#15': 'Andrzej Cichowlaz',
    //'feedbackProject#16': 'Marketing Specialist',
    //'feedbackProject#17': 'Andrzej Cichowlaz, CINDX Marketing Manager tells why he believes in the project`s success',
    'feedbackProject#18': 'Austin Kimm',
    'feedbackProject#19': 'CINDX顾问',
    'feedbackProject#20': '国际金融服务公司首席执行官',
    'feedbackProject#21': '视频',
    'feedbackProject#22': '“CINDX再次将“交易者及投资者”关系变为个人关系。”',
    'feedbackProject#23': 'Amarpreet Singh',
    'feedbackProject#24': 'CINDX 顾问',
    'feedbackProject#25': '全球区块链基金会（Global Blockchain Foundation）高级顾问',
    'feedbackProject#26': '视频',
    'feedbackProject#27': '“CINDX不仅为所有投资者及交易员提供复杂的工具, 同时还提供了一个平台，可以让我们在交易时毫不费力的以有丰富经验的交易员的操作为参考。”',
    'feedbackProject#28': 'Keith Teare',
    'feedbackProject#29': 'CINDX 顾问',
    'feedbackProject#30': 'Accelerated Digital Ventures的创始人兼执行主席',
    'feedbackProject#31': '视频',
    'feedbackProject#32': '“通过鼓励新交易员跟踪有交易经验交员的这一方式，CINDX消除了缺乏经验导致亏钱的担忧。”',
    //'feedbackProject#33': 'Mansur Rasulov',
    //'feedbackProject#34': 'Community Lead',
    //'feedbackProject#35': 'Mansur Rasulov, CINDX Community Lead explains why he decided to become a part of CINDX team',
    'feedbackProject#36': 'Sadie Hutton',
    'feedbackProject#37': 'CINDX 顾问',
    'feedbackProject#38': 'AuBit的创始人和首席战略官',
    'feedbackProject#39': '视频',
    'feedbackProject#40': '“整个协议为所有的参与者创造了一个具有真实支持和充满智慧的社区。”',
    'feedbackProject#41': 'Graham Doggart',
    'feedbackProject#42': 'CINDX 顾问',
    'feedbackProject#43': 'AuBit的创始人和首席产品官',
    'feedbackProject#44': '视频',
    'feedbackProject#45': '“设想一下投资者将他的资金委托给一个市场专家然后让他交易，但同时可以完全掌控自己的资金”',
    //'feedbackProject#46': 'Alina Vardanian',
    //'feedbackProject#47': 'Content Manager',
    //'feedbackProject#48': 'Alina Vardanian, CINDX Content Manager talks about the team spirit and what she loves about her job',
    'feedbackProject#49': 'Bogdan Fiedur',
    'feedbackProject#50': 'CINDX 顾问',
    'feedbackProject#51': 'bitJob联合创始人兼Blockchainexperts.io总裁',
    'feedbackProject#52': '视频',
    'feedbackProject#53': '“对于加密货币投资市场来说，任何旨在简化投资者、管理者和供应商工作的创新方案都是有潜在的破坏性。”',
    'feedbackProject#54': 'Jason King',
    'feedbackProject#55': 'CINDX 顾问',
    'feedbackProject#56': 'Telenor首席执行官',
    'feedbackProject#57': '视频',
    'feedbackProject#58': '“CINDX确实可以为大众市场投资者揭开加密货币交易世界的神秘面纱。这为众多普通投资者争取了入市交易的机会，并且为他们量身打造了更公平、更透明的交易方式。”',

    'mediaNews#1': '传媒',
    'mediaNews#2': '新闻',
    // Media ( не переводим)
    'mediaNews#1_1': 'CINDX Aims to Democratize the Modern Financial System',
    'mediaNews#1_2': 'Even as investors in the cryptocurrency space recognize that they are at the forefront of a new financial revolution, adoption by the general populace is yet to occur. While 3rd parties are slowly entering the space in the form of hedge funds and other investors, very few individuals seem to have taken the step to invest in cryptocurrencies on their own.',
    'mediaNews#1_3': 'CINDX Announces Plans For ICO Pre-Sale Amidst Release Of Revolutionary Crypto Trading Hub That Allows Users To Follow Expert Traders',
    'mediaNews#1_4': 'CINDX takes its business seriously with particular attention and cares to legal matters and has taken great pains to ensure that CINDX is as compliant as possible with global regulations.',
    'mediaNews#1_5': 'How CINDX is Democratizing the Modern Financial System',
    'mediaNews#1_6': 'CINDX is a new platform that is making crypto trading easier, more secure, and more accessible. With this platform, it’s possible to have your funds traded without high fees or minimum investments, with the added security and transparency of blockchain technology.',
    'mediaNews#1_7': 'CINDX is making crypto trading easier, more secure, and more accessible.',
    'mediaNews#1_8': 'Cryptocurrency and blockchain technology have been slated as powerful tools capable of fueling a global financial revolution. There are myriad opportunities to invest in the crypto space, and yet, very few individuals have undertaken the steps necessary to invest.',
    'mediaNews#1_9': 'CINDX Announces Plans Release Of Revolutionary Crypto Trading Hub',
    'mediaNews#1_10': 'The CINDX platform creates a complete ecosystem for cryptocurrency trading that is favorable to all the participants of the market and offers solutions to existing pain points.',
    'mediaNews#1_11': 'CINDX Announces Plans For ICO Pre-Sale',
    'mediaNews#1_12': 'CINDX made headlines this week with the announcement that they will be opening their ICO Pre-Sale in late August after investors interest in the ICO peaks to new levels.',
    'mediaNews#1_13': 'CINDX Will Be Opening ICO Pre-Sale in late August',
    'mediaNews#1_14': 'The CINDX Crypto trading platform has a myriad of features and benefits that haven’t been seen in other platforms to date.',
    'mediaNews#1_15': '5 Tips from CINDX Crypto Assets Management Hub: How to Avoid Being Cheated',
    'mediaNews#1_16': 'The team behind the CINDX crypto assets management hub has created a helpful set of guidelines on how not to become victims of unfair play, so you can learn what should be primarily taken into account when evaluating ICOs before investing in them.',
    'mediaNews#1_17': 'CINDX Crypto Asset Management Hub Adopts Bancor Protocol',
    'mediaNews#1_18': 'CINDX is delighted to announce that a number of new integrations with top industry players are underway, each of which will make the life of the users of the platform easier and richer. These new integrations include the decision to adopt the Bancor protocol to provide continuous liquidity for the CINDX platform.',
    'mediaNews#1_19': 'CINDX Crypto Asset Management Hub Adopts Bancor Protocol',
    'mediaNews#1_21': 'CINDX is delighted to announce that a number of new integrations with top industry players are underway, each of which will make the life of the users of the platform easier and richer. These new integrations include the decision to adopt the Bancor protocol to provide continuous liquidity for the CINDX platform.',
    'mediaNews#1_22': 'CINDX Beginning ICO for Crypto Asset Management and Social Network',
    'mediaNews#1_23': 'CINDX, a cryptocurrency project has now begun its security token ICO pre-sale to introduce a multifunctional platform that allows blockchain investors to choose an expert trader to follow based on trading stats recorded on the blockchain.',
    'mediaNews#1_24': 'Blockchain Investors Can Now Make It Big with the Help of CINDX',
    'mediaNews#1_25': 'Cryptocurrencies have certainly made their mark. The digital tokens have consistently shown that they have the potential to rise very high in value. The market is huge and investors are insatiable.',
    'mediaNews#1_26': 'Even Amateur Investors Can Make It Big with the Help of CINDX',
    'mediaNews#1_27': 'CINDX, the crypto asset management company, recently opened its ICO pre-sale and is introducing a multifunctional platform that allows anybody to invest in the crypto market while consistently earning returns. ',
    'mediaNews#1_28': 'CINDX Crypto Asset Manager For Traders Launches Security Token ICO',
    'mediaNews#1_29': 'CINDX, a digital currency startup, recently initiated the presale phase of its ICO token sale. Then proceeds from the ICO will be used to establish a multifunctional platform that enables crypto traders identify and follow their preferred expert investor based on trading statistics recorded on the blockchain.',
    'mediaNews#1_30': 'CINDX CEO Shares His Point of View on why Investors Should Treat Cryptocurrency and Blockchain Predictions with "Healthy Skepticism".',
    'mediaNews#1_31': 'Crypto isn\'t all it\'s cracked up to be. Citi analyst Josh Levin says to treat the many cryptocurrency and blockchain predictions with "healthy skepticism" and to remain "open minded".',
    'mediaNews#1_32': 'CINDX CEO Shares His Point of View on Rising Bitcoin Mining Hashrates',
    'mediaNews#1_33': 'Even though cryptocurrency prices have been significantly down throughout 2018 thus far, oddly, hashrates for the Bitcoin network—signifying mining activity—have risen meaningfully. Could this disparity provide some insight into the future of alt-currencies?',
    // News (Не переводим)
    'mediaNews#2_1': 'CINDX meets Ian Balina',
    'mediaNews#2_2': 'CINDX pitch to the one of the TOP10 crypto gurus in the world in the Cryptobazar event.',
    'mediaNews#2_3': 'CINDX took part in Moscow ICO Summit 2018',
    'mediaNews#2_4': 'CINDX team introduced the project to the Russian crypto community during ICO Summit event.',
    'mediaNews#2_5': 'How CINDX is ensuring legal compliance',
    'mediaNews#2_6': 'Our company is registered in Estonia. Why? The answer is simple — the authorities of Estonia have created a strong and innovative legal infrastructure...',
    'mediaNews#2_7': 'Keith Teare Co-founder of Accelerated Digital Ventures and Founding Shareholder in TechCrunch',
    'mediaNews#2_8': 'We continue to introduce CINDX advisers, find out what they...',
    'mediaNews#2_9': 'Meet world\'s first financial crypto social network',
    'mediaNews#2_10': 'Every day our team takes one more step on the way to creation and start...',
    'mediaNews#2_11': 'Denis Eskenazi CINDX Product Marketing Director',
    'mediaNews#2_12': 'We continue to tell about the CINDX team and each team member’s professional experience. Please, read about Denis Eskenazi...',
    'mediaNews#2_13': 'CINDX crypto currency exchange license',
    'mediaNews#2_14': 'Our company in Estonia has received Crypto Currency Exchange License. This allows CINDX to provide fiat-to-crypto exchange services. This licence is issued...',
    'mediaNews#2_15': 'Austin Kimm Co-founder of Crypterium & CINDS Adviser',
    'mediaNews#2_16': 'We continue to introduce CINDX advisers, find out what they are proud...',
    'mediaNews#2_17': 'The strategic meeting of the international CINDX team',
    'mediaNews#2_18': 'Every day our team takes one more step on the way to creation and start of the CINDX platform. Our work...',
    'mediaNews#2_19': 'Artur Shamalov CINDX Investment Director',
    'mediaNews#2_20': 'We continue to introduce the CINDX team. Our next article is about Artur Shamalov, a skilled...',
    'mediaNews#2_21': 'The issuance of CINX tokens',
    'mediaNews#2_22': 'The issuance of CINX tokens will be carried out according to the license of The Estonian Financial Supervisory Authority (EFSA). What is a token...',
    'mediaNews#2_23': 'Amarpreet Singh Co-Founding Member of Malta Blockchain Association & CINDX adviser',
    'mediaNews#2_24': 'We at CINDX are incredibly proud to present our advisers, tell the world about their achievements and why they have decided to become a part of the CINDX...',
    'mediaNews#2_25': 'CINDX meets Vangoo Capital Partners Fund',
    'mediaNews#2_26': 'Today we have welcomed Kevin Shang from Vangoo Capital Partners and his colleagues in our office. We have discussed business partnership opportunities in order to attract investors from the Asian region.',
    'mediaNews#2_27': 'Mofassair Hossain CINDX Chief Marketing Officer.',
    'mediaNews#2_28': 'We continue to introduce the CINDX team, explore each team member’s point of view, find out what our team members are particularly excited about...',
    'mediaNews#2_29': 'How CINDX ensures safety for its users - KYC',
    'mediaNews#2_30': 'Every crypto enthusiast knows that a KYC procedure is necessary in order to protect investors against fraudulent actions of the third...',
    'mediaNews#2_31': 'Philip Staehelin 25 Years of Consulting and Entrepreneurial Experience',
    'mediaNews#2_32': 'We continue our regular series of articles dedicated to introducing CINDX advisers. The next item is about Philip Staehelin.',
    'mediaNews#2_33': 'CINDX meets 9coin Digital Asses Exchange',
    'mediaNews#2_34': 'The CINDX team continues active work on building relationships with various international companies. One of the most important directions of business...',
    'mediaNews#2_35': 'Jason King',
    'mediaNews#2_36': 'The core of any great project is always people who make the unique idea come true. We would like to tell about...',
    'mediaNews#2_37': 'CINDX Presents the project during RICLUB private investors meeting',
    'mediaNews#2_38': 'CINDX has successfully passed the due diligence procedure requested by RICLub and was one of the few projects...',
    'mediaNews#2_39': 'Sofja Pevzner CINDX Personal Data Officer',
    'mediaNews#2_40': 'We continue to introduce our team and tell about what inspires our colleagues to work with CINDX. Please read about...',
    'mediaNews#2_41': 'Tyler Sanford 8+ years digital marketing in Yelp, Zenefits',
    'mediaNews#2_42': 'We continue to introduce CINDX advisers, find out what they are proud of and why they have decided to become a part of the CINDX team. In this...',
    'mediaNews#2_43': 'CINDX - Key points to observe',
    'mediaNews#2_44': 'The CINDX project is a valuable business venture with the features that are critically relevant for its participants. In this article we talk about Strengths...',
    'mediaNews#2_45': 'Airat Shayhulov CINDX Head of Quantitative Research.',
    'mediaNews#2_46': 'In “TEAM” series of the articles, we tell about people who every day participates in the creation...',
    'mediaNews#2_47': 'CINDX license of the financial consultant',
    'mediaNews#2_48': 'CINDX has obtained a termless operating licence (the FFA 000254 license) that gives the right to operate as a financial institution...',
    'mediaNews#2_49': 'Graham Doggart 20 Years of Enterprise Development and Fintech Marketing Compliance',
    'mediaNews#2_50': 'We continue our regular series of articles dedicated to introducing CINDX advisers. The next item...',
    'mediaNews#2_51': 'CINDX continues the roadshow in China!',
    'mediaNews#2_52': 'The CINDX team is glad to announce participation as a Golden Sponsor of Borderless Blockchain Technology Summit 2018, which...',
    'mediaNews#2_53': 'Ibraghim Haniev CINDX Tech Lead',
    'mediaNews#2_54': 'Get to know more about the CINDX team! The core of any great project is always people who make the unique ideas come true...',
    'mediaNews#2_55': 'Sadie Hutton 25 Years in Senior Management and Management Consulting',
    'mediaNews#2_56': 'Like any global project, we understand the importance of professional help and advice. We continue to tell the stories of...',
    'mediaNews#2_57': 'CINDX.talk - a service that will help people become wiser in taking financial decisions',
    'mediaNews#2_58': 'The current digital-world related to the crypto industry is getting crowded with mind-charming digital solutions...',
    'mediaNews#2_59': 'Val Jerdes CINDX Product Director',
    'mediaNews#2_60': 'We continue to introduce the CINDX team, explore each team member’s point of view, find out what our team members are particularly...',
    'mediaNews#2_61': 'Bogdan Fiedur Blockchain and cryptocurrency expert, investor, entrepreneur',
    'mediaNews#2_62': 'We continue to introduce CINDX advisers, find out what they are proud of and why they have decided to become...',
    'mediaNews#2_63': 'Welcome to CINX Token Distribution – it’s not an ICO; it’s an STO',
    'mediaNews#2_64': 'In this article, we want to make it clear why CINDX chose to hold a Security Token Offering (STO) instead of an Initial...',
    'mediaNews#2_65': 'CINDX celebrates the “AA” Rating from Top ICO List Audit!',
    'mediaNews#2_66': 'The results of CINDX’s sincerity to make meaningful changes in people’s ambitions via a unique business model just got appreciated...',
    'mediaNews#2_67': 'CINDX Weekly digest (30.07.18 - 05.08.18)',
    'mediaNews#2_68': 'The CINDX team is very excited to share the results of our work for the last week 30.07.18 - 05.08.18!',
    'mediaNews#2_69': 'Virtual Currency Wallet Services',
    'mediaNews#2_70': 'CINDX believes that being fluent in legal issues and having a clean, confident and clear legal status is of utmost importance. At the moment, CINDX is going...',
    'mediaNews#2_71': 'How CINDX Influences Crypto Trading',
    'mediaNews#2_72': 'How to master the crypto market movements and turn them in one’s favor? This is where CINDX’s effective business model comes into play. Learn more about CINDX on our Medium!',
    'mediaNews#2_73': 'CINDX Weekly digest (06.08.18 - 12.08.18)',
    'mediaNews#2_74': 'CINDX continues its regular column - Weekly Digest. We are excited to share the recent achievements of our project! Enjoy your time reading!',
    'mediaNews#2_75': 'CINDX gets 5.0 rating on TrackICO!',
    'mediaNews#2_76': 'CINDX continues getting approval from the largest and the most credible rating services. The week has hardly passed after...',
    'mediaNews#2_77': 'David Gutierrez Investment Relations Officer',
    'mediaNews#2_78': 'CINDX is very serious about taking people on crew and proud to introduce them. This article is dedicated to David Gutierrez, CINDX Investment Relations Officer',
    'mediaNews#2_79': 'ICObench gives 4.8 to CINDX',
    'mediaNews#2_80': 'The CINDX project has already gained the highest mark of  ‘AA’ from Top ICO List and got a 5.0 rating from TrackICO. Now, we are proud to share...',
    'mediaNews#2_81': 'Dip your Toe in Crypto',
    'mediaNews#2_82': 'It is never late to change your mind. It’s no surprise that Wall Street bankers change their minds in favour of crypto. Enjoy reading CINDX review on the current market situation!',
    'mediaNews#2_83': 'Yuriy Avdeev, CEO of the CINDX project',
    'mediaNews#2_84': 'Enjoy reading what inspired Yuriy Avdeev to create a project that will help all participants of the crypto market to be profitably engaged - CINDX.',
    'mediaNews#2_85': 'Weekly digest (13.08.18 - 19.08.18)',
    'mediaNews#2_86': 'CINDX continues its regular Weekly Digest column. We are excited to share our project’s progress and the most recent achievements! We are glad to remind you that CINDX security token Pre-sale starts this Sunday. Don\'t miss!',
    'mediaNews#2_87': 'Dip your Toe in Crypto',
    'mediaNews#2_88': "It is never late to change your mind. It’s no surprise that Wall Street bankers change their minds in favour of crypto. Enjoy reading CINDX review on the current market situation!",
    'mediaNews#2_89': 'Yuriy Avdeev, CEO of the CINDX project',
    'mediaNews#2_90': 'Enjoy reading what inspired Yuriy Avdeev to create a project that will help all participants of the crypto market to be profitably engaged - CINDX.',
    'mediaNews#2_91': 'Weekly digest (13.08.18 - 19.08.18)',
    'mediaNews#2_92': 'CINDX continues its regular Weekly Digest column. We are excited to share our project’s progress and the most recent achievements! We are glad to remind you that CINDX security token Pre-sale starts this Sunday. Don"t miss',
    'mediaNews#2_93': '接下来的活动',
    'mediaNews#2_94': 'Ended',
    'mediaNews#2_95': '路演',

    'socialMedia#1': '欲了解更多信息，请在社交媒体上关注我们:',

    'teamAdvisersPartners#1': '核心成员',
    'teamAdvisersPartners#2': 'CINDX顾问',
    'teamAdvisersPartners#3': '我们的合作伙伴',
    // teamInfo
    'teamAdvisersPartners#4': 'Yuriy Avdeev',
    'teamAdvisersPartners#5': '首席执行官',
    'teamAdvisersPartners#6': '13年创业经验',
    'teamAdvisersPartners#7': '9个创投项目',
    'teamAdvisersPartners#8': '200万美元加密货币管理额',
    'teamAdvisersPartners#9': 'Val Jerdes',
    'teamAdvisersPartners#10': '产品总监',
    'teamAdvisersPartners#11': '20年创业经验',
    'teamAdvisersPartners#12': '3次成功退出',
    'teamAdvisersPartners#13': '投资组合取得4倍回报',
    'teamAdvisersPartners#14': 'Mofassair Hossain',
    'teamAdvisersPartners#15': '首席市场官',
    'teamAdvisersPartners#16': 'Investor and Adviser',
    'teamAdvisersPartners#17': '',
    'teamAdvisersPartners#18': 'GBAP成员',
    'teamAdvisersPartners#19': 'Artur Shamalov',
    'teamAdvisersPartners#20': '投资总监',
    'teamAdvisersPartners#21': '15年创业经验',
    'teamAdvisersPartners#22': '拥有5千多名mixcart.ru客户',
    'teamAdvisersPartners#23': 'mail.ru投资于dc-daily.ru',
    'teamAdvisersPartners#24': 'Denis Eskenazi',
    'teamAdvisersPartners#25': '产品营销总监',
    'teamAdvisersPartners#26': '7年创业经验',
    'teamAdvisersPartners#27': '为客户吸引了200万美元资金',
    'teamAdvisersPartners#28': '10年品牌建设经验',
    'teamAdvisersPartners#29': 'Ibraghim Haniev',
    'teamAdvisersPartners#30': 'CTO',
    'teamAdvisersPartners#31': '6年创业经验',
    'teamAdvisersPartners#32': '全栈开发者',
    'teamAdvisersPartners#33': '信息安全和区块链焦点',
    'teamAdvisersPartners#34': 'Airat Shaikhulov',
    'teamAdvisersPartners#35': '定量研究总监',
    'teamAdvisersPartners#36': '莫斯科国立大学的科学博士',
    'teamAdvisersPartners#37': 'ATON-LINE NAVIGATOR开发人员',
    'teamAdvisersPartners#38': '算法交易主管',
    'teamAdvisersPartners#39': 'Sofja Pevzner',
    'teamAdvisersPartners#40': '个人数据官',
    'teamAdvisersPartners#41': '5年创业经验',
    'teamAdvisersPartners#42': '拥有爱沙尼亚反洗钱办公室的工作许可',
    'teamAdvisersPartners#43': '8年法务工作经验',
    'teamAdvisersPartners#44': 'Dave Gutierrez',
    'teamAdvisersPartners#45': '投资关系主任',
    'teamAdvisersPartners#46': '5年飞机租赁',
    'teamAdvisersPartners#47': '3年投资促进',
    'teamAdvisersPartners#48': '2年俄罗斯海外交易',
    'teamAdvisersPartners#49': 'Jelena Karzetskaja',
    'teamAdvisersPartners#50': '合规专员',
    'teamAdvisersPartners#51': '拥有多个法律学位',
    'teamAdvisersPartners#52': '1997年开始参与司法工作',
    'teamAdvisersPartners#53': '法律领域：国际法',
    'teamAdvisersPartners#54': 'Wang Dong',
    'teamAdvisersPartners#55': '中国区块链市场专家',
    'teamAdvisersPartners#56': '3年PHP/JavaScript经验',
    'teamAdvisersPartners#57': '5年SEO/SMM/媒介采购员经验',
    'teamAdvisersPartners#58': '2年中国区块链开发和代币销售经验',
    'teamAdvisersPartners#59_1': 'Sergey Potekhin',
    'teamAdvisersPartners#60_1': '开发者',
    'teamAdvisersPartners#61_1': '6年开发经验',
    'teamAdvisersPartners#62_1': '3年金融科技和区块链开发',
    'teamAdvisersPartners#63_1': '数据科学经验',
    'teamAdvisersPartners#64_1': 'Georg Brich',
    'teamAdvisersPartners#65_1': 'CAO',
    'teamAdvisersPartners#66_1': '7年交易经验',
    'teamAdvisersPartners#67_1': '拥有金融科技项目架构发展经验',
    'teamAdvisersPartners#68_1': '5年资产管理经验',
    'teamAdvisersPartners#69_1': 'Yuri Proshin',
    'teamAdvisersPartners#70_1': '社区负责人',
    'teamAdvisersPartners#71_1': '国际商务硕士学位',
    'teamAdvisersPartners#72_1': '4年FinTech和区块链经验',
    'teamAdvisersPartners#73_1': '社区管理,公共关系和沟通',
    'teamAdvisersPartners#74_1': 'Nick Begi',
    'teamAdvisersPartners#75_1': '开发人员',
    'teamAdvisersPartners#76_1': '经验丰富的全栈开发者',
    'teamAdvisersPartners#77_1': '大数据处理',
    'teamAdvisersPartners#78_1': '分析和可视化',
    'teamAdvisersPartners#79_1': 'Alexander Bogdanov',
    'teamAdvisersPartners#80_1': '',
    'teamAdvisersPartners#81_1': '拥有超过12年经验的金融和投资咨询专家',
    'teamAdvisersPartners#82_1': '营销策略发展',
    'teamAdvisersPartners#83_1': '',
   
    // advisers
    'teamAdvisersPartners#59': 'Keith Teare',
    'teamAdvisersPartners#60': 'Accelerated Digital Ventures创始人和执行主席',
    'teamAdvisersPartners#61': 'ContextGrid, INFARM, etc.',
    'teamAdvisersPartners#62': 'TechCrunch创始股东',
    'teamAdvisersPartners#63': '我是英国风投公司Accelerated Digital Ventures的执行主席兼投委会主席。我是一名土生土长的英国人。',
    'teamAdvisersPartners#64': '此前我是加州帕洛阿尔托Archimedes Labs的创始人和合伙人。我喜欢与创始人、团队、设计师和工程师们合作创造产品、讲述故事并将其变为现实。自20世纪80年代以来，我曾创立和联合创立了多家企业。一些企业被成功出售，另一些则成功上市。',
    'teamAdvisersPartners#65': '其中有两家企业属于独角兽，估值超过10亿美元。我始终专注于发生改变的关键节点。例如20世纪80年代的网络化和数据库。1994-1998年期间的互联网接入。1998-2010年期间的网页服务和内容、移动消费者应用以及2017年以来的区块链。',
    'teamAdvisersPartners#66': '职业经历和项目：Tech Crunch、Accelerated Digital Ventures、cScape、EasyNet、Cyberia、RealNames、Venture Network、Archimedes Labs和CINDX',
    'teamAdvisersPartners#67': 'Austin Kimm',
    'teamAdvisersPartners#68': '国际金融服务首席执行官',
    'teamAdvisersPartners#69': '30多年金融服务经验',
    'teamAdvisersPartners#70': '创立过价值达5亿美元的企业',
    'teamAdvisersPartners#71': '经验丰富且颇有建树的金融服务企业首席执行官，专注于在发达市场中打造保险企业，曾成功创立目前估值超过5亿美元的企业。',
    'teamAdvisersPartners#72': '此前在俄罗斯（10年）工作，并担任过其他非保险企业的高管职务，包括俄罗斯最大互联网销售招聘平台Workle的首席执行官，以及各种金融科技初创企业的首席职务，包括全球首个数字加密货币银行Crypterium。',
    'teamAdvisersPartners#73': '职业经历和项目：Aviva、Commercial Union UK、Reinaissance Insurance、Kimm Health & Leisure Ltd、Workle、CryptoIndex、CINDX',
    'teamAdvisersPartners#74': 'Philip Staehelin',
    'teamAdvisersPartners#75': '25年咨询和创业经验',
    'teamAdvisersPartners#76': '管理5000万美元的替代支付方式业务',
    'teamAdvisersPartners#77': '为20个国家的200多家初创企业担任顾问',
    'teamAdvisersPartners#78': 'Philip有25年的全球合作和顾问的经验。他曾经在一家市值5亿美元的公司任职首席执行官，是Roland Berger（一家全球顶级管理顾问公司）的管理合作伙伴，并且他也是T-Mobile, Accenture, A.T. kearney 和 CA-IB 投资银行（现在叫UniCredit）的执行委员。',
    'teamAdvisersPartners#79': '他还是Startup Yard(CEE’S元老级 技术启动加速器)的共有人。他是最活跃的投资者并且他创办了很多新企业包括他最新进行的商业-DOT Glasses-开发这个的目的是为了给世界上超过1亿需要镜片却支付不起（或者没有地方可以购买）的人而准备。',
    'teamAdvisersPartners#80': 'Philip还是捷克世界透明组织的董事会成员和Gjirafa的董事会成员-是巴尔干半岛发展最迅速的公司，他为中东欧，波罗的海國家以及巴尔干地域的新企业提供顾问咨询。同时是众多新企业和首次代币发售的顾问。',
    'teamAdvisersPartners#81': 'Philip 从INSEAD获得了他的MBA(法国枫丹白露),并且在科罗拉多大学获得经济和国际事务的学士学位（ 博尔德，科罗拉多，美国）',
    'teamAdvisersPartners#82': 'Amarpreet Singh',
    'teamAdvisersPartners#83': '全球区块链基金会高级顾问',
    'teamAdvisersPartners#84': '拥有微软、世界银行和空中客车公司工作经验',
    'teamAdvisersPartners#85': '多个区块链项目的顾问委员会成员',
    'teamAdvisersPartners#86': '技术/数字爱好者和经验丰富的专业人士，拥有多年的科技行业运营、咨询和创新经验。',
    'teamAdvisersPartners#87': '他是一名经认证的云（微软和和AWS）专家。其以多才多艺和灵活变通闻名。拥有着卓越的分析、战略、领导技能和团队合作精神。',
    'teamAdvisersPartners#88': '专长：技术咨询、运营、云计算、金融科技、创新、ERP、机器学习、客户管理、合作伙伴开发、业务发展和战略。',
    'teamAdvisersPartners#89': 'Amarpreet Singh拥有非常多元化的工作和教育背景。他拥有三个硕士学位，曾在印度、新加坡、法国、中国、南非、韩国和加拿大生活、工作和学习。',
    'teamAdvisersPartners#90': '职业经历和项目：微软、世行、空中客车、TokenAsia Platform、GOeurika、CINDX',
    'teamAdvisersPartners#91': 'Tyler Sanford',
    'teamAdvisersPartners#92': '在Yelp和Zenefits获得8年的数据营销经验',
    'teamAdvisersPartners#93': '12个ICP项目筹得超过2.25亿美元',
    'teamAdvisersPartners#94': '区块链的早期应用者',
    'teamAdvisersPartners#95': '运动生涯给我带来了受用终身的一套技能。',
    'teamAdvisersPartners#96': '我非常幸运地看到了加倍努力带来的成果，同时也学会了应对职业生涯不可避免的起起落落。',
    'teamAdvisersPartners#97': '我是一个非常自觉的人，不断寻求提高各方面的知识和才能。',
    'teamAdvisersPartners#98': '我喜欢挑战，我的动力来自于克服障碍来实现我的目标。',
    'teamAdvisersPartners#99': '职业经历和项目：IPG Holdings、DataBlockChain、TraXion by Puma Technologies、ELYSIAN、Etherinc、FTEC Foundation、CINDX',
    'teamAdvisersPartners#100': 'William Zh',
    'teamAdvisersPartners#101': 'Blockchainer和J&C Capital的创始人',
    'teamAdvisersPartners#102': '浙江大学客席教授',
    'teamAdvisersPartners#103': 'OTC数据库（上海）前董事长',
    'teamAdvisersPartners#104': '朱先生是金融博士生，拥有法学学士学位和市场营销硕士学位。现任新兴产业研究院(上海)执行秘书长、浙江大学客座教授及上海市旅游局专家讲师。',
    'teamAdvisersPartners#105': '此前，他曾担任OTC数据库（上海）信息技术有限公司董事长、中国银联智能大数据和互联网金融研究所总经理及CDMC首席执行官。',
    'teamAdvisersPartners#106': 'Blockchainer为其客户提供全面的服务，例如：峰会组织、全球商务旅行、聚会主持人和定制、媒体和社交媒体投射、VIP资源连接、本地化服务。customization, media & social media casting, VIP resource connection, localisation service.',
    'teamAdvisersPartners#107': '这六个业务部门围绕主要区块链企业提供核心链服务，构建区块链企业以扩展服务生态系统。',
    'teamAdvisersPartners#108': '',
    'teamAdvisersPartners#109': 'Blockchainer在商业部门网络发展中加速了人才，组织和信息三个方面的发展速度。',
    'teamAdvisersPartners#110': 'Blockchainer的科技财务团队拥有超过10年的经验，是中国最早的区块链支持者和实践者; 是中国最早的区块链生态系统核心参与者; 专注于区块链领域，提供深入和垂直的全球专业服务; 是上游和下游区块链行业中业内最全面的网络之一。',
    //'teamAdvisersPartners#111': 'Technology/Digital enthusiast and a seasoned professional with years of experience in Tech industry – operations, consulting and innovation.',
    // 'teamAdvisersPartners#112': 'He is a certified Cloud (Microsoft and AWS) specialist. Known for versatility and flexibility. Excellent analytic, strategic, leadership skills and a team player at its best.',
    // 'teamAdvisersPartners#113': 'Specialties: Technology Consulting, Operations, Cloud Computing, Fintech, Innovation, ERP, Machine Learning, Account Management, Partner Development, Business Development and Strategy',
    // 'teamAdvisersPartners#114': 'Amarpreet Singh has a very diversified work and educational background. He holds three Masters degrees and has lived/worked/studied in India, Singapore, France, China, South Africa, Korea, Canada.',
    // 'teamAdvisersPartners#115': 'Career and projects: Microsoft, The World Bank, Airbus, TokenAsia Platform, GOeurika, CINDX',
    // 'teamAdvisersPartners#116': 'Tyler Sanford',
    // 'teamAdvisersPartners#117': '8 Years Digital Marketing in Yelp and Zenefits',
    // 'teamAdvisersPartners#118': '12 ICO’s over $225M raised',
    // 'teamAdvisersPartners#119': 'Early adopter of blockchain',
    // 'teamAdvisersPartners#120': 'Growing up as an Athlete has given me a skill set that I\'ve been able to apply to my careers and personal life.',
    // 'teamAdvisersPartners#121': 'I have been very fortunate to see the results of going that extra mile, while learning to deal with the highs and lows that go along with all professional careers.',
    // 'teamAdvisersPartners#122': 'I\'m an extremely self-motivated individual, always looking to continue my growth of knowledge and talents in all aspects of business.',
    // 'teamAdvisersPartners#123': 'Always up for a challenge, my motivation comes from overcoming obstacles to reach my goals',
    // 'teamAdvisersPartners#124': 'Career and projects: IPG Holdings, DataBlockChain, TraXion by Puma Technologies, ELYSIAN, Etherinc, FTEC Foundation,  CINDX',
    'teamAdvisersPartners#125': 'Bogdan Fiedur',
    'teamAdvisersPartners#126': '区块链和加密货币专家、投资者和企业家',
    'teamAdvisersPartners#127': 'bitJob联合创始人和Blockchainexperts.io总裁',
    'teamAdvisersPartners#128': 'Blockchain Expert',
    'teamAdvisersPartners#129': 'Bogdan Fiedur是一名智能合约开发人员、加密货币投资者、企业家和http://blockchainexperts.io总裁。他在建设电子商务网站方面拥有20多年的IT经验，并在过去3年中参与了区块链开发。',
    'teamAdvisersPartners#130': '他还是一名ICO项目顾问，迄今为止已成功完成20个ICO项目，并在其当地的加密社区Winnipeg中深入参与了区块链和智能合约编程相关的演示和研讨会。',
    'teamAdvisersPartners#131': 'Bogdan目前专注于区块链行业，提供建议、咨询服务以及与区块链专家之间的人脉联系。近期，Bogdan联合创立了Bitjob，一个针对学生的自由职业区块链平台，并以团队成员身份参与了多个ICO项目，例如PayPie.com。',
    'teamAdvisersPartners#132': '职业经历和项目：美国区块链协会、Tuurnt、Trends Project、BiNeuro、Zichain、Graphen Tech、CyClean、CINDX',
    'teamAdvisersPartners#133': 'Graham Doggart',
    'teamAdvisersPartners#134': '20年企业发展、金融科技营销合规经验',
    'teamAdvisersPartners#135': '执行了价值1亿美元的营销活动',
    'teamAdvisersPartners#136': '今年为十多个区块链项目担任顾问',
    'teamAdvisersPartners#137': '我是Stealth加密货币项目主席，并从2012年开始担任区块链和金融科技顾问公司Dynamic Abundance的联合创始人和首席策略师。',
    'teamAdvisersPartners#138': '我专门从事代币发行项目的结构安排和包装工作，曾参与多个国际区块链、加密货币和ICO项目。',
    'teamAdvisersPartners#139': '20多年来，我一直致力于技术管理咨询、法律和财务合规建议、发展战略、投资者关系以及各种资料的设计和编写工作。',
    'teamAdvisersPartners#140': '职业经历和项目：Stealth加密货币项目、Dynamic Abundance、ELYSIAN、Fox Trading FX、Profede、Worltdopoly、CINDX',
    'teamAdvisersPartners#141': 'Sadie Hutton',
    'teamAdvisersPartners#142': '25年高管和管理咨询经验',
    'teamAdvisersPartners#143': '参与了价值7500万英镑的营销活动，筹得数百万美元',
    'teamAdvisersPartners#144': '8个IC项目顾问',
    'teamAdvisersPartners#145': '我是区块链顾问公司Dynamic Abundance的创始人。',
    'teamAdvisersPartners#146': '我是ICO分析、项目管理以及ICO流程所有关键方面的专家，喜欢“亲力亲为”的工作方式。我擅长业务管理和发展，这使我能够全权负责项目启动到交付的整个流程。我是一位天生的解决问题能手，我会亲自参与每一个项目。我喜欢成功的感觉！',
    'teamAdvisersPartners#147': '凭借20多年的管理经验，我建立了一个强大的网络，积极为高级区块链项目提供财务、技术、销售、营销和质量保证方面的建议。',
    'teamAdvisersPartners#148': '职业经历和项目：Dynamic Abundance、ELYSIAN、Worldopoly、Fox Trading FX、Profede、Market Digital、CINDX',
    'teamAdvisersPartners#149': 'Jason King',
    'teamAdvisersPartners#150': '20年高管经验',
    'teamAdvisersPartners#151': '曾在30个国家领导过企业',
    'teamAdvisersPartners#152': '负责超过5亿美元的损益',
    'teamAdvisersPartners#153': '近20年来，Jason King一直担任技术、传媒和电信企业高管，参与过商业项目的结构安排和营销、谈判，以及为企业创造市场和打造成功基础。',
    'teamAdvisersPartners#154': '其中许多挑战促使他担任成功重组和扭亏的角色 - 通过培养合适的人员和团队来实现最高盈利目标。',
    'teamAdvisersPartners#155': '另外他对人股本和初创公司敢兴趣，Jason有丰富工作经验，包括Deutsche Telecom、Telenor和UPC Global以及Veon公司合作经验。',
    'teamAdvisersPartners#156': 'Jason去过30多个国家工作或领导业曾负责掌管高达5亿至10亿美元的商业资金。',
    'teamAdvisersPartners#157': 'Motti Peer',
    'teamAdvisersPartners#158': 'Blonde 2.0（获奖的全球公关机构）联合创始人',
    'teamAdvisersPartners#159': '多家证券和金融行业企业的创始人',
    'teamAdvisersPartners#160': 'Motti是区块链、加密货币、初创和风投项目的先驱之一。',
    'teamAdvisersPartners#161': 'Motti是多家证券和金融行业企业的创始人。他拥有多年的货币基金和对冲基金经理经验，并拥有曼彻斯特大学的金融和市场营销MBA学位以及Fairleigh Dickinson大学的商业管理学士学位。',
    'teamAdvisersPartners#162': '他是屡获殊荣的全球公关机构Blonde 2.0的联席首席执行官，该机构也被评为福布斯Startup Nation的“Movers and Shakers”（“推进者和撼动者”）。作为Blonde 2.0的联席创始人，他负责处理公关工作，以及战略和危机管理。',
    'teamAdvisersPartners#163': 'Blonde 2.0拥有一个35名专业人士组成的团队，是区块链、加密货币、初创企业和风投企业领先的公关机构之一。',
    'teamAdvisersPartners#164': 'Leonard Grayver',
    'teamAdvisersPartners#165': 'Greenberg Whitcombe Takeuchi Gibson & Grayver合伙人',
    'teamAdvisersPartners#166': '代表和指导过许多成功地项目',
    'teamAdvisersPartners#167': '在风险投资基金形成过程有细致的了解',
    'teamAdvisersPartners#168': 'Leonard Grayver 是加利福尼亚州硅谷的一名非常有经验的公司律师。他是加利福尼亚州，格林伯格，LLP，一家顶尖律师事务所的首席官。他曾经在将近20年里为风险投资基金，企业和新公司担任过代表，涉及领域包括医药设备，软件，健康中心，电子设备，IT和替换能源方面。',
    'teamAdvisersPartners#169': '在过去的几年，Leonard活跃于实践加密货币的法律等问题，并且曾为一些首次代币发售提供顾问。',
    'teamAdvisersPartners#170': 'Leonard在加利福尼亚洛杉矶大学获得了文学学士，并且从加州大学洛杉矶分校获得法学博士。',
    'teamAdvisersPartners#171': 'Kosol Saisanit',
    'teamAdvisersPartners#172': 'Power C Technology Co., Ltd、C Power Solutions Co., Ltd和CoachHub的创始人。',
    'teamAdvisersPartners#173': '执行管理专家和营销专家',
    'teamAdvisersPartners#174': '东南亚地区最大的快速消费品公司之一的品牌经理',
    'teamAdvisersPartners#175': 'Saisanit先生毕业于泰国易三仓大学，拥有行政管理方面的工商管理硕士和市场营销方面的工商管理学士学位。',
    'teamAdvisersPartners#176': '此前，他曾担任Monde Nissin品牌经理，该公司是东南亚地区快速消费品市场的最大参与者之一。此外，他曾在一家房地产服务公司CB Richard Ellis工作。',
    'teamAdvisersPartners#177': '他的公司Power C Technology Co., Ltd是一家能源服务公司（ESCO），专注于通过创新技术减少能源损失和提高电能质量。Power C Technology Co., Ltd是一家在线营销分支机构，为客户提供在线品牌推广、战略计划、营销传播计划及营销和销售计划顾问方面的帮助。',
    'teamAdvisersPartners#178': 'Teo Lincoln',
    'teamAdvisersPartners#179': 'Intel Wise Group 创始人兼首席执行官',
    'teamAdvisersPartners#180': 'Information Group 前任常务董事',
    'teamAdvisersPartners#181': '文莱中央银行管理局文莱达鲁萨兰国（AMBD）顾问',
    'teamAdvisersPartners#182': 'Lincoln 是Intel Wise Group的创始人兼首席执行官。',
    'teamAdvisersPartners#183': '在此之前，他曾担任Experian plc子公司DP Information Group的首席运营官兼董事总经理，并在东南亚（包括新加坡、来西亚、印度尼西亚、越南和文莱）管理多个数据存储库。',
    'teamAdvisersPartners#184': 'Lincoln的经验包括与大华银行集团和渣打银行的风险管理和IT基础设施。作为一名统计学家，他向亚太地区的多家银行介绍了信用评分，并且还抽出时间为世界银行集团和亚洲开发银行的项目团队服务。',
    'teamAdvisersPartners#185': '在新加坡和中国政府的双边项目「重庆连通性计划」的覆盖下，Intel Wise已经为中国超过1亿家公司提供数据，并帮助全球公司满足其合规和尽职调查需求。最近，公司开始采用区块链技术，并开发了替代金融服务和数字资产交易平台。此外，Lincoln也是文莱中央银行文莱金融管理局（AMBD）的顾问。',
    'teamAdvisersPartners#186': '此外，林肯也是文莱中央银行文莱金融管理局（AMBD）的顾问。',
    'teamAdvisersPartners#187': 'Reinhard Berger',
    'teamAdvisersPartners#188': 'Reinhard Berger一家专注于创新DLT技术的迪拜风险投资公司Pecunio Blockchain Technologies的首席执行官。',
    'teamAdvisersPartners#189': '为瑞士信贷、瑞银和景顺提供建议，担任埃森哲的商业建筑师。 是凯捷的负责人',
    'teamAdvisersPartners#190': '自2003年以来，通过Invest Finance AG管理着2.5亿美元的替代资产',
    'teamAdvisersPartners#191': '他拥有计算机科学硕士学位，法律硕士学位及多瑙河大学工商管理硕士学位。',
    'teamAdvisersPartners#192': '加入Pecunio之前，Reinhard的职业生涯始于全球咨询公司埃森哲的业务架构师，其客户包括瑞士信贷、瑞银和景顺。',
    'teamAdvisersPartners#193': '随后，他担任法国科技巨头凯捷的负责人。2003年，Reinhard联合创办了另类对冲基金运营商Alternative Invest Finance AG，在那里他管理着超过2.5亿美元的替代资产。 ',
    'teamAdvisersPartners#194': '在Pecuino担任直接领导者，自然法人和真正的战略家',
    'teamAdvisersPartners#195': '',
    'teamAdvisersPartners#196': '',
    'teamAdvisersPartners#197': '',

    //urgentnews
    'urgentnews#g1': '查看所有文章',
    'urgentnews#g2': '更多',
    'urgentnews#1': 'CINXO代币的初始循环供应',            //title
    'urgentnews#2': '4 分钟',                                      // readtime
    'urgentnews#3': '关于CINXO代币重要的信息!',      // mainThought
    'urgentnews#4': 'https://www.weibo.com/ttarticle/p/show?id=2309404386816240316389', // link
    'urgentnews#5': 'CINDX 代币为持有者带来的收益',
    'urgentnews#6': '4 分钟',
    'urgentnews#7': '了解更多关于CINDX代币!',
    'urgentnews#8': 'https://www.weibo.com/ttarticle/p/show?id=2309404386715006578968',
    'urgentnews#9': '常见问题',
    'urgentnews#10': '6 分钟',
    'urgentnews#11': '关于CINDX的常见问题',
    'urgentnews#12': 'https://www.weibo.com/ttarticle/p/show?id=2309404386453491679919',
    'urgentnews#13': 'CINDX在Bitforex上发行',
    'urgentnews#14': '3 分钟',
    'urgentnews#15': '在币夫交易所上的CINDX尊享通证认购',
    'urgentnews#16': 'https://event.bitforex.com/cn/CINXO.html',
    'urgentnews#17': 'CINDX的官方社交媒体',
    'urgentnews#18': '2 分钟',
    'urgentnews#19': '给出的列表中包含我们所有的官方渠道和线上媒体',
    'urgentnews#20': 'https://medium.com/cindx/official-social-media-channels-for-cindx-4c44759dffe8',

 //   'joinTokenPreSale#1': '参与代币预售！',
//    'joinTokenPreSale#2': '参与预售',
//    'joinTokenPreSale#3': '测试MVP',
    'joinTokenPreSale#4': '总部',
    'joinTokenPreSale#5': 'Harjumaa, Erika 14,',
    'joinTokenPreSale#6': 'Talinn, 10416, Estonia',
    'joinTokenPreSale#7': '咨询洽谈 | hello@cindx.io',
//    'joinTokenPreSale#8': 'Legal Disclaimer',
//    'joinTokenPreSale#9': '开发和营销',
//    'joinTokenPreSale#10': 'Presnenskaya naberezhnaya, 8 building 1,',
//    'joinTokenPreSale#11': 'Moscow, 123112, Russia',
//    'joinTokenPreSale#12': '工作机会 | 加入我们',
    'joinTokenPreSale#13': '关注我们:',
    'joinTokenPreSale#14': '用户协议',
    'joinTokenPreSale#15': '隐私政策',
    'joinTokenPreSale#16': '订阅来自CINDX的最新更新',
    'joinTokenPreSale#17': '订阅',
    'joinTokenPreSale#18': '',
    'joinTokenPreSale#19': '',
    'joinTokenPreSale#20': '',

    'mvpBlock#1': 'CINDX Live',
    'mvpBlock#2': '我们已推出CINDX平台。立即加入我们！',
    'mvpBlock#3': '注册',
    'mvpBlock#4': '',
    'mvpBlock#5': '',
    'mvpBlock#6': '',
    'mvpBlock#7': '',
    'mvpBlock#8': '',
    'mvpBlock#9': '',
    'mvpBlock#10': '',
    'mvpBlock#11': '',
    'mvpBlock#12': '',
    'mvpBlock#13': '',
    'mvpBlock#14': '',

    'common#g1': '邮箱',

    'subscribeBlock#1': '成为第一个从CINDX获取最新消息和更新的人',

    'link#1_bitforex': 'https://event.bitforex.com/cn/CINXO.html',
    'link#2_help': 'https://medium.com/cindx/how-to-buy-cinxo-tokens-7fc82a27ba47?postPublishedType=repub',
}

export default zn;